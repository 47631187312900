import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import {
  toastErrorMessage,
  toastSuccessMessage,
} from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "./../../../redux/actions/loadingActions/loadingActions";

import {
  changeStoreStatusAdminServices,
  createNewsAdminService,
  deleteNewsAdminServices,
  editNewsAdminServices,
  getGetStoreStatusAdminService,
  getNewsAdminService,
} from "../../../services/userServices";

export const newsAdminContext = createContext({
  isOpenAddModal: null,
  setIsOpenAddModal: () => {},
  getNewsItems: [],
  setGetNewsItems: () => {},
  editNewsId: "",
  setEditNewsId: () => {},
  newsInfoData: "",
  setNewsInfoData: () => {},
  adminUser: "",
  setAdminUser: () => {},
  isStoreClose: null,
  setIsStoreClose: () => {},
  status: [],
  setStatus: () => {},
  handleCreateNewsAdmin: () => {},
  handleGetNewsAdmin: () => {},
  handleEditNewsAdmin: () => {},
  handleCloseEditModal: () => {},
  handleOpenEditModal: () => {},
  handleDeleteNews: () => {},
  handleChangeStoreStatus: () => {},
  handleGetStoreStatus: () => {},
});

const NewsAdminContext = ({ children }) => {
  const dispatch = useDispatch();

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [getNewsItems, setGetNewsItems] = useState([]);
  const [editNewsId, setEditNewsId] = useState("");
  const [newsInfoData, setNewsInfoData] = useState([]);
  const [adminUser, setAdminUser] = useState("");
  const [isStoreCloses, setIsStoreClose] = useState();

  //* Handle Create News
  const handleCreateNewsAdmin = async (userData) => {
    const objData = {
      ...userData,
      adminUser: Number(userData.adminUser),
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await createNewsAdminService(objData);
      if (status === 200) {
        setIsOpenAddModal(false);
        handleGetNewsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Get News
  const handleGetNewsAdmin = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getNewsAdminService();
      if (status === 200) {
        setGetNewsItems(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //! Edit Part
  //* Handle Edit News
  const handleOpenEditModal = (_item, setValue) => {
    setEditNewsId(_item.id);
    setNewsInfoData(_item);

    setValue("id", _item.id);
    setValue("title", _item.title);
    setValue("description", _item.description);
    setValue("dateCreate", _item.dateCreate);
  };

  const handleCloseEditModal = () => {
    setEditNewsId("");
  };

  const handleEditNewsAdmin = async (userData) => {
    const objData = {
      ...userData,
      adminUser: Number(userData.adminUser),
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editNewsAdminServices(objData);
      if (status === 200) {
        handleCloseEditModal();
        handleGetNewsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };
  //! Edit Part

  //* Handle Delete News
  const handleDeleteNews = async (id) => {
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await deleteNewsAdminServices(id);
      if (status === 200) {
        handleGetNewsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Change Store Status
  const handleChangeStoreStatus = async (isStoreClose) => {
    const objData = { id: 1, isStoreClose };

    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await changeStoreStatusAdminServices(objData);
      if (status === 200) {
        setIsStoreClose(!isStoreCloses);
        toastSuccessMessage(data.message);
        handleGetStoreStatus();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Get Store Status
  const handleGetStoreStatus = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getGetStoreStatusAdminService();
      if (status === 200) {
        setIsStoreClose(data?.isStoreClose);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <newsAdminContext.Provider
      value={{
        isOpenAddModal,
        setIsOpenAddModal,
        getNewsItems,
        setGetNewsItems,
        editNewsId,
        setEditNewsId,
        newsInfoData,
        setNewsInfoData,
        adminUser,
        setAdminUser,
        handleCreateNewsAdmin,
        handleGetNewsAdmin,
        handleEditNewsAdmin,
        handleCloseEditModal,
        handleOpenEditModal,
        handleDeleteNews,
        isStoreCloses,
        setIsStoreClose,
        handleChangeStoreStatus,
        // ?
        handleGetStoreStatus,
      }}
    >
      {children}
    </newsAdminContext.Provider>
  );
};

export default NewsAdminContext;
