import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";
import ColorChangingIcon from "../../../helpers/ColorChangingIcon/ColorChangingIcon";
import NewsModal from "../../../helpers/NewsModal/NewsModal";
import MobileBoxUi from "../../../helpers/MobileBoxUi/MobileBoxUi";

import { MdNotifications } from "react-icons/md";
import { TbClockCancel } from "react-icons/tb";
import { TbLoader } from "react-icons/tb";

import { pricePublicContext } from "../../../context/PublicContext/PricePublicContext/PricePublicContext";
import NewsPublicContext from "../../../context/PublicContext/NewsPublicContext/NewsPublicContext";

const MobilePriceView = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const {
    setIs_orderi,
    setCustomeruser,
    setProduct_name,
    confirmModal,
    setConfirmModal,
    resultModal,
    setResultModal,
    data,
    setData,
    getItemsList,
    type,
    setType,
    stateWeight,
    setStateWeight,
    setBos,
    transactionId,
    statePrice,
    setStatePrice,
    stateTotal,
    setStateTotal,
    setCounter,
    handleGetItemsList,
    handlePostTransaction,
    handlePriceChange,
    handleWeightChange,
    handleTotalChange,
    description,
    setDescription,
    addCommas,
    openModal,
    setOpenModal,
    loading,
    setLoading,
  } = useContext(pricePublicContext);

  const handleInitial = async () => {
    setCounter((prevCounter) => prevCounter + 1);
    await handleGetItemsList();
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleInitial();
      setLoading(false);
    };
    fetchData();

    const interval = setInterval(handleInitial, 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <span
        className="w-full h-full absolute space-y-5
      left-0 right-0 top-[2.5rem] text-slate-200 text-xl
      flex flex-col justify-center items-center"
      >
        <span>
          <TbLoader size={60} />
        </span>
        <p>لطفا صبر کنید</p>
      </span>
    );
  }

  return (
    <>
      <SiteTitle title="لیست قیمت ها" />
      {isEmpty(getItemsList) ? (
        <>
          <span
            className="w-full h-full absolute space-y-5
            left-0 right-0 top-[2.5rem] text-slate-200 text-xl
            flex flex-col justify-center items-center"
          >
            <span>
              <TbClockCancel size={60} />
            </span>
            <p>سامانه بسته می باشد</p>
          </span>
        </>
      ) : (
        <>
          <div
            className={`${
              isEmpty(getItemsList) ? "h-screen" : "mt-2 mb-16 space-y-1"
            } w-full relative flex flex-col justify-center  overflow-auto z-20`}
          >
            {/* News */}
            <div
              className={`${
                isEmpty(getItemsList) ? "absolute top-1 left-0 " : null
              } w-full`}
            >
              <span className="flex justify-end mx-3 z-40">
                <ColorChangingIcon
                  Icon={MdNotifications}
                  size={30}
                  className="animate__animated animate__swing
              animate__slow animate__infinite"
                  firstColor="#facc15"
                  secondColor="#fff"
                  onClick={() => setOpenModal(!openModal)}
                />
              </span>
              <span>
                <span
                  className="flex justify-center items-center
               w-full h-full z-50 mb-8"
                >
                  {openModal && (
                    <NewsPublicContext>
                      <NewsModal />
                    </NewsPublicContext>
                  )}
                </span>
              </span>
              {/* Title Text */}
              <span className="text-slate-200 absolute right-[44%] top-1">
                <p>هو الرزاق</p>
              </span>
            </div>
            {/* Boxes */}
            {getItemsList?.map((_item) => (
              <>
                {_item?.periority === true && (
                  <MobileBoxUi
                    _item={_item}
                    data={data}
                    setData={setData}
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    resultModal={resultModal}
                    setResultModal={setResultModal}
                    statePrice={statePrice}
                    setStatePrice={setStatePrice}
                    handlePriceChange={handlePriceChange}
                    stateWeight={stateWeight}
                    setStateWeight={setStateWeight}
                    handleWeightChange={handleWeightChange}
                    stateTotal={stateTotal}
                    setStateTotal={setStateTotal}
                    handleTotalChange={handleTotalChange}
                    description={description}
                    setDescription={setDescription}
                    addCommas={addCommas}
                    type={type}
                    setType={setType}
                    handlePostTransaction={handlePostTransaction}
                    setProduct_name={setProduct_name}
                    setCustomeruser={setCustomeruser}
                    userInfo={userInfo}
                    setBos={setBos}
                    setIs_orderi={setIs_orderi}
                    transactionId={transactionId}
                  />
                )}

                {_item?.periority === false && (
                  <MobileBoxUi
                    _item={_item}
                    data={data}
                    setData={setData}
                    confirmModal={confirmModal}
                    setConfirmModal={setConfirmModal}
                    resultModal={resultModal}
                    setResultModal={setResultModal}
                    statePrice={statePrice}
                    setStatePrice={setStatePrice}
                    handlePriceChange={handlePriceChange}
                    stateWeight={stateWeight}
                    setStateWeight={setStateWeight}
                    handleWeightChange={handleWeightChange}
                    stateTotal={stateTotal}
                    setStateTotal={setStateTotal}
                    handleTotalChange={handleTotalChange}
                    description={description}
                    setDescription={setDescription}
                    addCommas={addCommas}
                    type={type}
                    setType={setType}
                    handlePostTransaction={handlePostTransaction}
                    setProduct_name={setProduct_name}
                    setCustomeruser={setCustomeruser}
                    userInfo={userInfo}
                    setBos={setBos}
                    setIs_orderi={setIs_orderi}
                    transactionId={transactionId}
                  />
                )}
              </>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default MobilePriceView;
