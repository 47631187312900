import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import BoxUi from "../../../helpers/PcPriceUi/BoxUi";
import ListUi from "../../../helpers/PcPriceUi/ListUi";

import { TbClockCancel } from "react-icons/tb";
import { TbLoader } from "react-icons/tb";

import { pricePublicContext } from "../../../context/PublicContext/PricePublicContext/PricePublicContext";

const PcPriceView = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const {
    setIs_orderi,
    description,
    setDescription,
    setCustomeruser,
    setProduct_name,
    confirmModal,
    setConfirmModal,
    resultModal,
    setResultModal,
    data,
    setData,
    getItemsList,
    type,
    setType,
    stateWeight,
    setStateWeight,
    setBos,
    transactionId,
    statePrice,
    setStatePrice,
    stateTotal,
    setStateTotal,
    setCounter,
    handleGetItemsList,
    handlePostTransaction,
    handlePriceChange,
    handleWeightChange,
    handleTotalChange,
    addCommas,
    loading,
    setLoading,
  } = useContext(pricePublicContext);

  const handleInitial = async () => {
    setCounter((prevCounter) => prevCounter + 1);
    await handleGetItemsList();
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleInitial();
      setLoading(false);
    };
    fetchData();

    const interval = setInterval(handleInitial, 1000);
    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return (
      <span
        className="mr-auto 2xl:w-[100%] xl:w-[95%] lg:w-[90%]
      py-3 absolute top-[50%] mt-10 text-slate-200 text-xl
      flex flex-col justify-center items-center"
      >
        <span>
          <TbLoader size={60} />
        </span>
        <p>لطفا صبر کنید</p>
      </span>
    );
  }

  return (
    <>
      <section>
        {isEmpty(getItemsList) ? (
          <>
            <span
              className="mr-auto 2xl:w-[100%] xl:w-[95%] lg:w-[90%]
              py-3 absolute top-[50%] mt-10 text-slate-200 text-xl
              flex flex-col justify-center items-center"
            >
              <span>
                <TbClockCancel size={60} />
              </span>
              <p>سامانه بسته می باشد</p>
            </span>
          </>
        ) : (
          <>
            {/* Box View */}
            <div className="flex justify-center w-full">
              <div
                className="grid 2xl:grid-cols-4 xl:grid-cols-3 grid-cols-2
                gap-y-16 py-8 2xl:justify-items-center justify-items-end 
                px-2 2xl:mr-0 mr-10 w-full
                animate__animated animate__fadeIn animate__slow"
              >
                {getItemsList.map((_item) => (
                  <>
                    {_item.periority === true && (
                      <BoxUi
                        _item={_item}
                        data={data}
                        setData={setData}
                        confirmModal={confirmModal}
                        setConfirmModal={setConfirmModal}
                        resultModal={resultModal}
                        setResultModal={setResultModal}
                        statePrice={statePrice}
                        setStatePrice={setStatePrice}
                        handlePriceChange={handlePriceChange}
                        stateWeight={stateWeight}
                        setStateWeight={setStateWeight}
                        handleWeightChange={handleWeightChange}
                        stateTotal={stateTotal}
                        setStateTotal={setStateTotal}
                        handleTotalChange={handleTotalChange}
                        description={description}
                        setDescription={setDescription}
                        addCommas={addCommas}
                        type={type}
                        setType={setType}
                        handlePostTransaction={handlePostTransaction}
                        setProduct_name={setProduct_name}
                        setCustomeruser={setCustomeruser}
                        userInfo={userInfo}
                        setBos={setBos}
                        setIs_orderi={setIs_orderi}
                        transactionId={transactionId}
                      />
                    )}
                  </>
                ))}
              </div>
            </div>

            {/* List View */}
            <div className="mt-10 lg:w-[88%] xl:w-[95%] 2xl:w-[100%] float-left">
              <table className="w-full">
                {/* Table Header */}
                <thead className="text-sm font-medium text-slate-200 border-b-[1px] border-[#A79B81]">
                  <tr>
                    <th className="py-3">
                      <div>نام محصول</div>
                    </th>
                    <th className="py-3">
                      <div>خرید شما</div>
                    </th>
                    <th className="py-3">
                      <div>فروش شما</div>
                    </th>
                    <th className="py-3">
                      <div>آخرین آپدیت</div>
                    </th>
                    <th className="py-3">
                      <div>نوسانات بازار</div>
                    </th>
                  </tr>
                </thead>
                {/* Table Body */}
                <tbody className="text-lg divide-y divide-gray-700 w-full">
                  {getItemsList.map((_item) => (
                    <>
                      {_item.periority === false && (
                        <ListUi
                          _item={_item}
                          data={data}
                          setData={setData}
                          confirmModal={confirmModal}
                          setConfirmModal={setConfirmModal}
                          resultModal={resultModal}
                          setResultModal={setResultModal}
                          statePrice={statePrice}
                          setStatePrice={setStatePrice}
                          handlePriceChange={handlePriceChange}
                          stateWeight={stateWeight}
                          setStateWeight={setStateWeight}
                          handleWeightChange={handleWeightChange}
                          stateTotal={stateTotal}
                          setStateTotal={setStateTotal}
                          handleTotalChange={handleTotalChange}
                          description={description}
                          setDescription={setDescription}
                          addCommas={addCommas}
                          type={type}
                          setType={setType}
                          handlePostTransaction={handlePostTransaction}
                          setProduct_name={setProduct_name}
                          setCustomeruser={setCustomeruser}
                          userInfo={userInfo}
                          setBos={setBos}
                          setIs_orderi={setIs_orderi}
                          transactionId={transactionId}
                        />
                      )}
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default PcPriceView;
