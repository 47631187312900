import Swal from "sweetalert2";
import { isEmpty } from "lodash";

export const toastSuccessMessage = (message) => {
  Toast.fire({
    icon: "success",
    title: message,
  });
};

export const toastErrorMessage = (message) => {
  Toast.fire({
    icon: "error",
    title: message,
  });
};

export const toastWarningMessage = (message) => {
  Toast.fire({
    icon: "warning",
    title: message,
  });
};

export const toastOfflineMessage = () => {
  Swal.fire({
    html: "اتصال شما به اینترنت قطع شده است",
    icon: "warning",
    showConfirmButton: false,
  });
}

export const deliverySuccessMessage = (message, timer) => {
  Swal.fire({
    html: message,
    icon: "success",
    timer,
    showConfirmButton: false,
  });
}

export const modalErrorMessage = (message, redirectUrl, timer) => {
  Swal.fire({
    icon: "error",
    html: `<h5> ${message} </h5>`,
    confirmButtonText: "تأیید",
    showConfirmButton: isEmpty(timer),
    timer,
    didClose: () => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
  });
};

export const areYouSureMessage = (
  func,
  html = "از حذف کاربر مطمئنید؟",
  confirmButtonText = "بله مطمئنم"
) => {
  Swal.fire({
    html,
    icon: "warning",
    confirmButtonText,
    showCancelButton: true,
    cancelButtonColor: "#d33",
    confirmButtonColor: "#3085d6",
    cancelButtonText: "خیر",
  }).then((result) => {
    if (result.value) func();
  });
};

export const modalSuccessMessage = (message, redirectUrl, timer) => {
  Swal.fire({
    icon: "success",
    html: `<h5> ${message} </h5>`,
    confirmButtonText: "باشه",
    showConfirmButton: isEmpty(timer),
    timer,
    didClose: () => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    },
  });
};

export const modalWithInputMessage = (
  func,
  title,
  html,
  placeholder,
  input = "text"
) => {
  Swal.fire({
    title,
    html,
    input,
    inputAttributes: {
      autocomplete: "off",
      placeholder,
    },
    showCancelButton: true,
    cancelButtonText: "لغو",
    confirmButtonText: "تأیید",
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    preConfirm: (value) => {
      return func(value);
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 5000,
  showCloseButton: true,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

// const Toast = Swal.mixin({
//   toast: true,
//   position: "top-end",
//   showConfirmButton: false,
//   timer: 5000,
//   showCloseButton: true,
//   timerProgressBar: true,
//   onOpen: (toast) => {
//     toast.addEventListener("mouseenter", Swal.stopTimer);
//     toast.addEventListener("mouseleave", Swal.resumeTimer);
//   },
// });
