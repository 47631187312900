import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import { toastErrorMessage } from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  changeProductActiveServices,
  createProductsAdminService,
  editProductAdminServices,
  getProductsAdminService,
} from "../../../services/userServices";

export const productsAdminContext = createContext({
  getProducts: [],
  setGetProducts: () => {},
  addProduct: null,
  setAddProduct: () => {},
  active: null,
  setActive: () => {},
  productId: "",
  setProductId: () => {},
  buyTolerance: "",
  setBuyTolerance: () => {},
  sellTolerance: "",
  setSellTolerance: () => {},
  buyCalculate: "",
  setBuyCalculate: () => {},
  sellCalculate: "",
  setSellCalculate: () => {},
  balance: "",
  setBalance: () => {},
  handleCreateProductAdmin: () => {},
  handleGetProductsAdmin: () => {},
  handleOpenEditModal: () => {},
  handleCloseEditModal: () => {},
  handleEditProductAdmin: () => {},
  handleChangePriorityProduct: () => {},
  handleChangeActiveProduct: () => {},
});

const ProductsAdminContext = ({ children }) => {
  const dispatch = useDispatch();
  const [getProducts, setGetProducts] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [productId, setProductId] = useState("");

  const [buyTolerance, setBuyTolerance] = useState(5000);
  const [sellTolerance, setSellTolerance] = useState(5000);
  const [buyCalculate, setBuyCalculate] = useState("*1/1+0-0");
  const [sellCalculate, setSellCalculate] = useState("*1/1+0-0");
  const [balance, setBalance] = useState(0);

  //* Create Product Start

  // Handle Create Product Admin
  const handleCreateProductAdmin = async (userData) => {
    const objData = {
      ...userData,
      buyCalculate,
      sellCalculate,
      buy_price: Number(userData.buy_price),
      sell_price: Number(userData.sell_price),
      balance: isNaN(Number(userData.balance)) ? Number(balance) : 0,
      buyTolerance: isNaN(Number(userData.buyTolerance))
        ? Number(buyTolerance)
        : 5000,
      sellTolerance: isNaN(Number(userData.sellTolerance))
        ? Number(sellTolerance)
        : 5000,
      related_product: userData.related_product
        ? Number(userData.related_product)
        : null,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await createProductsAdminService(objData);
      if (status === 200) {
        setAddProduct(false);
        handleGetProductsAdmin();
        setBuyTolerance("");
        setSellTolerance("");
        setBuyCalculate("");
        setSellCalculate("");
        setBalance("");
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  const handleChangeBuyTolerance = (event) => {
    setBuyTolerance(event.target.value);
  };
  const handleChangeSellTolerance = (event) => {
    setSellTolerance(event.target.value);
  };
  const handleChangeBuyCalculate = (event) => {
    setBuyCalculate(event.target.value);
  };
  const handleChangeSellCalculate = (event) => {
    setSellCalculate(event.target.value);
  };
  const handleChangeBalance = (event) => {
    setBalance(event.target.value);
  };

  // Set Default Value In States When Open Modal
  const defaultValuesCreateProduct = {
    buyTolerance: 5000,
    sellTolerance: 5000,
    buyCalculate: "*1/1+0-0",
    sellCalculate: "*1/1+0-0",
    balance: 0,
  };

  // Handle Open Create Modal And Save Default Value In States
  const handleOpenCreateModal = () => {
    setBuyTolerance(defaultValuesCreateProduct.buyTolerance);
    setSellTolerance(defaultValuesCreateProduct.sellTolerance);
    setBuyCalculate(defaultValuesCreateProduct.buyCalculate);
    setSellCalculate(defaultValuesCreateProduct.sellCalculate);
    setBalance(defaultValuesCreateProduct.balance);

    setAddProduct(true);
  };
  //* Create Product End

  // Handle Get Products Admin
  const handleGetProductsAdmin = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getProductsAdminService();
      if (status === 200) {
        setGetProducts(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Convert Persian Number To English Number
  const convertPersianToEnglish = (str) => {
    const persianDigits = "۰۱۲۳۴۵۶۷۸۹".split("");
    const englishDigits = "0123456789".split("");

    return str.replace(
      /[۰-۹]/g,
      (char) => englishDigits[persianDigits.indexOf(char)] || char
    );
  };

  //* Edit Product Start
  // Handle Edit Product Admin
  const handleOpenEditModal = (_item, setValue) => {
    if (!_item) {
      setProductId("");
      return;
    }

    setProductId(_item?.id);

    setValue("id", _item?.id || "");
    setValue("name", _item?.name || "");
    setValue(
      "related_product_id",
      _item?.related_product_id
        ? convertPersianToEnglish(_item?.related_product_id.toString())
        : ""
    );
    setValue(
      "buy_price",
      _item?.buy_price
        ? convertPersianToEnglish(_item?.buy_price.toString())
        : ""
    );
    setValue(
      "sell_price",
      _item?.sell_price
        ? convertPersianToEnglish(_item?.sell_price.toString())
        : ""
    );
    setValue("buy_calculation", _item?.buy_calculation || "");
    setValue("sell_calculation", _item?.sell_calculation || "");
    setValue(
      "balance",
      _item?.balance ? convertPersianToEnglish(_item?.balance.toString()) : ""
    );
    setValue("is_market_close", _item?.is_market_close || false);
    setValue("tag", _item?.tag || "");
    setValue(
      "b_tel",
      _item?.b_tel ? convertPersianToEnglish(_item?.b_tel.toString()) : ""
    );
    setValue(
      "s_tel",
      _item?.s_tel ? convertPersianToEnglish(_item?.s_tel.toString()) : ""
    );
  };

  const handleCloseEditModal = () => {
    setProductId("");
  };

  const handleEditProductAdmin = async (userData) => {
    const objData = {
      ...userData,
      buy_price: Number(convertPersianToEnglish(userData.buy_price.toString())),
      sell_price: Number(
        convertPersianToEnglish(userData.sell_price.toString())
      ),
      balance: Number(convertPersianToEnglish(userData.balance.toString())),
      b_tel: Number(convertPersianToEnglish(userData.b_tel.toString())),
      s_tel: Number(convertPersianToEnglish(userData.s_tel.toString())),
      related_product: Number(
        convertPersianToEnglish(userData.related_product_id.toString())
      ),
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editProductAdminServices(objData);
      if (status === 200) {
        handleCloseEditModal();
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };
  //* Edit Product End

  // Change Priority Product
  const handleChangePriorityProduct = async (id, periority) => {
    const objData = {
      id,
      periority,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeProductActiveServices(objData);
      if (status === 200) {
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Change Active Product
  const handleChangeActiveProduct = async (id, is_market_close) => {
    const objData = {
      id,
      is_market_close,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeProductActiveServices(objData);
      if (status === 200) {
        handleGetProductsAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <productsAdminContext.Provider
      value={{
        getProducts,
        setGetProducts,
        addProduct,
        setAddProduct,
        productId,
        setProductId,
        buyTolerance,
        setBuyTolerance,
        sellTolerance,
        setSellTolerance,
        buyCalculate,
        setBuyCalculate,
        sellCalculate,
        setSellCalculate,
        balance,
        setBalance,
        handleCreateProductAdmin,
        handleGetProductsAdmin,
        handleOpenEditModal,
        handleCloseEditModal,
        handleEditProductAdmin,
        handleChangePriorityProduct,
        handleChangeActiveProduct,
        handleOpenCreateModal,
        handleChangeBuyTolerance,
        handleChangeSellTolerance,
        handleChangeBuyCalculate,
        handleChangeSellCalculate,
        handleChangeBalance,
      }}
    >
      {children}
    </productsAdminContext.Provider>
  );
};

export default ProductsAdminContext;
