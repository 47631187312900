import { SpinnerDotted } from "spinners-react";

const PreLoader = ({ width }) => {
  return (
    <>
      <span
        className="absolute top-0 bottom-0 left-0 right-0
         backdrop-blur-md z-[9999] flex justify-center items-center"
      >
        <SpinnerDotted
          size={width ? width : 90}
          thickness={180}
          speed={116}
          color="rgba(57, 96, 172, 1)"
        />
      </span>
    </>
  );
};

export default PreLoader;
