import PersianNumber from "../../utils/PersianNumber/PersianNumber";

import { MdOutlineCurrencyExchange } from "react-icons/md";
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";

const MobileBoxUi = ({
  _item,
  data,
  setData,
  confirmModal,
  setConfirmModal,
  resultModal,
  setResultModal,
  statePrice,
  setStatePrice,
  handlePriceChange,
  stateWeight,
  setStateWeight,
  handleWeightChange,
  stateTotal,
  setStateTotal,
  handleTotalChange,
  description,
  setDescription,
  addCommas,
  type,
  setType,
  handlePostTransaction,
  setProduct_name,
  setCustomeruser,
  userInfo,
  setBos,
  setIs_orderi,
  transactionId,
}) => {
  return (
    <>
      <div
        key={_item?.id}
        className="w-full flex justify-center
animate__animated animate__fadeIn animate__slow"
      >
        <div
          className={`${_item.id === data.id ? "h-80" : "h-44"}
relative w-[95%] rounded-xl my-1 py-4 bg-[#252839] shadow-BoxShadow`}
        >
          {/* Start Modal */}
          {_item.id === data.id && (
            <div
              className="absolute top-0 left-0 right-0 h-full z-30
animate__animated animate__fadeIn animate__fast"
            >
              {/* Start Buy/Sell Modal */}
              {!confirmModal && !resultModal && (
                <div className="flex flex-col justify-around items-center w-full h-full px-2 py-2">
                  {/* Order Icon */}
                  {type === "buy" ? (
                    <>
                      {_item.buy_price !== statePrice && (
                        <span
                          className="absolute px-2 py-1 rounded-full top-1
            left-1 flex items-center text-slate-300"
                        >
                          <div className="group flex relative">
                            <MdOutlineCurrencyExchange
                              size={18}
                              className="animate-spin-slow"
                            />
                            <span
                              className="group-hover:opacity-100 transition-opacity 
             px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
             -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                            >
                              اردری
                            </span>
                          </div>
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      {_item.sell_price !== statePrice && (
                        <span
                          className="absolute px-2 py-1 rounded-full top-1 
            left-1 flex items-center text-slate-300"
                        >
                          <div className="group flex relative">
                            <MdOutlineCurrencyExchange
                              size={18}
                              className="animate-spin-slow"
                            />
                            <span
                              className="group-hover:opacity-100 transition-opacity 
           px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
           -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                            >
                              اردری
                            </span>
                          </div>
                        </span>
                      )}
                    </>
                  )}
                  {/* Price */}
                  <span className="relative px-5 w-[90%]">
                    <input
                      type="text"
                      autoComplete="off"
                      name="price"
                      value={
                        statePrice === -1
                          ? ""
                          : statePrice === ""
                          ? statePrice + statePrice
                          : addCommas(statePrice)
                      }
                      onChange={(e) => {
                        handlePriceChange(_item, e);
                      }}
                      className={`${
                        type === "buy"
                          ? "focus:text-green-500 focus:border-green-600"
                          : "focus:text-red-500 focus:border-red-600"
                      } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
  border-zinc-500 appearance-none text-slate-500  
    focus:transition-all focus:duration-200 focus:ease-linear 
    transition-all duration-200 ease-linear focus:outline-0
    focus:outline-none focus:ring-0 peer text-left
  `}
                      style={{ direction: "ltr" }}
                      placeholder=" "
                    />
                    <label
                      htmlFor="price"
                      className={`${
                        type === "buy"
                          ? "peer-focus:text-green-600"
                          : "peer-focus:text-red-600"
                      } absolute text-xl text-gray-400
  duration-300 transform  -translate-y-5 scale-75
  right-0 top-1 -z-10 peer-focus:right-0 px-5 text-left
  peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
    `}
                      style={{ direction: "rtl" }}
                    >
                      {_item.tag === "S" ? "قیمت" : "مظنه"}
                    </label>
                  </span>
                  {/* Weight */}
                  <span className="relative px-5 w-[90%]">
                    <input
                      type="text"
                      autoComplete="off"
                      name="weight"
                      value={stateWeight}
                      onChange={(e) => {
                        handleWeightChange(_item, e);
                      }}
                      className={`${
                        type === "buy"
                          ? "focus:text-green-500 focus:border-green-600"
                          : "focus:text-red-500 focus:border-red-600"
                      } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
  border-zinc-500 appearance-none text-slate-500  
    focus:transition-all focus:duration-200 focus:ease-linear 
    transition-all duration-200 ease-linear focus:outline-0
    focus:outline-none focus:ring-0 peer text-left
  `}
                      placeholder=" "
                    />
                    <label
                      htmlFor="weight"
                      className={`${
                        type === "buy"
                          ? "peer-focus:text-green-600"
                          : "peer-focus:text-red-600"
                      } absolute text-xl text-gray-400
  duration-300 transform  -translate-y-5 scale-75
  right-0 top-1 -z-10 peer-focus:right-0 px-5 text-left
  peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
    `}
                    >
                      {_item.tag === "S" ? "تعداد" : "مقدار (گرم)"}
                    </label>
                  </span>
                  {/* Total */}
                  <span className="relative px-5 w-[90%]">
                    <input
                      type="text"
                      autoComplete="off"
                      name="total"
                      value={addCommas(stateTotal)}
                      onChange={(e) => {
                        handleTotalChange(_item, e);
                      }}
                      className={`${
                        type === "buy"
                          ? "focus:text-green-500 focus:border-green-600"
                          : "focus:text-red-500 focus:border-red-600"
                      } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
  border-zinc-500 appearance-none text-slate-500  
    focus:transition-all focus:duration-200 focus:ease-linear 
    transition-all duration-200 ease-linear focus:outline-0
    focus:outline-none focus:ring-0 peer text-left
  `}
                      placeholder=" "
                    />
                    <label
                      htmlFor="total"
                      className={`${
                        type === "buy"
                          ? "peer-focus:text-green-600"
                          : "peer-focus:text-red-600"
                      } absolute text-xl text-gray-400
  duration-300 transform  -translate-y-5 scale-75
  right-0 top-1 -z-10 peer-focus:right-0 px-5 text-left
  peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
    `}
                    >
                      جمع کل
                    </label>
                  </span>
                  {/* Description */}
                  <span className="relative px-5 w-[90%]">
                    <input
                      type="text"
                      autoComplete="off"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      className={`${
                        type === "buy"
                          ? "focus:text-green-500 focus:border-green-600"
                          : "focus:text-red-500 focus:border-red-600"
                      } block py-2 px-2 w-full text-sm bg-transparent border-0 border-b-2
  border-zinc-500 appearance-none text-slate-500  
    focus:transition-all focus:duration-200 focus:ease-linear 
    transition-all duration-200 ease-linear focus:outline-0
    focus:outline-none focus:ring-0 peer text-right
  `}
                      placeholder=" "
                    />
                    <label
                      htmlFor="description"
                      className={`${
                        type === "buy"
                          ? "peer-focus:text-green-600"
                          : "peer-focus:text-red-600"
                      } absolute text-xl text-gray-400
  duration-300 transform  -translate-y-5 scale-75
  right-0 top-1 -z-10 peer-focus:right-0 px-5 text-left
  peer-placeholder-shown:translate-y-0 peer-focus:-translate-y-5
    `}
                    >
                      توضیحات
                    </label>
                  </span>
                  {/* Buttons */}
                  <span className="flex justify-center w-full px-4">
                    <button
                      disabled={confirmModal ? true : false}
                      className="text-slate-300 text-base tracking-wider mx-4"
                      onClick={() => {
                        setStatePrice("");
                        setStateWeight("");
                        setStateTotal("");
                        setData("");
                        setType("");
                        setConfirmModal(false);
                        setResultModal(false);
                      }}
                    >
                      لغو
                    </button>
                    <button
                      onClick={() => {
                        setConfirmModal(true);
                        setResultModal(false);
                      }}
                      className={`${
                        type === "buy"
                          ? "bg-green-500 hover:bg-green-600"
                          : "bg-red-500 hover:bg-red-600"
                      } rounded-full px-3 py-1
              tracking-wider text-base text-white
              transition-all duration-100 ease-linear
              hover:transition-all hover:duration-100 hover:ease-linear`}
                    >
                      {type === "buy" ? "خرید" : "فروش"} شما
                    </button>
                  </span>
                </div>
              )}
              {/* End Buy/Sell Modal */}

              {/* Start Confirm Modal */}
              {confirmModal && !resultModal && (
                <form
                  onSubmit={handlePostTransaction}
                  className="absolute flex flex-col py-2 pt-3
        items-center justify-around w-full h-full"
                >
                  <div
                    className="flex text-slate-300 w-full h-full flex-col justify-center px-2 space-y-5
        animate__animated animate__fadeIn animate__fast"
                  >
                    {/* Type Order Row */}
                    <span className="flex justify-center items-center w-full">
                      <span className="font-bold tracking-wider px-2">
                        نوع عملیات :
                      </span>
                      <span className="font-medium tracking-wider text-zinc-300">
                        {type === "buy" ? (
                          <span className="text-green-500">خرید</span>
                        ) : (
                          <span className="text-red-500">فروش</span>
                        )}
                      </span>
                    </span>
                    {/* Price Row */}
                    <span className="flex justify-center items-center w-full">
                      <span className="font-bold tracking-wider px-2">
                        {_item.tag === "S" ? "قیمت" : "مظنه"} :{" "}
                      </span>
                      <span className="font-medium tracking-wider text-zinc-300">
                        <PersianNumber
                          value={statePrice}
                          thousandSeparator=","
                        />
                      </span>
                    </span>
                    {/* Weight Row */}
                    <span className="flex justify-center items-center w-full">
                      <span className="font-bold tracking-wider px-2">
                        {_item.tag === "S" ? "تعداد" : "مقدار (گرم)"} :
                      </span>
                      <span className="font-medium tracking-wider text-zinc-300">
                        {_item.tag === "S" ? (
                          <PersianNumber
                            value={stateWeight}
                            thousandSeparator=","
                          />
                        ) : (
                          <PersianNumber
                            value={stateWeight}
                            thousandSeparator=","
                          />
                        )}
                      </span>
                    </span>
                    {/* Total Row */}
                    <span className="flex justify-center items-center w-full">
                      <span className="font-bold tracking-wider px-2">
                        جمع کل :
                      </span>
                      <span className="font-medium tracking-wider text-zinc-300">
                        <PersianNumber
                          value={stateTotal}
                          thousandSeparator=","
                        />
                      </span>
                    </span>
                    {/* Description Row */}
                    <span className="flex flex-col justify-center items-center w-full">
                      <span className="font-bold tracking-wider px-2">
                        توضیحات :
                      </span>
                      <p className="truncate ... w-full text-center font-medium tracking-wider text-zinc-300">
                        {description}
                      </p>
                    </span>
                    {/* Default Row */}
                    <span className="flex justify-center items-center w-full">
                      <span className="font-bold tracking-wider px-2">
                        {setProduct_name(_item.name)}
                        {setCustomeruser(userInfo[0]?.id)}
                        {type === "buy" ? setBos("B") : setBos("S")}
                        {setIs_orderi(
                          _item.buy_price !== statePrice ? true : false
                        )}
                      </span>
                    </span>
                    {/* Button */}
                    <span className="flex justify-center">
                      <button
                        onClick={() => {
                          setStatePrice("");
                          setStateWeight("");
                          setStateTotal("");
                          setData("");
                          setType("");
                          setConfirmModal(false);
                          setResultModal(false);
                        }}
                        className="rounded-full px-3 py-1 mx-1
              tracking-wider text-base text-slate-300
              transition-all duration-100 ease-linear
              hover:transition-all hover:duration-100 hover:ease-linear"
                      >
                        لغو
                      </button>
                      <button
                        type="submit"
                        className={`${
                          type === "buy"
                            ? "bg-green-500 hover:bg-green-600"
                            : "bg-red-500 hover:bg-red-600"
                        } rounded-full px-3 py-0.5 mx-2
tracking-wider text-base text-white
transition-all duration-100 ease-linear
hover:transition-all hover:duration-100 hover:ease-linear`}
                      >
                        تأیید
                      </button>
                    </span>
                  </div>
                </form>
              )}
              {/* End Confirm Modal */}

              {/* Start Result Modal */}
              {resultModal && (
                <>
                  {transactionId ? (
                    <>
                      <div
                        className="flex text-slate-300 w-full h-full flex-col justify-center px-2 space-y-5
            animate__animated animate__fadeIn animate__fast"
                      >
                        {/* First Row */}
                        <span className="flex flex-col items-center space-y-2 w-full">
                          <span
                            className={`${
                              type === "buy" ? "text-green-500" : "text-red-500"
                            }
                font-bold text-center tracking-wide`}
                          >
                            {type === "buy" ? "خرید " : "فروش "}
                            <span className="text-slate-300">
                              شما به حالت انتظار تغییر پیدا کرد
                            </span>
                          </span>
                          <span className="flex flex-col text-center font-medium tracking-wider text-zinc-300">
                            <span>شماره سفارش :</span>
                            <span>#{transactionId}</span>
                          </span>
                        </span>
                        {/* Button */}
                        <span className="flex justify-center">
                          <button
                            onClick={() => {
                              setStatePrice("");
                              setStateWeight("");
                              setStateTotal("");
                              setData("");
                              setType("");
                              setConfirmModal(false);
                              setResultModal(false);
                            }}
                            className="rounded-full px-3 py-1 mx-1
                tracking-wider text-base text-white 
                bg-green-600 hover:bg-green-700
                transition-all duration-100 ease-linear
                hover:transition-all hover:duration-100 hover:ease-linear"
                          >
                            بستن
                          </button>
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="flex w-full h-full flex-col 
              justify-center space-y-5
            animate__animated animate__fadeIn animate__fast"
                      >
                        <span
                          className="flex flex-col items-center w-full
              text-slate-300 font-bold text-center leading-loose"
                        >
                          <span>سفارش شما ثبت نگردید</span>
                          <span>لطفا دوباره تلاش کنید!</span>
                        </span>
                        {/* Button */}
                        <span className="flex justify-center">
                          <button
                            onClick={() => {
                              setStatePrice("");
                              setStateWeight("");
                              setStateTotal("");
                              setData("");
                              setType("");
                              setConfirmModal(false);
                              setResultModal(false);
                            }}
                            className="rounded-full px-3 py-1 mx-1
                tracking-wider text-base text-white 
                bg-green-600 hover:bg-green-700
                transition-all duration-100 ease-linear
                hover:transition-all hover:duration-100 hover:ease-linear"
                          >
                            بستن
                          </button>
                        </span>
                      </div>
                    </>
                  )}
                </>
              )}
              {/* End Result Modal */}
            </div>
          )}
          {/* End Modal */}
          {/* Information */}
          <span
            className={`${
              _item.id === data.id ? "hidden" : "flex"
            } flex-col p-6 w-full space-y-5
animate__animated animate__fadeIn animate__fast`}
          >
            {/* Discount */}
            <span className="absolute text-xs text-white top-4 right-4">
              {_item.change === -1 ? (
                "-"
              ) : (
                <span
                  className={`${
                    _item.change < 0
                      ? "bg-red-500"
                      : _item.change > 0
                      ? "bg-green-500"
                      : _item.change === 0
                      ? ""
                      : null
                  } inline-flex items-center justify-center 
      rounded-full px-2 py-1 text-xs font-bold 
      leading-none text-zinc-50`}
                >
                  <PersianNumber value={_item.change} suffix={"%"} />
                </span>
              )}
            </span>
            {/* Last Update */}
            <span className="absolute text-xs font-bold text-slate-400 rounded-full px-1 py-0.5 -top-2 left-4">
              <span className="text-xs">{_item.last_update_time_ago}</span>
            </span>
            {/* Name */}
            <h4 className="mt-1 text-xl tracking-wider font-bold text-gray-100 leading-tight text-center">
              {_item.name}
            </h4>
            {/* Prices */}
            <div className="flex justify-between w-full text-center font-bold text-sm tracking-wider pt-4">
              {/* Green Price */}
              <span
                onClick={() => {
                  setStateWeight("");
                  setStateTotal("");
                  setDescription("");
                  setData("");
                  setType("");
                  setStatePrice(_item.buy_price);
                  setData(_item);
                  setType("buy");
                }}
                className="text-green-500 w-[50%] cursor-pointer 
flex justify-center text-xl tracking-wide font-thin"
              >
                {_item.buy_price === -1 ? null : (
                  <>
                    {_item.sell_format === "+" ? (
                      <>
                        <span className="ml-1 mt-1">
                          <IoArrowUpOutline />
                        </span>
                      </>
                    ) : _item.sell_format === "-" ? (
                      <>
                        <span>
                          <IoArrowDownOutline />
                        </span>
                      </>
                    ) : _item.sell_format === "|" ? null : null}
                  </>
                )}
                {_item.buy_price === -1 ? (
                  "-"
                ) : (
                  <PersianNumber
                    value={_item.buy_price}
                    thousandSeparator=","
                  />
                )}
                {_item.buy_price === -1 ? null : (
                  <p className="text-xs text-gray-400 text-center mt-2 mr-1">
                    تومان
                  </p>
                )}
              </span>
              {/* Red Price */}
              <span
                onClick={() => {
                  setStateWeight("");
                  setStateTotal("");
                  setDescription("");
                  setData("");
                  setType("");
                  setStatePrice(_item.sell_price);
                  setData(_item);
                  setType("sell");
                }}
                className="text-red-500 w-[50%] cursor-pointer 
flex justify-center text-xl tracking-wide font-thin"
              >
                {_item.sell_price === -1 ? null : (
                  <>
                    {_item.sell_format === "+" ? (
                      <>
                        <span className="ml-1 mt-1">
                          <IoArrowUpOutline />
                        </span>
                      </>
                    ) : _item.sell_format === "-" ? (
                      <>
                        <span>
                          <IoArrowDownOutline />
                        </span>
                      </>
                    ) : _item.sell_format === "|" ? null : null}
                  </>
                )}
                {_item.sell_price === -1 ? (
                  "-"
                ) : (
                  <PersianNumber
                    value={_item.sell_price}
                    thousandSeparator=","
                  />
                )}
                {_item.sell_price === -1 ? null : (
                  <p className="text-xs text-gray-400 text-center mt-2 mr-1">
                    تومان
                  </p>
                )}
              </span>
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default MobileBoxUi;
