import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import {
  toastErrorMessage,
  toastSuccessMessage,
} from "../../../utils/toastMessage/toastMessage";
import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  changePasswordService,
  confirmChangePasswordService,
} from "../../../services/userServices";
import { secondsCountDown } from "../../../utils";

export const changePasswordCustomerContext = createContext({
  phonenumber: "",
  setPhonenumber: () => {},
  isCodeSent: null,
  setIsCodeSent: () => {},
  isOpenModal: null,
  setIsOpenModal: () => {},
  handleChangePassword: () => {},
  handleConfirmChangePassword: () => {},
  isShowCurrentPass: null,
  setIsShowCurrentPass: () => {},
  isShowNewPass: null,
  setIsShowNewPass: () => {},
  isShowConfirmPass: null,
  setIsShowConfirmPass: () => {},
});

const ChangePasswordCustomerContext = ({ children }) => {
  const dispatch = useDispatch();

  const [phonenumber, setPhonenumber] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  // Show Password Status
  const [isShowCurrentPass, setIsShowCurrentPass] = useState(false);
  const [isShowNewPass, setIsShowNewPass] = useState(false);
  const [isShowConfirmPass, setIsShowConfirmPass] = useState(false);

  //* Handle Change Password
  const handleChangePassword = async (userData, event) => {
    event?.preventDefault();

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changePasswordService(userData);
      if (status === 200) {
        setPhonenumber(userData.phonenumber);
        setIsCodeSent(true);
        secondsCountDown("verificationCodeCountDown", 120, () => {
          setIsCodeSent(false);
        });
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Confirm Change Password
  const handleConfirmChangePassword = async (userData, event) => {
    event?.preventDefault();

    const objData = {
      ...userData,
      phonenumber,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await confirmChangePasswordService(objData);
      if (status === 200) {
        setPhonenumber("");
        setIsCodeSent(false);
        setIsOpenModal(false);
        toastSuccessMessage("رمز عبور شما با موفقیت تغییر پیدا کرد");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <changePasswordCustomerContext.Provider
      value={{
        phonenumber,
        setPhonenumber,
        isCodeSent,
        setIsCodeSent,
        isOpenModal,
        setIsOpenModal,
        handleChangePassword,
        handleConfirmChangePassword,
        isShowCurrentPass,
        setIsShowCurrentPass,
        isShowNewPass,
        setIsShowNewPass,
        isShowConfirmPass,
        setIsShowConfirmPass,
      }}
    >
      {children}
    </changePasswordCustomerContext.Provider>
  );
};

export default ChangePasswordCustomerContext;
