import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import {
  toastErrorMessage,
  toastSuccessMessage,
} from "../../../utils/toastMessage/toastMessage";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  changeUserActiveServices,
  createOrderByAdminService,
  createUsersAdminService,
  deleteUsersAdminService,
  editUserAdminServices,
  editUserToleranceAdminServices,
  getProductsListPublicService,
  getUserToleranceAdminService,
  getUsersAdminService,
} from "../../../services/userServices";

export const usersAdminContext = createContext({
  dataItems: [],
  setDataItems: () => {},
  getUsers: [],
  setGetUsers: () => {},
  addUser: null,
  setAddUser: () => {},
  userId: "",
  setUserId: () => {},
  handleCreateUserAdmin: () => {},
  handleGetUsersAdmin: () => {},
  handleOpenEditModal: () => {},
  handleCloseEditModal: () => {},
  handleEditUserAdmin: () => {},
  handleChangeActiveUser: () => {},
  handleDeleteUser: () => {},
  // * Tolerance Part
  getProducts: [],
  setGetProducts: () => {},
  userID: "",
  setUserID: () => {},
  handleGetProducts: () => {},
  handleGetUserTolerance: () => {},
  handleOpenEditModalUserTolerance: () => {},
  handleCloseEditModalUserTolerance: () => {},
  handleEditUserToleranceAdmin: () => {},
  handleBuyToleranceChange: () => {},
  handleSellToleranceChange: () => {},
  isOpenInfo: null,
  setIsOpenInfo: () => {},
  productName: "",
  setProductName: () => {},
  bTolerance: "",
  setBTolerance: () => {},
  sTolerance: "",
  setSTolerance: () => {},
  //* Order By Admin
  addCommas: () => {},
  stateWeight: "",
  setStateWeight: () => {},
  statePrice: "",
  setStatePrice: () => {},
  stateTotal: "",
  setStateTotal: () => {},
  bos: "",
  setBos: () => {},
  tType: "",
  setTtype: () => {},
  is_orderi: null,
  setIs_orderi: () => {},
  description: "",
  setDescription: () => {},
  product_name: "",
  setProduct_name: () => {},
  transactionId: "",
  setTransactionId: () => {},
  customeruser: "",
  setCustomeruser: () => {},
  adminUser: "",
  setAdminUser: () => {},
  userSelected: [],
  setUserSelected: () => {},
  selectedProductId: "",
  setSelectedProductId: () => {},
  productData: {},
  setProductData: () => {},
  OrderByAdminModal: null,
  setOrderByAdminModal: () => {},
  isOpenProduct: null,
  setIsOpenProduct: () => {},
  isOpenInputs: null,
  setIsOpenInputs: () => {},
  isShowButton: null,
  setIsShowButton: () => {},
  isShowTransactionId: null,
  handleOpenOrderModal: () => {},
  setIsShowTransactionId: () => {},
  handlePostTransaction: () => {},
  handlePriceChange: () => {},
  handleWeightChange: () => {},
  handleTotalChange: () => {},
  handleProductChange: () => {},
  handleShowButton: () => {},
});

const UsersAdminContext = ({ children }) => {
  const dispatch = useDispatch();
  const [dataItems, setDataItems] = useState([]);
  const [getUsers, setGetUsers] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [userId, setUserId] = useState("");
  const [isAllowed] = useState(false);
  // * Tolerance Part
  const [getProducts, setGetProducts] = useState([]);
  const [userID, setUserID] = useState("");
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [product, setProduct] = useState("");
  const [productName, setProductName] = useState("");
  const [bTolerance, setBTolerance] = useState("");
  const [sTolerance, setSTolerance] = useState("");
  const [id, setId] = useState("");

  //* Order By Admin
  const [stateWeight, setStateWeight] = useState("");
  const [statePrice, setStatePrice] = useState("");
  const [stateTotal, setStateTotal] = useState("");
  const [bos, setBos] = useState("");
  const [tType, setTtype] = useState("T");
  const [isOrderi, setIsOrderi] = useState(false);
  const [description, setDescription] = useState("");
  const [product_name, setProduct_name] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [customeruser, setCustomeruser] = useState("");
  const [adminUser, setAdminUser] = useState("");
  const [userSelected, setUserSelected] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [productData, setProductData] = useState({});
  const [OrderByAdminModal, setOrderByAdminModal] = useState(false);
  const [isOpenProduct, setIsOpenProduct] = useState(false);
  const [isOpenInputs, setIsOpenInputs] = useState(false);
  const [isShowButton, setIsShowButton] = useState(false);
  const [isShowTransactionId, setIsShowTransactionId] = useState(false);

  // Handle Create User Admin
  const handleCreateUserAdmin = async (userData) => {
    const objData = {
      ...userData,
      gender: Number(userData.gender),
      isAllowed,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await createUsersAdminService(objData);
      if (status === 201) {
        setAddUser(false);
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Get Users Admin
  const handleGetUsersAdmin = async () => {
    try {
      dispatch(setLoadingTrueAction());
      const { status, data } = await getUsersAdminService();
      if (status === 200) {
        setGetUsers(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Edit Part
  // Handle Edit Product Admin
  const handleOpenEditModal = (_item, setValueEditUser) => {
    setUserId(_item.id);

    setValueEditUser("id", _item.id);
    setValueEditUser("firstname", _item.firstname);
    setValueEditUser("lastname", _item.lastname);
    setValueEditUser("phonenumber", _item.phonenumber);
    setValueEditUser("nationalid", _item.nationalid);
    setValueEditUser("gender", _item.gender);
    setValueEditUser("user_type", _item.user_type);
    setValueEditUser("isAllow", _item.isAllowed);
  };

  const handleCloseEditModal = () => {
    setUserId("");
  };

  const handleEditUserAdmin = async (userData) => {
    const objData = {
      ...userData,
      gender: Number(userData.gender),
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editUserAdminServices(objData);
      if (status === 200) {
        handleCloseEditModal();
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  // Change Active User
  const handleChangeActiveUser = async (id, isAllowed) => {
    const objData = { id, isAllowed };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changeUserActiveServices(objData);
      if (status === 200) {
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Delete User Context
  const handleDeleteUser = async (id) => {
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await deleteUsersAdminService(id);
      if (status === 200) {
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // * Tolerance Part
  const addCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // Handle Get Products Admin
  const handleGetProducts = async () => {
    try {
      const { status, data } = await getProductsListPublicService();
      if (status === 200) {
        setGetProducts(data);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  // Handle Get User Tolerance Admin
  const handleGetUserTolerance = async (event) => {
    event.preventDefault();

    const objData = {
      productName,
      userID,
    };

    try {
      const { status, data } = await getUserToleranceAdminService(objData);
      if (status === 200) {
        setIsOpenInfo(true);
        setBTolerance(data[0]?.buy_telorance);
        setSTolerance(data[0]?.sell_telorance);
        setId(data[0]?.id);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    }
  };

  const handleBuyToleranceChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    setBTolerance(addCommas(value));
  };

  const handleSellToleranceChange = (e) => {
    const value = e.target.value.replace(/,/g, "");
    setSTolerance(addCommas(value));
  };

  // Handle Edit User Tolerance Admin
  const handleOpenEditModalUserTolerance = (_item) => {
    setUserID(_item.id);
    setBTolerance(bTolerance);
    setSTolerance(sTolerance);
  };

  const handleCloseEditModalUserTolerance = () => {
    setIsOpenInfo(false);
    setUserID("");
  };

  const handleEditUserToleranceAdmin = async (event) => {
    event.preventDefault();

    // Ensure that bTolerance and sTolerance are strings
    const buyToleranceStr =
      typeof bTolerance === "string" ? bTolerance : String(bTolerance);
    const sellToleranceStr =
      typeof sTolerance === "string" ? sTolerance : String(sTolerance);

    const buyTolerance = parseFloat(buyToleranceStr.replace(/,/g, ""));
    const sellTolerance = parseFloat(sellToleranceStr.replace(/,/g, ""));

    // cons t buyTolerance = parseFloat(bTolerance.replace(/,/g, ""));
    // const sellTolerance = parseFloat(sTolerance.replace(/,/g, ""));

    const objData = {
      buy_telorance: buyTolerance,
      sell_telorance: sellTolerance,
    };

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await editUserToleranceAdminServices(objData, id);
      if (status === 200) {
        setProductName("");
        setBTolerance("");
        setSTolerance("");
        toastSuccessMessage("تغییرات شما با موفقیت ذخیره شد");
        handleGetUsersAdmin();
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };
  // * Tolerance Part

  //* Order By Admin Part
  const handleOpenOrderModal = (_item) => {
    setUserSelected(_item);
    setCustomeruser(_item?.id);
    setOrderByAdminModal(true);
    setIsOpenProduct(false);
    setIsOpenInputs(false);
    setBos("");
    setProductData("");
    setSelectedProductId("");
    setStatePrice("");
    setStateWeight("");
    setStateTotal("");
    setDescription("");
  };

  // Handle Post Transaction Public
  const handlePostTransaction = async (e) => {
    e?.preventDefault();

    const objData = {
      amount: Number(stateTotal),
      volume: Number(stateWeight),
      price: Number(statePrice),
      tType,
      description,
      bos,
      is_orderi: isOrderi,
      product_name,
      customeruser,
      user: adminUser,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await createOrderByAdminService(objData)
        .then((response) => {
          setIsShowTransactionId(true);
          setTransactionId(response.data.transactionID);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      if (status === 200) {
        setStatePrice("");
        setStateWeight("");
        setStateTotal("");
        setBos("");
        setTtype("");
        setIsOrderi("");
        setDescription("");
        setProduct_name("");
        setAdminUser("");
        setCustomeruser("");
        setSelectedProductId("");
        setProductData("");
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
      dispatch(setLoadingFalseAction());
    }
  };

  // Handle Prices
  const handlePriceChange = (_item, e) => {
    e.preventDefault();
    const price = parseFloat(e.target.value.replace(/,/g, ""));
    const pricePerOne = isNaN(price)
      ? 0
      : _item.tag === "T"
      ? price / 4.3318
      : price;
    const weight = stateWeight;
    const total = (pricePerOne * weight).toFixed(0);
    setStatePrice(isNaN(price) ? 0 : price);
    setStateTotal(total);
  };

  // Handle Weight
  const handleWeightChange = (_item, e) => {
    e.preventDefault();
    const pricePerOne = isNaN(statePrice)
      ? 0
      : _item.tag === "T"
      ? statePrice / 4.3318
      : statePrice;
    const inputValue = e.target.value;

    // If _item.tag is "S", ensure that the input is a whole number
    if (_item.tag === "S" && !/^\d+$/.test(inputValue)) {
      // If the input is not a whole number, don't update the state
      return;
    }

    // Check if the input is a valid number format (including the decimal point)
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // Update state with the input value
      let limitedValue = inputValue;
      if (inputValue.includes(".")) {
        const parts = inputValue.split(".");
        if (parts[1].length >= 4) {
          return;
        } else {
          limitedValue = `${parts[0]}.${parts[1].slice(0, 3)}`;
        }
      }
      setStateWeight(limitedValue);
      const weight = parseFloat(inputValue);
      const total = (pricePerOne * (isNaN(weight) ? 0 : weight)).toFixed(0);
      setStateTotal(total);
    }
  };

  // Handle Total
  const handleTotalChange = (_item, e) => {
    e.preventDefault();
    const total = parseFloat(e.target.value.replace(/,/g, ""));
    let pricePerOne = 0;
    if (_item.tag === "T") {
      pricePerOne = isNaN(statePrice) ? 0 : statePrice / 4.3318;
      const weight = total / pricePerOne;
      setStateTotal(isNaN(total) ? 0 : total);
      setStateWeight(isNaN(weight) ? 0 : weight.toFixed(3));
    } else {
      pricePerOne = isNaN(statePrice) ? 0 : statePrice;
    }
  };

  // Handle Get Data From Select Tag For Order By Admin Part
  const handleProductChange = (e) => {
    const selectedId = e.target.value;
    setSelectedProductId(selectedId);

    const selectedProduct = getProducts?.find(
      (_item) => String(_item.id) === String(selectedId)
    );

    if (selectedProduct) {
      setProduct_name(selectedProduct.name);
      setProductData(selectedProduct);
      if (bos === "b") {
        setStatePrice(selectedProduct?.buy_price);
        setIsOpenInputs(true);
      } else {
        setStatePrice(selectedProduct?.sell_price);
        setIsOpenInputs(true);
      }
    }
  };

  // Handle Show Button For Order By Admin
  const handleShowButton = () => {
    if (
      bos &&
      selectedProductId !== "" &&
      statePrice !== "" &&
      stateWeight !== "" &&
      stateTotal !== ""
    ) {
      setIsShowButton(true);
    } else {
      setIsShowButton(false);
    }
  };

  //* Order By Admin Part

  return (
    <usersAdminContext.Provider
      value={{
        dataItems,
        setDataItems,
        getUsers,
        setGetUsers,
        addUser,
        setAddUser,
        userId,
        setUserId,
        handleCreateUserAdmin,
        handleGetUsersAdmin,
        handleOpenEditModal,
        handleCloseEditModal,
        handleEditUserAdmin,
        handleChangeActiveUser,
        handleDeleteUser,
        // * Tolerance Part
        isOpenInfo,
        setIsOpenInfo,
        product,
        setProduct,
        getProducts,
        setGetProducts,
        userID,
        setUserID,
        handleGetProducts,
        handleGetUserTolerance,
        handleOpenEditModalUserTolerance,
        handleCloseEditModalUserTolerance,
        handleEditUserToleranceAdmin,
        productName,
        setProductName,
        bTolerance,
        handleBuyToleranceChange,
        sTolerance,
        handleSellToleranceChange,
        //* Order By Admin
        addCommas,
        stateWeight,
        setStateWeight,
        statePrice,
        setStatePrice,
        stateTotal,
        setStateTotal,
        bos,
        setBos,
        tType,
        setTtype,
        isOrderi,
        setIsOrderi,
        description,
        setDescription,
        product_name,
        setProduct_name,
        transactionId,
        setTransactionId,
        customeruser,
        setCustomeruser,
        adminUser,
        setAdminUser,
        userSelected,
        setUserSelected,
        selectedProductId,
        setSelectedProductId,
        productData,
        setProductData,
        OrderByAdminModal,
        setOrderByAdminModal,
        isOpenProduct,
        setIsOpenProduct,
        isOpenInputs,
        setIsOpenInputs,
        isShowButton,
        setIsShowButton,
        isShowTransactionId,
        setIsShowTransactionId,
        handleOpenOrderModal,
        handlePostTransaction,
        handlePriceChange,
        handleWeightChange,
        handleTotalChange,
        handleProductChange,
        handleShowButton,
      }}
    >
      {children}
    </usersAdminContext.Provider>
  );
};

export default UsersAdminContext;
