import { useContext } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { logo } from "../../../assets";
import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";

import { HiEye, HiEyeOff } from "react-icons/hi";

import { authContext } from "../../../context/AuthContext/AuthContext";
import {
  confirmForgetPasswordUserValidation,
  forgetPasswordUserValidation,
} from "../../../utils/validation/validation";
import ValidationErrorMessage from "../../../helpers/ValidationErrorMessage/ValidationErrorMessage";

const ForgetPassword = () => {
  const {
    isShowPassForgetPass,
    setIsShowPassForgetPass,
    isShowPassForgetPassConfirm,
    setIsShowPassForgetPassConfirm,
    phonenumber,
    isReceiveCode,
    handleForgetPassword,
    handleConfirmForgetPassword,
  } = useContext(authContext);

  // Validation Forget Password
  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    formState: { errors: errorsForm1 },
  } = useForm({
    resolver: yupResolver(forgetPasswordUserValidation),
  });

  // Validation Confirm Forget Password
  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
  } = useForm({
    resolver: yupResolver(confirmForgetPasswordUserValidation),
  });

  return (
    <>
      <SiteTitle title="تغییر رمز عبور حساب کاربری" />
      <div className="bg-gray-900">
        <div className="flex justify-center h-screen">
          {/* Background - Text */}
          <div className="hidden bg-cover lg:block lg:w-2/3 bg-log-in-wallpaper">
            <div className="flex items-center h-full px-10 bg-black bg-opacity-50">
              <div className="text-right">
                <h2 className="text-4xl font-bold text-white">
                  برادران ثمره گلستانی
                </h2>
              </div>
            </div>
          </div>
          {/* Login Part */}
          <div
            className="flex items-center relative
            w-full max-w-md px-6 mx-auto lg:w-2/6
            animate__animated animate__fadeIn animate__slow"
          >
            <div className="flex flex-col w-full items-center justify-center">
              {/* Logo */}
              <div className="flex justify-center items-center my-3">
                <img
                  src={logo}
                  alt="Website Logo"
                  width={100}
                  className="rounded-full bg-cover"
                />
              </div>
              <div className="flex-1 w-full">
                {/* Title */}
                <div className="text-center">
                  <h2 className="text-4xl font-bold text-center text-white">
                    تغییر رمز عبور
                  </h2>
                </div>
                {/* Forget Password */}
                {!isReceiveCode && (
                  <>
                    <div className="mt-8">
                      <form
                        onSubmit={handleSubmitForm1(handleForgetPassword)}
                        className="space-y-6"
                      >
                        {/* Phone Number  */}
                        <div>
                          <label
                            htmlFor="phonenumber"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            شماره موبایل
                          </label>
                          <input
                            type="number"
                            name="phonenumber"
                            placeholder="09123456789"
                            autoComplete="off"
                            {...registerForm1("phonenumber")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      focus:border-yellow-400 tracking-widest mb-2 border-gray-600
                      focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsForm1.phonenumber?.message}
                            />
                          </span>
                        </div>
                        {/* Button */}
                        <div>
                          <button
                            type="submit"
                            className="w-full px-4 py-2 tracking-wide text-white font-medium
                      transition-colors duration-200 transform rounded-md
                      bg-yellow-500 hover:bg-yellow-400 focus:outline-none"
                          >
                            دریافت کد
                          </button>
                        </div>
                      </form>
                      {/* Create Account */}
                      <p className="mt-6 text-sm font-medium text-center text-gray-400">
                        <Link
                          to="/"
                          className="text-yellow-500 focus:outline-none"
                        >
                          ورود حساب کاربری
                        </Link>
                      </p>
                    </div>
                  </>
                )}
                {/* Confirm Forget Password */}
                {isReceiveCode && (
                  <>
                    <div className="mt-8">
                      <form
                        onSubmit={handleSubmitForm2(
                          handleConfirmForgetPassword
                        )}
                        className="space-y-6"
                      >
                        {/* Phone Number  */}
                        <div>
                          <label
                            htmlFor="phoneNumber"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            شماره موبایل
                          </label>
                          <input
                            type="number"
                            name="phoneNumber"
                            disabled="true"
                            readOnly
                            autoComplete="off"
                            value={phonenumber}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-900 bg-gray-900 text-gray-300 
                      focus:border-yellow-400 tracking-widest mb-2 border-gray-600
                      focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsForm2.phonenumber?.message}
                            />
                          </span>
                        </div>
                        {/* Password */}
                        <div className="relative">
                          <label
                            htmlFor="password"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            رمز عبور
                          </label>
                          <input
                            type={isShowPassForgetPass ? "text" : "password"}
                            name="password"
                            placeholder="******"
                            autoComplete="off"
                            {...registerForm2("password")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                        placeholder-gray-600 bg-gray-900 text-gray-300 
                        border-gray-600 focus:border-yellow-400 mb-2
                        focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          {/* Show/Hide password Icon */}
                          <span className="absolute left-2 top-10">
                            {isShowPassForgetPass ? (
                              <HiEye
                                onClick={() =>
                                  setIsShowPassForgetPass(!isShowPassForgetPass)
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            ) : (
                              <HiEyeOff
                                onClick={() =>
                                  setIsShowPassForgetPass(!isShowPassForgetPass)
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            )}
                          </span>
                          <span>
                            <ValidationErrorMessage
                              message={errorsForm2.password?.message}
                            />
                          </span>
                        </div>
                        {/* Confirm Password */}
                        <div className="relative">
                          <label
                            htmlFor="confirmpassword"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            تکرار رمز عبور
                          </label>
                          <input
                            type={
                              isShowPassForgetPassConfirm ? "text" : "password"
                            }
                            name="confirmpassword"
                            placeholder="******"
                            autoComplete="off"
                            {...registerForm2("confirmpassword")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                        placeholder-gray-600 bg-gray-900 text-gray-300 
                        border-gray-600 focus:border-yellow-400 mb-2
                        focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          {/* Show/Hide Password Icon */}
                          <span className="absolute left-2 top-10">
                            {isShowPassForgetPassConfirm ? (
                              <HiEye
                                onClick={() =>
                                  setIsShowPassForgetPassConfirm(
                                    !isShowPassForgetPassConfirm
                                  )
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            ) : (
                              <HiEyeOff
                                onClick={() =>
                                  setIsShowPassForgetPassConfirm(
                                    !isShowPassForgetPassConfirm
                                  )
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            )}
                          </span>
                          <span>
                            <ValidationErrorMessage
                              message={errorsForm2.confirmpassword?.message}
                            />
                          </span>
                        </div>
                        {/* Verify Code  */}
                        <div>
                          <label
                            htmlFor="token"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            کد تأیید
                          </label>
                          <input
                            type="number"
                            name="token"
                            placeholder="*****"
                            autoComplete="off"
                            {...registerForm2("token")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      focus:border-yellow-400 tracking-widest mb-2 border-gray-600
                      focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsForm2.token?.message}
                            />
                          </span>
                        </div>
                        {/* Timer */}
                        <p className="text-center mt-2 text-gray-200">
                          زمان باقی مانده
                          <span id="verificationCodeCountDown" className="mx-2">
                            120
                          </span>
                          ثانیه
                        </p>
                        {/* Button */}
                        <div>
                          <button
                            type="submit"
                            className="w-full px-4 py-2 tracking-wide text-white font-medium
                      transition-colors duration-200 transform rounded-md
                      bg-yellow-500 hover:bg-yellow-400 focus:outline-none"
                          >
                            تغییر رمز عبور
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
