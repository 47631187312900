import { useContext, useEffect } from "react";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { HiOutlinePlusSm, HiOutlineX } from "react-icons/hi";
import { HiMiniStar } from "react-icons/hi2";
import { IoIosPricetags } from "react-icons/io";

import PersianNumber from "../../../../utils/PersianNumber/PersianNumber";
import { productCreateUserValidation } from "../../../../utils/validation/validation";
import SiteTitle from "../../../../helpers/SiteTitle/SiteTitle";
import ValidationErrorMessage from "../../../../helpers/ValidationErrorMessage/ValidationErrorMessage";

import { productsAdminContext } from "../../../../context/AdminContext/ProductsAdminContext/ProductsAdminContext";

const AdminDashboardProducts = () => {
  const {
    getProducts,
    addProduct,
    setAddProduct,
    editProductId,
    editProductData,
    buyTolerance,
    sellTolerance,
    buyCalculate,
    sellCalculate,
    balance,
    handleCreateProductAdmin,
    handleGetProductsAdmin,
    handleOpenEditModal,
    handleCloseEditModal,
    handleEditProductAdmin,
    handleChangePriorityProduct,
    handleChangeActiveProduct,
    handleOpenCreateModal,
    handleChangeBuyTolerance,
    handleChangeSellTolerance,
    handleChangeBuyCalculate,
    handleChangeSellCalculate,
    handleChangeBalance,
    //* Product Information
    productInfoId,
    productInfoData,
    handleOpenProductInfo,
    handleCloseProductInfo,
  } = useContext(productsAdminContext);

  useEffect(() => {
    handleGetProductsAdmin();
  }, []);

  // Validation
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    // defaultValues: {
    //   related_product: getProducts?.related_product || "",
    // },
    // resolver: yupResolver(productCreateUserValidation),
  });

  // Get All Id
  const id = getProducts.map((item) => item.id);

  return (
    <>
      <SiteTitle title="لیست محصولات" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full mb-16">
        <div className="flex h-full justify-center items-center rounded-xl bg-[#252839]">
          <div className="rounded-xl h-full w-full relative animate__animated animate__fadeIn animate__fast">
            {/* Table */}
            <div className="w-full flex-col mt-10">
              {/* Add Product Button */}
              <div className="w-full flex justify-end items-center pl-1.5">
                <button
                  onClick={() => {
                    reset();
                    handleOpenCreateModal();
                  }}
                  className="flex items-center px-2 lg:py-2 py-1
                  text-white bg-green-500 hover:bg-green-600 rounded-full
                   font-bold lg:text-sm text-xs tracking-wide"
                >
                  اضافه کردن محصول
                  <HiOutlinePlusSm size={20} />
                </button>
              </div>
              {/* Check Empty Array */}
              {isEmpty(getProducts) ? (
                <>
                  <span
                    className="mr-auto w-full py-3 mt-10
                   text-slate-200 text-xl flex flex-col
                    justify-center items-center absolute top-[50%]"
                  >
                    <span>
                      <IoIosPricetags size={60} />
                    </span>
                    <p>محصولی وجود ندارد</p>
                  </span>
                </>
              ) : (
                <>
                  <table className="w-full h-full">
                    <thead className="text-sm font-medium text-slate-200 border-b-[1px] border-[#A79B81]">
                      <tr>
                        <th className="py-3">
                          <div>نام محصول</div>
                        </th>
                        <th className="py-3 hidden lg:table-cell">
                          <div>قیمت خرید / قیمت فروش</div>
                        </th>
                        <th className="py-3 hidden lg:table-cell">
                          <div>سود فروش / سود خرید</div>
                        </th>
                        <th className="py-3">
                          <div>وضعیت</div>
                        </th>
                        <th className="py-3">
                          <div>تقدم</div>
                        </th>
                        <th className="py-3">
                          <div>موجودی</div>
                        </th>
                        <th className="py-3">
                          <div>عملیات</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg divide-y divide-gray-700 w-full h-full overflow-y-scroll">
                      {getProducts.map((_item) => (
                        <>
                          <tr
                            key={_item.id}
                            className="hover:bg-gray-900 w-full h-16 relative"
                          >
                            {/* Product Name */}
                            <td>
                              <div className="flex justify-center text-center lg:text-sm text-xs font-medium text-slate-200">
                                <p
                                  className="w-6/12 cursor-pointer"
                                  onClick={() => handleOpenProductInfo(_item)}
                                >
                                  {_item.name}
                                </p>
                              </div>
                            </td>
                            {/* Buy / Sell Price */}
                            <td className="hidden lg:table-cell">
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  {_item.buy_price === -1 ? (
                                    "_"
                                  ) : (
                                    <PersianNumber
                                      value={_item.buy_price}
                                      thousandSeparator=","
                                    />
                                  )}
                                  <span> - </span>
                                  {_item.sell_price === -1 ? (
                                    "_"
                                  ) : (
                                    <PersianNumber
                                      value={_item.sell_price}
                                      thousandSeparator=","
                                    />
                                  )}
                                </p>
                              </div>
                            </td>
                            {/* Buy /Sell Tolerance */}
                            <td className="hidden lg:table-cell">
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  <PersianNumber
                                    value={_item.b_tel}
                                    thousandSeparator=","
                                  />
                                  <span> - </span>
                                  <PersianNumber
                                    value={_item.s_tel}
                                    thousandSeparator=","
                                  />
                                </p>
                              </div>
                            </td>
                            {/* Market Status */}
                            <td>
                              <div className="flex justify-center text-slate-200 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  {_item.is_market_close === true ? (
                                    <>
                                      <span>بسته</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>باز</span>
                                    </>
                                  )}
                                </p>
                              </div>
                            </td>
                            {/* Priority */}
                            <td>
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  {_item.periority ? (
                                    <span
                                      onClick={() =>
                                        handleChangePriorityProduct(
                                          _item.id,
                                          false
                                        )
                                      }
                                      className="px-2.5 rounded-full text-black cursor-pointer"
                                    >
                                      <HiMiniStar size={20} fill="#facc15" />
                                    </span>
                                  ) : (
                                    <span
                                      onClick={() =>
                                        handleChangePriorityProduct(
                                          _item.id,
                                          true
                                        )
                                      }
                                      className="px-2.5 rounded-full text-black cursor-pointer"
                                    >
                                      <HiMiniStar size={20} fill="#fff    " />
                                    </span>
                                  )}
                                </p>
                              </div>
                            </td>
                            {/* Balance */}
                            <td>
                              <div
                                className="flex justify-center text-gray-400 lg:text-sm text-xs"
                                style={{ direction: "ltr" }}
                              >
                                <p>
                                  <PersianNumber
                                    value={_item.balance}
                                    thousandSeparator=","
                                  />
                                </p>
                              </div>
                            </td>
                            {/* Button */}
                            <td className="py-2">
                              <div
                                className="flex lg:flex-row-reverse flex-col justify-center items-center
                              text-gray-950 lg:text-sm text-xs font-bold
                                lg:space-x-2 lg:space-y-0 space-y-2 space-x-0"
                              >
                                {/* Active */}
                                <span>
                                  {_item.is_market_close ? (
                                    <button
                                      onClick={() =>
                                        handleChangeActiveProduct(
                                          _item.id,
                                          false
                                        )
                                      }
                                      className="bg-blue-500 hover:bg-blue-600
                                    text-white lg:px-2.5 px-1 text-xs
                                      lg:py-1 py-0.5 rounded-full"
                                    >
                                      نمایش
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleChangeActiveProduct(
                                          _item.id,
                                          true
                                        )
                                      }
                                      className="bg-red-500 hover:bg-red-600
                                      text-white lg:px-2.5 px-1 text-xs
                                      lg:py-1 py-0.5 rounded-full"
                                    >
                                      عدم نمایش
                                    </button>
                                  )}
                                </span>
                                {/* Edit */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModal(_item, setValue)
                                    }
                                    className="bg-yellow-600 hover:bg-yellow-700 tracking-wide
                        text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    ویرایش
                                  </button>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
          {/* Create Product Modal */}
          {addProduct && (
            <>
              <div
                className="fixed bg-black bg-opacity-40 inset-0
                w-full h-full z-50 flex items-center justify-center
                animate__animated animate__fadeIn animate__fast"
              >
                <div
                  className="flex flex-col absolute lg:top-20 top-10
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full h-full">
                      {/* Title */}
                      <span>
                        <p className="font-medium lg:text-base text-sm text-gray-400">
                          اضافه کردن محصول
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={() => {
                            setAddProduct(false);
                            reset();
                          }}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form onSubmit={handleSubmit(handleCreateProductAdmin)}>
                      <div className="grid grid-cols-1 gap-x-3 w-full">
                        {/* Part 1 */}
                        <span>
                          {/* Product Name $ All Products */}
                          <span className="flex w-full">
                            {/* Product Name */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="productName"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نام محصول
                              </label>
                              <input
                                type="text"
                                name="productName"
                                placeholder="نام محصول"
                                autoComplete="off"
                                {...register("name")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.name?.message}
                                />
                              </span>
                            </span>
                            {/* All Products */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="products"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نرخ گیری از
                              </label>
                              <select
                                name="products"
                                {...register("related_product")}
                                value={watch("related_product") || ""}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  hidden
                                  value=""
                                >
                                  محصولات
                                </option>
                                {getProducts?.map((_item) => (
                                  <option
                                    className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                    value={_item?.id}
                                  >
                                    {_item?.name}
                                  </option>
                                ))}
                              </select>
                            </span>
                          </span>
                          {/* Buy Price / Sell Price */}
                          <span className="flex w-full">
                            {/* Buy Price */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="buyPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                قیمت خرید
                              </label>
                              <input
                                type="text"
                                name="buyPrice"
                                placeholder="قیمت خرید"
                                autoComplete="off"
                                {...register("buy_price")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.buy_price?.message}
                                />
                              </span>
                            </span>
                            {/* Sell Price */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="sellPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                قیمت فروش
                              </label>
                              <input
                                type="text"
                                name="sellPrice"
                                placeholder="قیمت فروش"
                                autoComplete="off"
                                {...register("sell_price")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.sell_price?.message}
                                />
                              </span>
                            </span>
                          </span>
                          {/* Buy Tolerance / Sell Tolerance */}
                          <span className="flex w-full">
                            {/* Buy Tolerance */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="buyTolerance"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سود فروش
                              </label>
                              <input
                                type="text"
                                name="buyTolerance"
                                placeholder="سود فروش"
                                autoComplete="off"
                                value={buyTolerance}
                                onChange={handleChangeBuyTolerance}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                            </span>
                            {/* Sell Tolerance */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="sellTolerance"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سود خرید
                              </label>
                              <input
                                type="text"
                                name="sellTolerance"
                                placeholder="سود خرید"
                                autoComplete="off"
                                value={sellTolerance}
                                onChange={handleChangeSellTolerance}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                            </span>
                          </span>
                          {/* Buy Calculation & Sell Calculation */}
                          <span className="flex w-full">
                            {/* Buy Calculation */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="buyPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                محاسبات نرخ خرید
                              </label>
                              <input
                                type="text"
                                name="buyPrice"
                                placeholder="*1/1+0-0"
                                autoComplete="off"
                                value={buyCalculate}
                                onChange={handleChangeBuyCalculate}
                                style={{ direction: "ltr" }}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                            </span>
                            {/* Sell Calculation */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="sellPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                محاسبات نرخ فروش
                              </label>
                              <input
                                type="text"
                                name="sellPrice"
                                placeholder="*1/1+0-0"
                                autoComplete="off"
                                value={sellCalculate}
                                onChange={handleChangeSellCalculate}
                                style={{ direction: "ltr" }}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                            </span>
                          </span>
                        </span>
                        {/* Part 2 */}
                        <span>
                          {/* Balance */}
                          <span>
                            <label
                              htmlFor="balance"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              موجودی
                            </label>
                            <input
                              type="text"
                              name="balance"
                              placeholder="موجودی"
                              autoComplete="off"
                              value={balance}
                              onChange={handleChangeBalance}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                              w-full lg:text-base text-sm tracking-wide text-slate-400
                              rounded-md focus:right-0 focus:outline-none
                            focus:border-yellow-400 focus:text-yellow-400"
                            />
                          </span>
                          {/* Tag / Market Close */}
                          <span className="flex w-full">
                            {/* Tag */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="tag"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نوع محصول
                              </label>
                              <select
                                name="tag"
                                {...register("tag")}
                                value={watch("tag") || ""}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  hidden
                                  value=""
                                >
                                  نوع محصول
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value="S"
                                >
                                  سکه
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value="T"
                                >
                                  طلا
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.tag?.message}
                                />
                              </span>
                            </span>
                            {/* Market Status */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="marketClose"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                وضعیت بازار
                              </label>
                              <select
                                name="marketClose"
                                {...register("is_market_close")}
                                value={watch("is_market_close") || ""}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  hidden
                                  value=""
                                >
                                  وضعیت بازار
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value={true}
                                >
                                  بسته
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value={false}
                                >
                                  باز
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.is_market_close?.message}
                                />
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          اضافه کردن
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Edit Product Modal */}
          {editProductId && (
            <>
              <div
                className="fixed bg-black bg-opacity-40 inset-0
                  w-full h-full z-50 flex items-center justify-center
                  animate__animated animate__fadeIn animate__fast"
              >
                <div
                  className="flex flex-col absolute lg:top-20 top-10
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full h-full">
                      {/* Title */}
                      <span>
                        <p className="font-medium lg:text-base text-sm text-gray-400">
                          ویرایش کردن محصول{" "}
                          <span className="mx-1 text-gray-300">
                            {editProductData.name}
                          </span>
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={handleCloseEditModal}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form onSubmit={handleSubmit(handleEditProductAdmin)}>
                      <div className="grid grid-cols-1 gap-x-3 w-full">
                        {/* Part 1 */}
                        <span>
                          {/* Product Name & All Products */}
                          <span className="flex w-full">
                            {/* Product Name */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="productName"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نام محصول
                              </label>
                              <input
                                type="text"
                                name="productName"
                                placeholder="نام محصول"
                                autoComplete="off"
                                {...register("name")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.name?.message}
                                />
                              </span>
                            </span>
                            {/* All Products */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="products"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نرخ گیری از
                              </label>
                              <select
                                name="products"
                                {...register("related_product")}
                                value={watch("related_product") || ""}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              >
                                {getProducts?.map((_item) => (
                                  <>
                                    <option
                                      className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                      hidden
                                      value=""
                                    >
                                      {id === _item.related_product_id
                                        ? getProducts.name
                                        : "تعیین نشده"}
                                    </option>
                                    <option
                                      className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                      value={_item?.id}
                                    >
                                      {_item?.name}
                                    </option>
                                  </>
                                ))}
                              </select>
                            </span>
                          </span>
                          {/* Buy Price / Sell Price */}
                          <span className="flex w-full">
                            {/* Buy Price */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="buyPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                قیمت خرید
                              </label>
                              <input
                                type="text"
                                name="buyPrice"
                                placeholder="قیمت خرید"
                                autoComplete="off"
                                {...register("buy_price")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.buy_price?.message}
                                />
                              </span>
                            </span>
                            {/* Sell Price */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="sellPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                قیمت فروش
                              </label>
                              <input
                                type="text"
                                name="sellPrice"
                                placeholder="قیمت فروش"
                                autoComplete="off"
                                {...register("sell_price")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.sell_price?.message}
                                />
                              </span>
                            </span>
                          </span>
                          {/* Buy Tolerance / Sell Tolerance */}
                          <span className="flex w-full">
                            {/* Buy Tolerance */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="buyTolerance"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سود فروش
                              </label>
                              <input
                                type="text"
                                name="buyTolerance"
                                placeholder="سود فروش"
                                autoComplete="off"
                                {...register("s_tel")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.b_tel?.message}
                                />
                              </span>
                            </span>
                            {/* Sell Tolerance */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="sellTolerance"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سود خرید
                              </label>
                              <input
                                type="text"
                                name="sellTolerance"
                                placeholder="سود خرید"
                                autoComplete="off"
                                {...register("b_tel")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.s_tel?.message}
                                />
                              </span>
                            </span>
                          </span>
                          {/* Buy Calculation & Sell Calculation */}
                          <span className="flex w-full">
                            {/* Buy Calculation */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="buyPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                محاسبات نرخ خرید
                              </label>
                              <input
                                type="text"
                                name="buyPrice"
                                placeholder="*1/1+0-0"
                                autoComplete="off"
                                {...register("buy_calculation")}
                                style={{ direction: "ltr" }}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.buy_calculation?.message}
                                />
                              </span>
                            </span>
                            {/* Sell Calculation */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="sellPrice"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                محاسبات نرخ فروش
                              </label>
                              <input
                                type="text"
                                name="sellPrice"
                                placeholder="*1/1+0-0"
                                autoComplete="off"
                                {...register("sell_calculation")}
                                style={{ direction: "ltr" }}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.sell_calculation?.message}
                                />
                              </span>
                            </span>
                          </span>
                        </span>
                        {/* Part 2 */}
                        <span>
                          {/* Balance */}
                          <span>
                            <label
                              htmlFor="balance"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              موجودی
                            </label>
                            <input
                              type="text"
                              name="balance"
                              placeholder="موجودی"
                              autoComplete="off"
                              {...register("balance")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.balance?.message}
                              />
                            </span>
                          </span>
                          {/* Tag / Market Close */}
                          <span className="flex w-full">
                            {/* Tag */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="tag"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نوع محصول
                              </label>
                              <select
                                name="tag"
                                {...register("tag")}
                                value={watch("tag") || ""}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  hidden
                                  value=""
                                >
                                  نوع محصول
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value="S"
                                >
                                  سکه
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value="T"
                                >
                                  طلا
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.tag?.message}
                                />
                              </span>
                            </span>
                            {/* Market Close */}
                            <span className="mx-1 w-full">
                              <label
                                htmlFor="marketClose"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                وضعیت بازار
                              </label>
                              <select
                                name="marketClose"
                                {...register("is_market_close")}
                                value={watch("is_market_close") || ""}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  hidden
                                  value=""
                                >
                                  وضعیت بازار
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value={true}
                                >
                                  بسته
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  value={false}
                                >
                                  باز
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.is_market_close?.message}
                                />
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          ویرایش کردن
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Product Info Modal */}
          {productInfoId && (
            <div
              className="fixed bg-black bg-opacity-40 inset-0
              sm:w-full w-full h-full z-50 flex items-center justify-center
              animate__animated animate__fadeIn animate__fast"
            >
              <div
                className="flex flex-col absolute top-20
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
              >
                <div className="flex flex-row justify-between p-5 bg-[#252839]">
                  {/* Title */}
                  <span className="flex justify-center items-center w-full">
                    <p className="flex font-medium lg:text-base text-sm text-gray-300">
                      مشخصات {productInfoData.name}
                    </p>
                  </span>
                </div>
                <span className="border-b-2 border-[#1A1D27]" />
                {/* Inputs */}
                <div className="w-full px-6 py-5 bg-[#252839]">
                  <div className="grid sm:grid-cols-2 grid-cols-1 justify-around items-center w-full text-sm font-medium">
                    {/* National Number */}
                    <td className="py-4">
                      <div className="flex flex-col justify-center items-center space-y-1">
                        <p className="text-gray-300">قیمت خرید - قیمت فروش</p>
                        <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                          <p className="tracking-wider">
                            {productInfoData.buy_price === -1 ? (
                              "_"
                            ) : (
                              <PersianNumber
                                value={productInfoData.buy_price}
                                thousandSeparator=","
                              />
                            )}
                            <span> - </span>
                            {productInfoData.sell_price === -1 ? (
                              "_"
                            ) : (
                              <PersianNumber
                                value={productInfoData.sell_price}
                                thousandSeparator=","
                              />
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    {/* National Number */}
                    <td className="py-4">
                      <div className="flex flex-col justify-center items-center space-y-1">
                        <p className="text-gray-300">سود فروش - سود خرید</p>
                        <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                          <p className="tracking-wider">
                            <PersianNumber
                              value={productInfoData.b_tel}
                              thousandSeparator=","
                            />
                            <span> - </span>
                            <PersianNumber
                              value={productInfoData.s_tel}
                              thousandSeparator=","
                            />
                          </p>
                        </div>
                      </div>
                    </td>
                  </div>
                  {/* Button */}
                  <div className="flex items-center justify-center p-5 w-full bg-[#252839]">
                    <button
                      type="button"
                      onClick={handleCloseProductInfo}
                      className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                    >
                      بستن
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboardProducts;
