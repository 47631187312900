import { useContext } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { HiOutlineX } from "react-icons/hi";

import { defaultPicture } from "../../../../utils";
import SiteTitle from "../../../../helpers/SiteTitle/SiteTitle";
import ValidationErrorMessage from "../../../../helpers/ValidationErrorMessage/ValidationErrorMessage";

import { changePasswordAdminContext } from "../../../../context/AdminContext/ChangePasswordAdminContext/ChangePasswordAdminContext";

import {
  changePasswordAdminValidation,
  confirmChangePasswordAdminValidation,
} from "../../../../utils/validation/validation";

const AdminDashboardProfile = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const {
    isCodeSent,
    setIsCodeSent,
    isOpenModal,
    setIsOpenModal,
    handleChangePassword,
    handleConfirmChangePassword,
  } = useContext(changePasswordAdminContext);

  // Validation Change Password
  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    formState: { errors: errorsForm1 },
  } = useForm({
    resolver: yupResolver(changePasswordAdminValidation),
  });

  // Validation Confirm Change Password
  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
  } = useForm({
    resolver: yupResolver(confirmChangePasswordAdminValidation),
  });

  return (
    <>
      <SiteTitle title="پروفایل ادمین" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full">
        <div className="flex h-full justify-center items-center rounded-xl bg-[#252839] relative">
          <div
            className="rounded-xl flex flex-col h-full w-full
            animate__animated animate__fadeIn animate__fast"
          >
            {/* Information */}
            <span className="w-full flex flex-col items-center justify-center h-screen">
              <div
                className="flex flex-col items-center w-full p-5 space-y-5
              bg-[#252839] text-[#A79B81] lg:text-lg text-sm font-medium text-center"
              >
                {/* Profile */}
                <span className="xl:w-[30%] md:w-[60%] w-full">
                  <img
                    src={defaultPicture}
                    alt="User_Profile"
                    width={135}
                    className="m-auto text-white rounded-full object-cover"
                  />
                </span>
                {/* User Name */}
                <span
                  className="xl:w-[30%] md:w-[60%] w-full p-2 space-y-2
                      flex flex-col justify-between
                      border-2 border-[#111827] bg-[#111827] rounded-xl"
                >
                  <span>نام کاربر :</span>
                  <span>
                    {userInfo[0]?.firstname} {userInfo[0]?.lastname}
                  </span>
                </span>
                {/* Phone Number */}
                <span
                  className="xl:w-[30%] md:w-[60%] w-full p-2 space-y-2
                      flex flex-col justify-between
                      border-2 border-[#111827] bg-[#111827] rounded-xl"
                >
                  <span>موبایل :</span>
                  <span>{userInfo[0]?.phonenumber}</span>
                </span>
                {/* National Number */}
                <span
                  className="xl:w-[30%] md:w-[60%] w-full p-2 space-y-2
                      flex flex-col justify-between
                      border-2 border-[#111827] bg-[#111827] rounded-xl"
                >
                  <span>کد ملی :</span>
                  <span>{userInfo[0]?.nationalid}</span>
                </span>
                {/* Forget Password Button */}
                <span
                  onClick={() => setIsOpenModal(true)}
                  className="xl:w-[30%] md:w-[60%] w-full p-2
                bg-yellow-400 hover:bg-yellow-500 
                  hover:transition-all hover:duration-150 hover:ease-linear
                  transition-all duration-150 ease-linear
                  cursor-pointer rounded-lg text-slate-800"
                >
                  <button>تغییر رمز عبور</button>
                </span>
              </div>
            </span>
          </div>
          {/* Forget Password Modal */}
          {isOpenModal && (
            <>
              <div
                className="flex absolute bg-black bg-opacity-40 
                inset-0 h-screen items-center w-full z-50 mr-auto 
                animate__animated animate__fadeIn animate__fast"
              >
                <div className="flex flex-col -mt-5 w-11/12 max-w-lg mx-auto rounded-lg border-2 border-[#252839]">
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full">
                      {/* Title */}
                      <span>
                        <p className="font-semibold text-gray-400">
                          تغییر رمز عبور
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={() => {
                            setIsOpenModal(false);
                            setIsCodeSent(false);
                          }}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    {/* First Form */}
                    {!isCodeSent && (
                      <>
                        <form
                          onSubmit={handleSubmitForm1(handleChangePassword)}
                        >
                          <div className="grid grid-cols-1 gap-x-3 w-full">
                            <span className="animate__animated animate__fadeIn animate__fast">
                              {/* Phone Number */}
                              <span>
                                <label
                                  htmlFor="phonenumber"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  شماره موبایل
                                </label>
                                <input
                                  type="text"
                                  name="phonenumber"
                                  placeholder="شماره موبایل"
                                  autoComplete="off"
                                  {...registerForm1("phonenumber")}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                                />
                                <span>
                                  <ValidationErrorMessage
                                    message={errorsForm1.phonenumber?.message}
                                  />
                                </span>
                              </span>
                              {/* Current password */}
                              <span>
                                <label
                                  htmlFor="currentPassword"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  رمز عبور فعلی
                                </label>
                                <input
                                  type="text"
                                  name="currentPassword"
                                  placeholder="رمز عبور فعلی"
                                  autoComplete="off"
                                  {...registerForm1("currentPassword")}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                                />
                                <span>
                                  <ValidationErrorMessage
                                    message={
                                      errorsForm1.currentPassword?.message
                                    }
                                  />
                                </span>
                              </span>
                            </span>
                          </div>
                          {/* Button */}
                          <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                            <button
                              type="submit"
                              className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                            >
                              دریافت کد
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                    {/* Second Form */}
                    {isCodeSent && (
                      <form
                        onSubmit={handleSubmitForm2(
                          handleConfirmChangePassword
                        )}
                      >
                        <div className="grid grid-cols-1 gap-x-3 w-full">
                          <>
                            <span className="animate__animated animate__fadeIn animate__fast">
                              {/* New Password */}
                              <span>
                                <label
                                  htmlFor="newPassword"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  رمز عبور جدید
                                </label>
                                <input
                                  type="text"
                                  name="newPassword"
                                  placeholder="رمز عبور جدید"
                                  autoComplete="off"
                                  {...registerForm2("password")}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                                />
                                <span>
                                  <ValidationErrorMessage
                                    message={errorsForm2.password?.message}
                                  />
                                </span>
                              </span>
                              {/* Confirm New password */}
                              <span>
                                <label
                                  htmlFor="confirmPassword"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  تکرار رمز عبور جدید
                                </label>
                                <input
                                  type="text"
                                  name="confirmPassword"
                                  placeholder="تکرار رمز عبور جدید"
                                  autoComplete="off"
                                  {...registerForm2("confirmpassword")}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                                />
                                <span>
                                  <ValidationErrorMessage
                                    message={
                                      errorsForm2.confirmpassword?.message
                                    }
                                  />
                                </span>
                              </span>
                              {/* Verify Code */}
                              <span>
                                <label
                                  htmlFor="verifyCode"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  کد تأیید
                                </label>
                                <input
                                  type="text"
                                  name="verifyCode"
                                  placeholder="کد تأیید"
                                  autoComplete="off"
                                  {...registerForm2("token")}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                                />
                                <span>
                                  <ValidationErrorMessage
                                    message={errorsForm2.token?.message}
                                  />
                                </span>
                              </span>
                            </span>
                          </>
                        </div>
                        {/* Button */}
                        <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                          <button
                            type="submit"
                            className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                          >
                            تغییر رمز عبور
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboardProfile;
