import * as yup from "yup";

// Phone Number
const phoneNumberRegex = /^09\d{9}$/;

export const isValidPhoneNumber = (phoneNumber) => {
  return phoneNumberRegex.test(phoneNumber);
};

// Email
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email) => {
  return emailRegex.test(email);
};

// Sing-Up Validation
export const signUpUserValidation = yup
  .object({
    firstname: yup.string().required("نام الزامی می باشد"),
    lastname: yup.string().required("نام خانوادگی الزامی می باشد"),
    phonenumber: yup
      .string()
      .required("شماره موبایل الزامی می باشد")
      .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد"),
    nationalid: yup
      .string()
      .required("کد ملی الزامی می باشد")
      .min(10, "کد ملی نمی تواند کمتر از 10 رقم باشد")
      .max(10, "کد ملی نمی تواند بیشتر از 10 رقم باشد"),
    password: yup
      .string()
      .required("رمز عبور الزامی می باشد")
      .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "تکرار رمز عبور یکسان نمی باشد")
      .required("تکرار رمز عبور یکسان نمی باشد"),
    gender: yup.string().required("جنسیت الزامی می باشد"),
  })
  .required();

// Log-In Validation
export const loginUserValidation = yup.object({
  phonenumber: yup
    .string()
    .required("شماره موبایل الزامی می باشد")
    .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد"),
  password: yup
    .string()
    .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد")
    .required("رمز عبور الزامی می باشد"),
});

// Forget Password Validation
export const forgetPasswordUserValidation = yup.object({
  phonenumber: yup
    .string()
    .required("شماره موبایل الزامی می باشد")
    .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد"),
});

// Confirm Forget Password Validation
export const confirmForgetPasswordUserValidation = yup.object({
  password: yup
    .string()
    .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد")
    .required("رمز عبور الزامی می باشد"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "تکرار رمز عبور یکسان نمی باشد")
    .required("تکرار رمز عبور کاربر الزامی می باشد"),
  token: yup.string().required("کد تأیید الزامی می باشذ")
});

// Create User Admin Validation
export const createUserValidation = yup
  .object({
    firstname: yup.string().required("نام کاربر الزامی می باشد"),
    lastname: yup.string().required("نام خانوادگی کاربر الزامی می باشد"),
    phonenumber: yup
      .string()
      .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد")
      .required("شماره موبایل کاربر الزامی می باشد"),
    nationalid: yup.string().required("کد ملی کاربر الزامی می باشد")
      .min(10, "کد ملی نمی تواند کمتر از 10 رقم باشد")
      .max(10, "کد ملی نمی تواند بیشتر از 10 رقم باشد"),
    user_type: yup.string().required("سمت کاربر الزامی می باشد"),
    gender: yup.string().required("جنسیت کاربر الزامی می باشد"),
    password: yup
      .string()
      .required("رمز عبور کاربر الزامی می باشد")
      .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد"),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "تکرار رمز عبور یکسان نمی باشد")
      .required("تکرار رمز عبور کاربر الزامی می باشد"),
  })
  .required();

// Edit User Admin Validation
export const editUserValidation = yup
  .object({
    firstname: yup.string().required("نام کاربر الزامی می باشد"),
    lastname: yup.string().required("نام خانوادگی کاربر الزامی می باشد"),
    phonenumber: yup
      .string()
      .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد")
      .required("شماره موبایل کاربر الزامی می باشد"),
    nationalid: yup.string().required("کد ملی کاربر الزامی می باشد")
      .min(10, "کد ملی نمی تواند کمتر از 10 رقم باشد")
      .max(10, "کد ملی نمی تواند بیشتر از 10 رقم باشد"),
    user_type: yup.string().required("سمت کاربر الزامی می باشد"),
    gender: yup.string().required("جنسیت کاربر الزامی می باشد"),
    isAllowed: yup.string().required("تعیین وضعیت کاربر الزامی می باشد")
  })
  .required();

// Create Product Admin Validation
export const productCreateUserValidation = yup
  .object({
    name: yup.string().required("نام محصول الزامی می باشد"),
    buy_price: yup.string().required("قیمت خرید الزامی می باشد"),
    sell_price: yup.string().required("قیمت فروش الزامی می باشد"),
    tag: yup.string().required("نوع محصول الزامی می باشد"),
    is_market_close: yup.string().required("وضعیت بازار الزامی می باشد"),
    })
  .required();

// Edit Profile Admin Validation
export const profileEditAdminValidation = yup.object({
  firstname: yup.string().required("نام الزامی می باشد"),
  lastname: yup.string().required("نام خانوادگی الزامی می باشد"),
  phonenumber: yup
    .string()
    .required("شماره موبایل الزامی می باشد")
    .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد"),
  nationalid: yup
    .string()
    .required("کد ملی الزامی می باشد")
    .min(10, "کد ملی نمی تواند کمتر از 10 رقم باشد")
    .max(10, "کد ملی نمی تواند بیشتر از 10 رقم باشد"),
});

// Change Password Admin Validation
export const changePasswordAdminValidation = yup.object({
  phonenumber: yup
    .string()
    .required("شماره موبایل الزامی می باشد")
    .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد"),
  currentPassword: yup
    .string()
    .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد")
    .required("رمز عبور الزامی می باشد"),
});

// Confirm Change Password Admin Validation
export const confirmChangePasswordAdminValidation = yup.object({
  password: yup
    .string()
    .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد")
    .required("رمز عبور الزامی می باشد"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "تکرار رمز عبور یکسان نمی باشد")
    .required("تکرار رمز عبور کاربر الزامی می باشد"),
  token: yup.string().required("کد تأیید الزامی می باشذ")
});

// Transaction Public Validation
export const transaction = yup.object({
  price: yup.string().required("قیمت الزامی می باشد"),
  volume: yup.string().required("مقدار الزامی می باشد"),
  amount: yup.string().required("جمع کل الزامی می باشد"),
});

// Change Password Customer Validation
export const changePasswordCustomerValidation = yup.object({
  phonenumber: yup
    .string()
    .required("شماره موبایل الزامی می باشد")
    .matches(phoneNumberRegex, "شماره موبایل وارد شده معتبر نمی باشد"),
  currentPassword: yup
    .string()
    .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد")
    .required("رمز عبور الزامی می باشد"),
});

// Confirm Change Password Customer Validation
export const confirmChangePasswordCustomerValidation = yup.object({
  password: yup
    .string()
    .min(6, "رمز عبور نمی تواند کمتر از 6 رقم باشد")
    .required("رمز عبور الزامی می باشد"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "تکرار رمز عبور یکسان نمی باشد")
    .required("تکرار رمز عبور کاربر الزامی می باشد"),
  token: yup.string().required("کد تأیید الزامی می باشذ")
});

// Add News Admin Validation
export const addNewsAdminValidation = yup.object({
  title: yup.string().required("موضوع اخبار الزامی می باشد"),
  description: yup.string().required("متن اخبار الزامی می باشد"),
});

// Edit News Admin Validation
export const editNewsAdminValidation = yup.object({
  title: yup.string().required("موضوع اخبار الزامی می باشد"),
  description: yup.string().required("متن اخبار الزامی می باشد"),
});

// Edit User Tolerance Admin Validation
export const editUserToleranceAdminValidation = yup.object({
  productName: yup.string().required("نام محصول الزامی می باشد"),
});