import { createContext, useState } from "react";
import { useDispatch } from "react-redux";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../../redux/actions/loadingActions/loadingActions";

import {
  toastErrorMessage,
  toastSuccessMessage,
} from "../../../utils/toastMessage/toastMessage";

import {
  changePasswordService,
  confirmChangePasswordService,
} from "../../../services/userServices";

export const changePasswordAdminContext = createContext({
  phonenumber: "",
  setPhonenumber: () => {},
  isCodeSent: null,
  setIsCodeSent: () => {},
  isOpenModal: null,
  setIsOpenModal: () => {},
  handleChangePassword: () => {},
  handleConfirmChangePassword: () => {},
});

const ChangePasswordAdminContext = ({ children }) => {
  const dispatch = useDispatch();

  const [phonenumber, setPhonenumber] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);

  //* Handle Change Password
  const handleChangePassword = async (userData, event) => {
    event?.preventDefault();

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await changePasswordService(userData);
      if (status === 200) {
        setPhonenumber(userData.phonenumber);
        setIsCodeSent(true);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Confirm Change Password
  const handleConfirmChangePassword = async (userData, event) => {
    event?.preventDefault();

    const objData = {
      ...userData,
      phonenumber,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await confirmChangePasswordService(objData);
      if (status === 200) {
        setPhonenumber("");
        setIsCodeSent(false);
        setIsOpenModal(false);
        toastSuccessMessage("رمز عبور شما با موفقیت تغییر پیدا کرد");
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  return (
    <changePasswordAdminContext.Provider
      value={{
        phonenumber,
        setPhonenumber,
        isCodeSent,
        setIsCodeSent,
        isOpenModal,
        setIsOpenModal,
        handleChangePassword,
        handleConfirmChangePassword,
      }}
    >
      {children}
    </changePasswordAdminContext.Provider>
  );
};

export default ChangePasswordAdminContext;
