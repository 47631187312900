import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import OtpInput from "react-otp-input";

import { logo } from "../../../assets";
import { loginUserValidation } from "../../../utils/validation/validation";
import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";
import ValidationErrorMessage from "./../../../helpers/ValidationErrorMessage/ValidationErrorMessage";
import { authContext } from "../../../context/AuthContext/AuthContext";

import { HiEye, HiEyeOff } from "react-icons/hi";
import { MdInstallDesktop, MdInstallMobile } from "react-icons/md";

const SignIn = () => {
  const {
    isShowPassLogin,
    setIsShowPassLogin,
    loginCode,
    setLoginCode,
    sendCode,
    handleLogIn,
    handleOtpCode,
    setDeferredPrompt,
    showInstallButton,
    setShowInstallButton,
    handleInstallClick,
  } = useContext(authContext);

  // PWA Installer
  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  // Validation
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginUserValidation),
  });

  return (
    <>
      <SiteTitle title="ورود به حساب کاربری" />
      <div className="bg-gray-900">
        <div className="flex justify-center h-screen">
          {/* Background - Text */}
          <div className="hidden bg-cover lg:block lg:w-2/3 bg-log-in-wallpaper">
            <div className="flex items-center h-full px-10 bg-black bg-opacity-50">
              <div className="text-right">
                <h2 className="text-4xl font-bold text-white">
                  برادران ثمره گلستانی
                </h2>
              </div>
            </div>
          </div>
          {/* Login Part */}
          <div
            className="flex items-center relative
            w-full max-w-md px-6 mx-auto lg:w-2/6
            animate__animated animate__fadeIn animate__slow"
          >
            {/* Title Text */}
            <span
              className="flex justify-center w-full 
            absolute top-4 left-0 right-0 text-slate-200"
            >
              <p>هو الرزاق</p>
            </span>
            {/* Logo */}
            <div className="absolute top-[5%] left-0 right-0 flex justify-center items-center mb-24">
              <img
                src={logo}
                alt="Website Logo"
                width={100}
                className="rounded-full bg-cover"
              />
            </div>
            <div className="flex-1">
              {/* Title */}
              <div className="text-center">
                <h2 className="text-4xl font-bold text-center text-white">
                  ورود
                </h2>
              </div>
              <div className={`${sendCode ? "hidden" : "none"} mt-8`}>
                <form
                  onSubmit={handleSubmit(handleLogIn)}
                  className="space-y-6"
                >
                  {/* Phone Number  */}
                  <div>
                    <label
                      htmlFor="phoneNumber"
                      className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                    >
                      شماره موبایل
                    </label>
                    <input
                      type="number"
                      name="phoneNumber"
                      placeholder="09123456789"
                      autoComplete="off"
                      {...register("phonenumber")}
                      className={`${
                        sendCode ? "hidden" : "none border-gray-600"
                      } 
                      block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      focus:border-yellow-400 tracking-widest mb-2
                      focus:outline-none focus:text-yellow-400 font-medium`}
                    />
                    <span>
                      <ValidationErrorMessage
                        message={errors.phonenumber?.message}
                      />
                    </span>
                  </div>
                  {/* Password */}
                  <div className="relative">
                    <label
                      htmlFor="password"
                      className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                    >
                      رمز عبور
                    </label>
                    <input
                      type={isShowPassLogin ? "text" : "password"}
                      name="password"
                      placeholder="******"
                      autoComplete="off"
                      {...register("password")}
                      className={`${
                        sendCode ? "hidden" : "none border-gray-600"
                      } 
                      block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      focus:border-yellow-400 tracking-widest mb-2
                      focus:outline-none focus:text-yellow-400 font-medium`}
                    />
                    {/* Show/Hide Password Icon */}
                    <span className="absolute left-2 top-10">
                      {isShowPassLogin ? (
                        <HiEye
                          onClick={() => setIsShowPassLogin(!isShowPassLogin)}
                          fill="#fff"
                          className="cursor-pointer"
                        />
                      ) : (
                        <HiEyeOff
                          onClick={() => setIsShowPassLogin(!isShowPassLogin)}
                          fill="#fff"
                          className="cursor-pointer"
                        />
                      )}
                    </span>
                    <span>
                      <ValidationErrorMessage
                        message={errors.password?.message}
                      />
                    </span>
                  </div>
                  {/* Button */}
                  <div>
                    <button
                      type="submit"
                      className="w-full px-4 py-2 tracking-wide text-white font-medium
                      transition-colors duration-200 transform rounded-md
                      bg-yellow-500 hover:bg-yellow-400 focus:outline-none"
                    >
                      دریافت کد
                    </button>
                  </div>
                </form>
                {/* Create Account */}
                <p className="mt-6 text-sm font-medium text-center text-gray-400">
                  حساب کاربری ندارید؟
                  <Link
                    to="/Sign-Up"
                    className="text-yellow-500 focus:outline-none mr-1.5"
                  >
                    ثبت نام
                  </Link>
                </p>
                {/* Forget Password */}
                <p className="mt-3 text-sm font-medium text-center text-gray-400">
                  رمز عبور خود را فراموش کردید؟
                  <Link
                    to="/Forget-Password"
                    className="text-yellow-500 focus:outline-none mr-1.5"
                  >
                    فراموشی رمز عبور
                  </Link>
                </p>
              </div>
              {/* Verify Code Part */}
              <div className={`${sendCode ? "none" : "hidden"} mt-8`}>
                <form onSubmit={handleSubmit(handleOtpCode)}>
                  <div>
                    <label
                      htmlFor="verifyCode"
                      className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                    >
                      کد تأیید
                    </label>
                    <span
                      className="flex justify-center items-center"
                      style={{ direction: "ltr" }}
                    >
                      <OtpInput
                        value={loginCode}
                        onChange={setLoginCode}
                        numInputs={5}
                        renderInput={(props) => (
                          <input
                            {...props}
                            type="number"
                            name="verifyCode"
                            autoComplete="off"
                            className="block w-full px5 py-2 my-4 mx-3 border rounded-md
                          placeholder-gray-600 bg-gray-900 text-gray-300 text-center
                          border-gray-700 focus:border-yellow-400 tracking-widest 
                            focus:outline-none focus:text-yellow-400 font-medium"
                            style={{ direction: "rtl" }}
                          />
                        )}
                      />
                    </span>
                  </div>
                  <p className="text-center mt-2 text-gray-200">
                    زمان باقی مانده
                    <span id="verificationCodeCountDown" className="mx-2">
                      120
                    </span>
                    ثانیه
                  </p>
                  {/* Button */}
                  <button
                    type="submit"
                    className="w-full px-4 py-2 mt-5 tracking-wide text-white font-medium
                    transition-colors duration-200 transform rounded-md
                    bg-yellow-500 hover:bg-yellow-400 focus:outline-none"
                  >
                    ورود
                  </button>
                </form>
              </div>
              {/* Pwa Button */}
              <div className="mt-6 text-sm font-medium text-center text-gray-400">
                {showInstallButton && (
                  <button
                    className="w-full py-3 tracking-wide text-white font-medium
                  transition-colors duration-200 transform rounded-md 
                  flex justify-center text-base
                  bg-green-500 hover:bg-green-400 focus:outline-none"
                    onClick={handleInstallClick}
                  >
                    <MdInstallDesktop
                      size={22}
                      className="lg:flex hidden mx-1"
                    />
                    <MdInstallMobile
                      size={22}
                      className="flex lg:hidden mx-1"
                    />
                    نصب نسخه تحت وب
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
