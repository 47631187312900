import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SiteTitle from "../../../../helpers/SiteTitle/SiteTitle";
import ValidationErrorMessage from "../../../../helpers/ValidationErrorMessage/ValidationErrorMessage";
import { areYouSureMessage } from "../../../../utils/toastMessage/toastMessage";
import {
  addNewsAdminValidation,
  editNewsAdminValidation,
} from "../../../../utils/validation/validation";

import {
  HiNewspaper,
  HiOutlinePlusSm,
  HiOutlineX,
  HiLockOpen,
  HiLockClosed,
} from "react-icons/hi";

import { newsAdminContext } from "../../../../context/AdminContext/NewsAdminContext/NewsAdminContext";

const AdminDashboardNews = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const {
    isOpenAddModal,
    setIsOpenAddModal,
    getNewsItems,
    newsId,
    setAdminUser,
    handleCreateNewsAdmin,
    handleGetNewsAdmin,
    handleEditNewsAdmin,
    handleCloseEditModal,
    handleOpenEditModal,
    handleDeleteNews,
    handleChangeStoreStatus,
    handleGetStoreStatus,
    isStoreCloses,
  } = useContext(newsAdminContext);

  useEffect(() => {
    handleGetNewsAdmin();
  }, []);

  useEffect(() => {
    handleGetStoreStatus();
  }, []);

  // Add New Validation
  const {
    register: registerAddNews,
    handleSubmit: handleSubmitAddNews,
    reset,
    formState: { errors: errorsAddNews },
  } = useForm({
    resolver: yupResolver(addNewsAdminValidation),
  });

  // Edit News Validation
  const {
    register: registerEditNews,
    handleSubmit: handleSubmitEditNews,
    setValue,
    formState: { errors: errorsEditNews },
  } = useForm({
    resolver: yupResolver(editNewsAdminValidation),
  });

  // const [farzad, setFarzad] = useState(false);
  // const [changeModal, setChangeModal] = useState(false);

  return (
    <>
      <SiteTitle title="اخبار" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full mb-16">
        <div className="flex h-screen justify-center items-center rounded-xl bg-[#252839]">
          <div
            className="rounded-xl flex flex-col h-full w-full relative
             animate__animated animate__fadeIn animate__fast"
          >
            {/* Store Status */}
            <span className="absolute top-5 right-5 cursor-pointer flex">
              {/* Start Status */}
              {isStoreCloses === true && (
                <span onClick={() => handleChangeStoreStatus(false)}>
                  <HiLockClosed size={40} fill="#dc2626" />
                </span>
              )}
              {isStoreCloses === false && (
                <span onClick={() => handleChangeStoreStatus(true)}>
                  <HiLockOpen size={40} fill="#16a34a" />
                </span>
              )}
              {/* End Status */}
              {/* Start Login Farzad */}
              {/* <span
                className="mr-3 cursor-pointer"
                onClick={() => setFarzad(true)}
              >
                <RiLoginCircleLine
                  size={40}
                  fill="#fff"
                  fill={farzad ? "#16a34a" : "#dc2626"}
                />
              </span> */}
              {/* End Login Farzad */}
            </span>
            {/* Table */}
            <div className="w-full flex-col mt-10">
              {/* Add Product Button */}
              <span className="bg-green-500 hover:bg-green-600 rounded-full text-white float-left ml-2">
                <button
                  onClick={() => {
                    reset();
                    setIsOpenAddModal(true);
                  }}
                  className="flex items-center px-2 lg:py-2 py-1 font-bold lg:text-sm text-xs tracking-wide"
                >
                  اضافه کردن اخبار
                  <HiOutlinePlusSm size={20} />
                </button>
              </span>
              {isEmpty(getNewsItems) ? (
                <>
                  <span
                    className="mr-auto w-full py-3 mt-10
                    text-slate-200 text-xl flex flex-col
                     justify-center items-center absolute top-[50%]"
                  >
                    <span>
                      <HiNewspaper size={60} className="mb-2" />
                    </span>
                    <p>اخباری وجود ندارد</p>
                  </span>
                </>
              ) : (
                <>
                  <table className="w-full h-full">
                    <thead className="text-sm font-medium text-slate-200 border-b-[1px] border-[#A79B81]">
                      <tr>
                        <th className="py-3">
                          <div>موضوع اخبار</div>
                        </th>
                        <th className="py-3">
                          <div>متن اخبار</div>
                        </th>
                        <th className="py-3">
                          <div>عملیات</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg divide-y divide-gray-700 w-full h-full overflow-y-scroll">
                      {getNewsItems.map((_item) => (
                        <>
                          <tr
                            key={_item.id}
                            className="hover:bg-gray-900 w-full h-16 relative
                            text-center lg:text-sm text-xs font-medium tracking-wide text-slate-200"
                          >
                            {/* Title */}
                            <td>
                              <span>{_item.title}</span>
                            </td>
                            {/* Description */}
                            <td className="flex justify-center items-center h-full">
                              <span className="flex justify-center w-44">
                                <p className="truncate ... w-full">
                                  {_item.description}
                                </p>
                              </span>
                            </td>
                            {/* Buttons */}
                            <td>
                              <span
                                className="flex lg:flex-row-reverse flex-col 
                                 justify-center items-center text-gray-950 lg:text-sm
                                  text-xs font-bold lg:space-x-2 lg:space-y-0 space-y-2 space-x-0"
                              >
                                {/* Delete Button */}
                                <span>
                                  <button
                                    onClick={() =>
                                      areYouSureMessage(
                                        () => handleDeleteNews(_item.id),
                                        `آیا از حذف ${_item.title} مطمئنید؟`
                                      )
                                    }
                                    className="bg-red-600 hover:bg-red-700 text-white
                           tracking-wider lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    حذف
                                  </button>
                                </span>
                                {/* Edit Button */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModal(_item, setValue)
                                    }
                                    className="bg-yellow-500 hover:bg-yellow-600 text-white
                          tracking-wider lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    ویرایش
                                  </button>
                                </span>
                              </span>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            {/* Add Modal */}
            {isOpenAddModal && (
              <div
                className="flex bg-black bg-opacity-40 fixed lg:mr-40
              inset-0 items-center w-full z-50 mr-auto h-screen
              animate__animated animate__fadeIn animate__fast"
              >
                <div
                  className="flex flex-col absolute top-20 left-0 right-0 
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full">
                      {/* Title */}
                      <span>
                        <p className="font-semibold text-gray-400">
                          اضافه کردن اخبار
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={() => setIsOpenAddModal(false)}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form onSubmit={handleSubmitAddNews(handleCreateNewsAdmin)}>
                      <div className="flex flex-col w-full">
                        {/* Title */}
                        <span>
                          <label
                            htmlFor="title"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            موضوع اخبار
                          </label>
                          <input
                            type="text"
                            name="title"
                            placeholder="موضوع اخبار"
                            autoComplete="off"
                            {...registerAddNews("title")}
                            className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsAddNews.title?.message}
                            />
                          </span>
                        </span>
                        {/* Description */}
                        <span>
                          <label
                            htmlFor="description"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            متن اخبار
                          </label>
                          <textarea
                            type="text"
                            name="description"
                            placeholder="متن اخبار"
                            autoComplete="off"
                            {...registerAddNews("description")}
                            className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsAddNews.description?.message}
                            />
                          </span>
                        </span>
                        {/* User Id */}
                        <span>{setAdminUser(userInfo[0]?.id)}</span>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          اضافه کردن
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* Edit Modal */}
            {newsId && (
              <div
                className="flex bg-black bg-opacity-40 fixed lg:mr-40
                inset-0 items-center w-full z-50 mr-auto h-screen
                animate__animated animate__fadeIn animate__fast"
              >
                <div
                  className="flex flex-col absolute top-20 left-0 right-0 
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full">
                      {/* Title */}
                      <span>
                        <p className="font-semibold text-gray-400">
                          ویرایش کردن اخبار
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={handleCloseEditModal}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form onSubmit={handleSubmitEditNews(handleEditNewsAdmin)}>
                      <div className="flex flex-col w-full">
                        {/* Title */}
                        <span>
                          <label
                            htmlFor="title"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            موضوع اخبار
                          </label>
                          <input
                            type="text"
                            name="title"
                            placeholder="موضوع اخبار"
                            autoComplete="off"
                            {...registerEditNews("title")}
                            className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsEditNews.title?.message}
                            />
                          </span>
                        </span>
                        {/* Description */}
                        <span>
                          <label
                            htmlFor="description"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            متن اخبار
                          </label>
                          <textarea
                            type="text"
                            name="description"
                            placeholder="متن اخبار"
                            autoComplete="off"
                            {...registerEditNews("description")}
                            className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsEditNews.description?.message}
                            />
                          </span>
                        </span>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          ویرایش کردن
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/* Edit Modal */}
            {/* {farzad && (
              <div
                className="flex bg-black bg-opacity-40 fixed lg:mr-40
              inset-0 items-center w-full z-50 mr-auto h-screen
              animate__animated animate__fadeIn animate__fast"
              >
                <div
                  className="flex flex-col absolute top-20 left-0 right-0 
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    Title - Close Icon
                    <span className="flex justify-between w-full">
                      Title
                      <span>
                        <p className="font-semibold text-gray-400">
                          ورود به سامانه فرزاد
                        </p>
                      </span>
                      Close Icon
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={() => setFarzad(false)}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  Inputs
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form
                    // onSubmit={handleSubmitEditNews(handleEditNewsAdmin)}
                    >
                      <div className="flex flex-col w-full">
                        {!changeModal && (
                          <>
                            Phone Number
                            <span>
                              <label
                                htmlFor="phoneNumber"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                شماره موبایل
                              </label>
                              <input
                                type="number"
                                name="phoneNumber"
                                placeholder="شماره موبایل"
                                autoComplete="off"
                                // {...registerEditNews("title")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errorsEditNews.title?.message}
                                />
                              </span>
                            </span>
                          </>
                        )}
                        {changeModal && (
                          <>
                            Verify COde
                            <span>
                              <label
                                htmlFor="verifyCode"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                کد تأیید
                              </label>
                              <input
                                type="number"
                                name="verifyCode"
                                placeholder="کد تأیید"
                                autoComplete="off"
                                // {...registerEditNews("title")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                            rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errorsEditNews.title?.message}
                                />
                              </span>
                            </span>
                          </>
                        )}
                      </div>
                      Button
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          // type="submit"
                          type="button"
                          onClick={() => setChangeModal(!changeModal)}
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          {changeModal ? "ارسال کد" : "دریافت کد"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminDashboardNews;
