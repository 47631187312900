import { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";

import { formatToPersianClock, formatToPersianDate } from "../../../utils";

import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";
import PersianNumber from "../../../utils/PersianNumber/PersianNumber";
import {
  MdOutlineCheck,
  MdOutlineClose,
  MdOutlineCurrencyExchange,
} from "react-icons/md";
import { GrTransaction } from "react-icons/gr";

import { transactionAdminContext } from "../../../context/AdminContext/TransactionAdminContext/TransactionAdminContext";

// Date Picker Package
import DatePicker, { DateObject } from "react-multi-date-picker";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Button from "react-multi-date-picker/components/button";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import "react-multi-date-picker/styles/colors/teal.css";

const AdminLastTransactions = () => {
  const {
    getOrdersHistoryAdmin,
    setStartDate,
    productType,
    setProductType,
    handleSubmitAdmin,
    handleGetOrderHistoryAdmin,
    handleChangeOrderTypeHistory,
  } = useContext(transactionAdminContext);

  useEffect(() => {
    handleGetOrderHistoryAdmin();
  }, []);

  const [values] = useState([
    new DateObject({ calendar: persian }).subtract(7, "days"),
    new DateObject({ calendar: persian }).add(0, "days"),
  ]);

  //
  const [isAccounted, setIsAccounted] = useState(false);

  return (
    <>
      <SiteTitle title="آخرین معاملات" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-screen mb-16">
        <div
          className="flex w-full h-full justify-center overflow-y-scroll 
        items-center bg-[#252839] relative"
        >
          <div
            className={`${isEmpty(getOrdersHistoryAdmin) ? "h-full" : null}
            w-full left-0 top-0 absolute mx-auto
              overflow-y-scroll grid gap-y-10 px-8 py-4 justify-items-center
              `}
          >
            {/* Filters */}
            <div className="flex justify-center items-start w-full">
              <form
                onSubmit={handleSubmitAdmin}
                className="w-full flex justify-center 
                    items-center md:space-y-0 space-y-3"
              >
                {/* Select Options */}
                <span className="md:mx-2">
                  <select
                    className="px-3 bg-transparent border-b border-[#facc15] w-full
                    rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-200
                    focus:border-2 focus:border-yellow-400 focus:text-yellow-400"
                    onChange={(e) => setProductType(e.target.value)}
                    value={productType}
                  >
                    <option
                      className="text-slate-200 p-1 bg-[#252839]"
                      value={""}
                    >
                      همه
                    </option>
                    <option
                      className="text-slate-200 p-1 bg-[#252839]"
                      value={"L"}
                    >
                      لغو شده
                    </option>
                    <option
                      className="text-slate-200 p-1 bg-[#252839]"
                      value={"T"}
                    >
                      تأیید شده
                    </option>
                  </select>
                </span>
                {/* Date Picker */}
                <span className="text-white flex items-center md:mx-2 border-b border-[#facc15] rounded-md">
                  <DatePicker
                    className="rmdp-prime bg-dark"
                    value={values}
                    onChange={setStartDate}
                    range
                    showOtherDays
                    render={<Button />}
                    plugins={[<DatePanel position="left" />]}
                    calendar={persian}
                    locale={persian_fa}
                    calendarPosition="bottom-left"
                  />
                </span>
                {/* Button */}
                <span className="md:mx-2">
                  <button
                    type="submit"
                    className="px-3 py-1.5 text-sm font-medium rounded-md
                    bg-transparent hover:bg-[#1A1D27] text-slate-200
                    border-b border-[#facc15]"
                  >
                    دریافت اطلاعات
                  </button>
                </span>
              </form>
            </div>
            {isEmpty(getOrdersHistoryAdmin) ? (
              <>
                <span
                  className="w-full h-full absolute space-y-5
                  left-0 right-0 lg:top-[3.5rem] top-[4.5rem] 
                  text-slate-200 text-xl
                  flex flex-col justify-center items-center"
                >
                  <span>
                    <GrTransaction size={60} />
                  </span>
                  <p>تاریخچه معامله ای وجود ندارد</p>
                </span>
              </>
            ) : (
              <>
                {getOrdersHistoryAdmin.map((_item) => (
                  <>
                    <div
                      key={_item.id}
                      className="w-full h-full animate__animated animate__fadeIn animate__slow"
                    >
                      <span className="justify-center">
                        {/* WeekDays */}
                        <span
                          className="flex justify-between text-lg text-slate-200
                           px-5 py-2 mr-auto w-full 2xl:w-[95%] xl:w-[93%] lg:w-[90%]"
                        >
                          <p>{_item?.weekday}</p>
                          <p>{formatToPersianDate(_item?.date)}</p>
                        </span>
                        {/* Items */}
                        <div className="grid 2xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 mt-5">
                          {_item.transactions?.map((_item) => (
                            <>
                              <div
                                key={_item.id}
                                className="w-full h-96 rounded-xl bg-[#1a1d2783]
                                 border-b-2 border-[#A79B81] max-w-lg relative py-4
                                 animate__animated animate__fadeIn animate__slow"
                              >
                                {/* Spin */}
                                {_item.tType === "T" ? (
                                  <div
                                    className="absolute top-2 right-2 cursor-pointer"
                                    onClick={() =>
                                      handleChangeOrderTypeHistory(
                                        _item.id,
                                        "L"
                                      )
                                    }
                                  >
                                    <span className="relative flex h-3 w-3">
                                      <span className="relative h-full w-full">
                                        <span className="w-20 absolute -top-1 right-1 opacity-100 text-sm text-slate-300">
                                          <MdOutlineCheck
                                            className=""
                                            size={25}
                                            fill="#22c55e"
                                          />
                                          تأیید شده
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                ) : _item.tType === "L" ? (
                                  <>
                                    <div
                                      className="absolute top-2 right-2 cursor-pointer"
                                      onClick={() =>
                                        handleChangeOrderTypeHistory(
                                          _item.id,
                                          "T"
                                        )
                                      }
                                    >
                                      <span className="relative h-full w-full">
                                        <span className="w-20 absolute -top-1 right-1 opacity-100 text-sm text-slate-300">
                                          <MdOutlineClose
                                            className=""
                                            size={25}
                                            fill="#ef4444"
                                          />
                                          لغو شده
                                        </span>
                                      </span>
                                    </div>
                                  </>
                                ) : null}

                                {/* Accounting */}
                                {/* <span className="absolute top-4 left-4 cursor-pointer z-50 text-slate-300 text-sm">
                                  {isAccounted ? (
                                    <span className="w-full" onClick={() => setIsAccounted(false)}>
                                      حساب نشده
                                    </span>
                                  ) : (
                                    <span className="w-full" onClick={() => setIsAccounted(true)}>
                                      حساب شده
                                    </span>
                                  )}
                                </span> */}

                                {/* Order Type */}
                                {_item.is_orderi ? (
                                  <span className="absolute px-2 py-1 rounded-full top-1 left-1 flex items-center text-slate-300">
                                    <div className="group flex relative">
                                      <MdOutlineCurrencyExchange size={18} />
                                      <span className="bg-slate-700"></span>
                                      <span
                                        className="group-hover:opacity-100 transition-opacity 
                         px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
                         -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                                      >
                                        اردری
                                      </span>
                                    </div>
                                  </span>
                                ) : null}
                                {/* Information */}
                                <span
                                  className="flex flex-col justify-center items-center space-y-6
                                  relative animate__animated animate__flipInX animate__fast"
                                >
                                  {/* Title */}
                                  <span
                                    className={`${
                                      _item.bos === "B"
                                        ? "text-green-600"
                                        : "text-red-600"
                                    } text-center font-medium text-lg tracking-wide`}
                                  >
                                    <h3
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      }`}
                                    >
                                      معامله{" "}
                                      {_item.bos === "B" ? "خرید" : "فروش"}{" "}
                                      {_item.tag === "T" ? "طلا" : "سکه"}
                                    </h3>
                                    <p className="text-xs text-gray-100">
                                      #{_item.transaction_id}
                                    </p>
                                  </span>
                                  {/* Weight - Price - Total */}
                                  <div className="space-y-3">
                                    {/* Customer Name */}
                                    <span
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      } flex justify-center items-center tracking-wide text-gray-100 w-full`}
                                    >
                                      <p className="font-medium text-sm text-gray-100 px-1">
                                        کاربر :
                                      </p>
                                      <span className="flex items-center text-slate-300 px-1">
                                        {_item.customeruser}
                                      </span>
                                    </span>
                                    {/* Product Name */}
                                    <span
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      } flex flex-col justify-center items-center text-gray-100 w-full pb-2`}
                                    >
                                      <p className="font-medium text-sm tracking-wide text-gray-100 pb-1 px-1">
                                        نام محصول :
                                      </p>
                                      <span className="flex items-center text-slate-300 px-1">
                                        {_item.product_name}
                                      </span>
                                    </span>
                                    {/* Weight */}
                                    <span
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      } flex justify-center items-center text-sm tracking-wide text-gray-100 w-full`}
                                    >
                                      <p className="font-medium text-gray-100 px-1">
                                        {_item.tag === "S"
                                          ? "تعداد"
                                          : "مقدار (گرم)"}
                                        :
                                      </p>
                                      <span className="flex items-center text-slate-300 px-1">
                                        <PersianNumber
                                          value={_item.volume}
                                          thousandSeparator=","
                                        />
                                      </span>
                                    </span>
                                    {/* Price */}
                                    <span
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      } flex justify-center items-center text-sm tracking-wide text-gray-100 w-full`}
                                    >
                                      <p className="font-medium text-gray-100 px-1">
                                        {_item.tag === "S" ? "قیمت" : "مظنه"} :
                                      </p>
                                      <span className="flex items-center text-slate-300 px-1">
                                        <PersianNumber
                                          value={_item.price}
                                          thousandSeparator=","
                                        />
                                        <p className="text-xs px-1 text-gray-100 text-center">
                                          تومان
                                        </p>
                                      </span>
                                    </span>
                                    {/* Total */}
                                    <span
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      } flex justify-center items-center text-sm tracking-wide text-gray-100 w-full`}
                                    >
                                      <p className="font-medium text-gray-100 px-1">
                                        مبلغ :
                                      </p>
                                      <span className="flex items-center text-slate-300 px-1">
                                        <PersianNumber
                                          value={_item.amount}
                                          thousandSeparator=","
                                        />
                                        <p className="text-xs px-1 text-gray-100 text-center">
                                          تومان
                                        </p>
                                      </span>
                                    </span>
                                    {/* Description */}
                                    <span
                                      className={`${
                                        _item.tType === "L"
                                          ? "line-through decoration-2"
                                          : "no-underline"
                                      } flex flex-col justify-center items-center text-sm tracking-wide text-gray-100 w-full`}
                                    >
                                      <p className="font-medium text-sm tracking-wide text-gray-100 px-1">
                                        توضیحات :
                                      </p>
                                      <span className="flex items-center justify-center text-center text-slate-300 px-1">
                                        {_item.description ? (
                                          <>
                                            <p>{_item.description}</p>
                                          </>
                                        ) : (
                                          <p> - </p>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                  {/* Date - Time */}
                                  <div className="w-full">
                                    <span className="w-full flex justify-around items-center font-medium text-sm tracking-wide text-gray-100">
                                      <h3
                                        className={`${
                                          _item.tType === "L"
                                            ? "line-through decoration-2"
                                            : "no-underline"
                                        }`}
                                      >
                                        {formatToPersianClock(
                                          _item.date_create
                                        )}
                                      </h3>
                                      <h3
                                        className={`${
                                          _item.tType === "L"
                                            ? "line-through decoration-2"
                                            : "no-underline"
                                        }`}
                                      >
                                        {formatToPersianDate(_item.date_create)}
                                      </h3>
                                    </span>
                                  </div>
                                </span>
                              </div>
                            </>
                          ))}
                        </div>
                      </span>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLastTransactions;
