import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Toaster } from "react-hot-toast";

import OtpInput from "react-otp-input";

import { authContext } from "../../../context/AuthContext/AuthContext";

import { logo } from "../../../assets";

import { signUpUserValidation } from "../../../utils/validation/validation";
import ValidationErrorMessage from "../../../helpers/ValidationErrorMessage/ValidationErrorMessage";
import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";

import { HiEye, HiEyeOff } from "react-icons/hi";
import { MdInstallDesktop, MdInstallMobile } from "react-icons/md";

const SignUp = () => {
  const {
    isShowPassSignUp,
    setIsShowPassSignUp,
    isShowPassSignUpConfirm,
    setIsShowPassSignUpConfirm,
    signUpCode,
    setSignUpCode,
    isChange,
    isShowForm,
    handleSignUp,
    handleOtpSignUp,
    setDeferredPrompt,
    showInstallButton,
    setShowInstallButton,
    handleInstallClick,
  } = useContext(authContext);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallButton(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  // Validation
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpUserValidation),
  });

  const [isAcceptRule, setIsAcceptRule] = useState(false);
  const [isShowAcceptRule, setIsShowAcceptRule] = useState(false);

  return (
    <>
      <SiteTitle title="ثبت نام کاربر" />
      <div className="bg-gray-900">
        <div className="flex justify-center h-screen">
          <div className="hidden bg-cover lg:block lg:w-2/3 bg-log-in-wallpaper">
            <div className="flex items-center h-full px-10 bg-black bg-opacity-50">
              {/* Text */}
              <div className="text-right">
                <h2 className="text-4xl font-bold text-white">
                  برادران ثمره گلستانی
                </h2>
              </div>
            </div>
          </div>
          {/* Sign Up Part */}
          <div
            className="flex items-center relative overflow-y-scroll
            w-full h-full max-w-md px-6 mx-auto lg:w-2/6 py-5 2xl:pt-40 pt-0
            animate__animated animate__fadeIn animate__slow"
          >
            <div className="flex flex-col w-full h-full items-center justify-center">
              {/* Title Text */}
              <span className="flex justify-center w-full text-slate-200">
                <p>هو الرزاق</p>
              </span>
              {/* Logo */}
              <div className="flex justify-center items-center w-full">
                <img
                  src={logo}
                  alt="Website Logo"
                  width={100}
                  className="rounded-full bg-cover"
                />
              </div>
              {/* Title */}
              <div className="text-center my-5 w-full">
                <h2 className="text-3xl font-bold text-center text-white">
                  ثبت نام
                </h2>
              </div>
              {/* Form */}
              {isShowForm && (
                <>
                  <div className="none mt-5 w-full">
                    <form
                      onSubmit={handleSubmit(handleOtpSignUp)}
                      className="space-y-10"
                    >
                      <span className="space-y-4">
                        {/* First Name / Last Name */}
                        <span className="flex">
                          {/* First Name */}
                          <div className="mx-1">
                            <label
                              htmlFor="firstname"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              نام
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              placeholder="نام"
                              autoComplete="off"
                              {...register("firstname")}
                              className="block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      border-gray-600 focus:border-yellow-400 mb-2
                      focus:outline-none focus:text-yellow-400 font-medium"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.firstname?.message}
                              />
                            </span>
                          </div>
                          {/* Last Name */}
                          <div className="mx-1">
                            <label
                              htmlFor="lastname"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              نام خانوادگی
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              placeholder="نام خانوادگی"
                              autoComplete="off"
                              {...register("lastname")}
                              className="block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      border-gray-600 focus:border-yellow-400 mb-2
                      focus:outline-none focus:text-yellow-400 font-medium"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.lastname?.message}
                              />
                            </span>
                          </div>
                        </span>
                        {/* Phone Number / National Number */}
                        <span className="flex">
                          {/* National Number */}
                          <div className="mx-1">
                            <label
                              htmlFor="nationalid"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              کد ملی
                            </label>
                            <input
                              type="text"
                              name="nationalid"
                              placeholder="کد ملی"
                              autoComplete="off"
                              {...register("nationalid")}
                              className="block w-full px-4 py-2 mt-2 border rounded-md
                      placeholder-gray-600 bg-gray-900 text-gray-300 
                      border-gray-600 focus:border-yellow-400 mb-2
                      focus:outline-none focus:text-yellow-400 font-medium"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.nationalid?.message}
                              />
                            </span>
                          </div>
                          {/* Phone Number */}
                          <div className="mx-1">
                            <label
                              htmlFor="phonenumber"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              شماره موبایل
                            </label>
                            <input
                              type="number"
                              name="phonenumber"
                              placeholder="شماره موبایل"
                              autoComplete="off"
                              {...register("phonenumber")}
                              className="block w-full px-4 py-2 mt-2 border rounded-md
                        placeholder-gray-600 bg-gray-900 text-gray-300 
                        border-gray-600 focus:border-yellow-400 mb-2
                        focus:outline-none focus:text-yellow-400 font-medium"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.phonenumber?.message}
                              />
                            </span>
                          </div>
                        </span>
                        {/* Gender */}
                        <span>
                          <label
                            htmlFor="gender"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            جنسیت
                          </label>
                          <select
                            defaultValue=""
                            name="gender"
                            {...register("gender")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                          placeholder-gray-600 bg-gray-900 text-gray-300 mb-2 
                          border-gray-600 focus:border-yellow-400 text-right
                            focus:outline-none focus:text-yellow-400 font-medium"
                          >
                            <option
                              className="text-gray-200"
                              selected
                              hidden
                              value=""
                            >
                              جنسیت
                            </option>
                            <option
                              className="text-gray-200"
                              selected={watch("gender") === 0}
                              value={0}
                            >
                              جنسیت
                            </option>
                            <option
                              className="text-gray-200"
                              selected={watch("gender") === 1}
                              value={1}
                            >
                              مرد
                            </option>
                            <option
                              className="text-gray-200"
                              selected={watch("gender") === 2}
                              value={2}
                            >
                              زن
                            </option>
                          </select>
                          <span>
                            <ValidationErrorMessage
                              message={errors.gender?.message}
                            />
                          </span>
                        </span>
                        {/* Password */}
                        <span className="relative">
                          <label
                            htmlFor="password"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            رمز عبور
                          </label>
                          <input
                            type={isShowPassSignUp ? "text" : "password"}
                            name="password"
                            placeholder="******"
                            autoComplete="off"
                            {...register("password")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                        placeholder-gray-600 bg-gray-900 text-gray-300 
                        border-gray-600 focus:border-yellow-400 mb-2
                        focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          {/* Show/Hide Password Icon */}
                          <span className="absolute left-2 top-10">
                            {isShowPassSignUp ? (
                              <HiEye
                                onClick={() =>
                                  setIsShowPassSignUp(!isShowPassSignUp)
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            ) : (
                              <HiEyeOff
                                onClick={() =>
                                  setIsShowPassSignUp(!isShowPassSignUp)
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            )}
                          </span>
                          <span>
                            <ValidationErrorMessage
                              message={errors.password?.message}
                            />
                          </span>
                        </span>
                        {/* Confirm Password */}
                        <span className="relative">
                          <label
                            htmlFor="confirmpassword"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            تکرار رمز عبور
                          </label>
                          <input
                            type={isShowPassSignUpConfirm ? "text" : "password"}
                            name="confirmpassword"
                            placeholder="******"
                            autoComplete="off"
                            {...register("confirmpassword")}
                            className="block w-full px-4 py-2 mt-2 border rounded-md
                        placeholder-gray-600 bg-gray-900 text-gray-300 
                        border-gray-600 focus:border-yellow-400 mb-2
                        focus:outline-none focus:text-yellow-400 font-medium"
                          />
                          {/* Show/Hide Password Icon */}
                          <span className="absolute left-2 top-10">
                            {isShowPassSignUpConfirm ? (
                              <HiEye
                                onClick={() =>
                                  setIsShowPassSignUpConfirm(
                                    !isShowPassSignUpConfirm
                                  )
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            ) : (
                              <HiEyeOff
                                onClick={() =>
                                  setIsShowPassSignUpConfirm(
                                    !isShowPassSignUpConfirm
                                  )
                                }
                                fill="#fff"
                                className="cursor-pointer"
                              />
                            )}
                          </span>
                          <span>
                            <ValidationErrorMessage
                              message={errors.confirmpassword?.message}
                            />
                          </span>
                        </span>
                        {/* Accept Rules */}
                        <span className="relative flex items-center pt-2 mr-1">
                          <input
                            type="checkbox"
                            name="acceptRules"
                            onClick={() => setIsAcceptRule(!isAcceptRule)}
                            className="block appearance-none w-4 h-4 bg-white
                            checked:bg-yellow-400 cursor-pointer rounded-full"
                          />
                          <label
                            htmlFor="acceptRules"
                            className="block mr-2 text-sm font-medium text-gray-200"
                          >
                            ورود شما به معنای{" "}
                            <span
                              onClick={() => {
                                setIsShowAcceptRule(true);
                              }}
                              className="text-yellow-400 mx-1 tracking-wide hover:underline hover:cursor-pointer"
                            >
                              پذیرش قوانین
                            </span>{" "}
                            سامانه می باشد
                          </label>
                        </span>
                      </span>
                      {/* Button */}
                      <div>
                        <button
                          type="submit"
                          disabled={!isAcceptRule ?? false}
                          className={`${
                            isAcceptRule
                              ? "bg-yellow-500 hover:bg-yellow-400"
                              : "bg-transparent"
                          }
                          w-full px-4 py-2 tracking-wide text-white font-medium
                          transition-colors duration-200 transform rounded-md 
                          focus:outline-none`}
                        >
                          ثبت نام
                        </button>
                      </div>
                    </form>
                    {/* Login Account */}
                    <p
                      className={`${isChange ? "hidden" : "none"}
                mt-4 text-sm font-medium text-center text-gray-400`}
                    >
                      حساب کاربری دارید؟
                      <Link
                        to="/"
                        className="text-yellow-500 focus:outline-none mr-1.5"
                      >
                        ورود
                      </Link>
                    </p>
                  </div>
                </>
              )}
              {/* Verify Code */}
              {isChange && (
                <>
                  <span className="w-full">
                    <form onSubmit={handleSubmit(handleSignUp)}>
                      <div>
                        <label
                          htmlFor="verifyCode"
                          className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                        >
                          کد تأیید
                        </label>
                        <span
                          className="flex justify-center items-center"
                          style={{ direction: "ltr" }}
                        >
                          <OtpInput
                            value={signUpCode}
                            onChange={setSignUpCode}
                            numInputs={5}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => (
                              <input
                                {...props}
                                type="number"
                                name="verifyCode"
                                autoComplete="off"
                                className="block w-full px5 py-2 my-4 mx-3 border rounded-md
                              placeholder-gray-600 bg-gray-900 text-gray-300 text-center
                              border-gray-600 focus:border-yellow-400 tracking-widest 
                                focus:outline-none focus:text-yellow-400 font-medium"
                                style={{ direction: "rtl" }}
                              />
                            )}
                          />
                        </span>
                      </div>
                      {/* Button */}
                      <div>
                        <button
                          type="submit"
                          className="w-full px-4 py-2 tracking-wide text-white font-medium
                          transition-colors duration-200 transform rounded-md mt-5
                        bg-yellow-500 hover:bg-yellow-400 focus:outline-none"
                        >
                          تأیید ثبت نام
                        </button>
                      </div>
                    </form>
                    <p className="text-center mt-2 text-gray-200">
                      زمان باقی مانده
                      <span id="verificationCodeCountDown" className="mx-2">
                        120
                      </span>
                      ثانیه
                    </p>
                  </span>
                </>
              )}
              {/* Pwa Button */}
              <div className="w-full py-4 text-sm font-medium text-center text-gray-400">
                {showInstallButton && (
                  <button
                    className="w-full py-3 tracking-wide text-white font-medium
                  transition-colors duration-200 transform rounded-md 
                  flex justify-center text-base
                  bg-green-500 hover:bg-green-400 focus:outline-none"
                    onClick={handleInstallClick}
                  >
                    <MdInstallDesktop
                      size={22}
                      className="lg:flex hidden mx-1"
                    />
                    <MdInstallMobile
                      size={22}
                      className="flex lg:hidden mx-1"
                    />
                    نصب نسخه تحت وب
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* Rules Modal */}
          {isShowAcceptRule && (
            <>
              <div
                className="flex justify-center items-center h-screen
              animate__animated animate__fadeIn animate__fast"
              >
                <div className="fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center">
                  <div className="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity"></div>
                  <div
                    className="bg-gray-900 text-slate-200 leading-relaxed text-justify rounded-md
                   shadow-xl overflow-hidden w-full lg:w-8/12 md:w-10/12 z-50"
                  >
                    <div className="bg-yellow-500 text-gray-900 px-4 py-2 flex justify-between">
                      <h2 className="text-lg font-semibold">قوانین سایت</h2>
                    </div>
                    <div className="p-4 w-full h-96 overflow-y-scroll tracking-wide space-y-2">
                      <p>
                        سامانه برادران ثمره گلستانی تابع قوانین جمهوری اسلامی
                        ایران بوده و بستری برای معامله فلزات گرانبهایی از قبیل
                        طلا آبشده و تمام سکه و نیم سکه و ربع سکه با ریال
                        می‌باشد. هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا
                        سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.
                      </p>
                      <p>
                        تعهدنامه فعالیت در سامانه برادران ثمره گلستانی کاربران
                        موظفند قبل از ثبت‌نام تمامی مفاد این تعهدنامه را مطالعه
                        نموده و صرفا در صورت پذیرش آن اقدام به ثبت نام نمایند.
                      </p>
                      <p>
                        1-کاربران سایت می‌پذیرند که کلیه‌ی فعالیت‌های آن‌ها در
                        سامانه برادران ثمره گلستانی در چارچوب قوانین جمهوری
                        اسلامی بوده و هیچ گونه فعالیتی خارج از این چارچوب انجام
                        نخواهند داد.
                      </p>
                      <p>
                        2-احراز هویت برای استفاده از خدمات سامانه برادران ثمره
                        گلستانی ضروری بوده و کاربران موظفند اطلاعات صحیح خود را
                        بارگذاری نمایند. بدیهی است در صورت وجود هرگونه تخلف در
                        احراز هویت، مسئولیت به عهده‌ی فرد متخلف بوده و سامانه
                        برادران ثمره گلستانی حق توقف ارائه خدمات به کاربر مذبور
                        و ارجاع موارد تخلف به مراجع ذی صلاح را خواهد داشت.
                        سامانه برادران ثمره گلستانی خود را ملزم به حفظ اطلاعات
                        شخصی کاربران خود می‌داند.
                      </p>
                      <p>
                        3-کاربران سامانه برادران ثمره گلستانی تعهد می دهند که از
                        خدمات سامانه برادران ثمره گلستانی تنها برای خود استفاده
                        نموده و مسئولیت استفاده از خدمات سامانه برادران ثمره
                        گلستانی برای فرد غیر به عهده کاربر خواهد بود.
                      </p>
                      <p>
                        4-هرگونه سهل انگاری کاربران در حفظ اطلاعات امنیتی از
                        جمله رمز عبور کاربر، به عهده‌ی شخص کاربر بوده و سامانه
                        برادران ثمره گلستانی هیچ مسئولیتی به عهده نخواهد داشت.
                        اکیداً توصیه می شود از رمز عبور پیچیده و امن استفاده
                        شود.
                      </p>
                      <p>
                        5-کاربر می‌پذیرد که به جز در موارد مورد تعهد سامانه
                        برادران ثمره گلستانی، حق هیچ گونه داعیه، طلب و شکایت از
                        سایت سامانه برادران ثمره گلستانی، مدیران، کارمندان و
                        افراد مرتبط با این سایت را نخواهد داشت.
                      </p>
                      <p>
                        6-در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات،
                        سامانه برادران ثمره گلستانی حق دارد مستقلاً آن معامله را
                        ابطال و دارایی‌های هر یک از طرفین را به حساب خودشان عودت
                        دهد. بدیهی است که در صورتی که اشکال از سمت سامانه
                        برادران ثمره گلستانی باشد، موظف خواهد بود که جبران خسارت
                        نماید و در غیراین صورت کاربر حق هرگونه اعتراض و ادعایی
                        را در خصوص نحوه عملکرد سامانه برادران ثمره گلستانی از هر
                        جهت از خود ساقط می‌نماید.
                      </p>
                      <p>
                        7-هرگونه تغییر در قوانین، شروط ارائه خدمات و مقررات
                        مشابه، از طریق سایت سامانه برادران ثمره گلستانی به
                        کاربران اطلاع‌رسانی خواهد شد.
                      </p>
                      <p>
                        8-در سامانه برادران ثمره گلستانی تمامی معاملات نقدی
                        می‌باشد.
                      </p>
                      <p>
                        9-هیچ یک از معاملات از سمت کاربر برگشت و کنسلی ندارد.
                      </p>
                      <p>
                        10-هر گونه اختلاف حساب بعد از یک روز به عهده خود کاربران
                        می‌باشد.
                      </p>
                      <p>
                        11-تمامی فیش های برگشتی به حساب مشتری شما به عهده خود
                        کاربر می‌باشد.
                      </p>
                    </div>
                    <div className="px-4 py-2 flex justify-end">
                      <button
                        onClick={() => setIsShowAcceptRule(false)}
                        className="px-3 py-1 bg-yellow-500 hover:bg-yellow-400
                   text-gray-900 rounded-md w-full sm:w-auto"
                      >
                        بستن
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default SignUp;
