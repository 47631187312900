import { useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";
import ValidationErrorMessage from "../../../helpers/ValidationErrorMessage/ValidationErrorMessage";

import { HiEye, HiEyeOff } from "react-icons/hi";

import {
  changePasswordCustomerValidation,
  confirmChangePasswordCustomerValidation,
} from "../../../utils/validation/validation";

import { changePasswordCustomerContext } from "../../../context/CustomerContext/ChangePasswordCustomerContext/ChangePasswordCustomerContext";

const CustomerChangePassword = () => {
  const {
    isCodeSent,
    handleChangePassword,
    handleConfirmChangePassword,
    isShowCurrentPass,
    setIsShowCurrentPass,
    isShowNewPass,
    setIsShowNewPass,
    isShowConfirmPass,
    setIsShowConfirmPass,
  } = useContext(changePasswordCustomerContext);

  // Validation Change Password
  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    formState: { errors: errorsForm1 },
  } = useForm({
    resolver: yupResolver(changePasswordCustomerValidation),
  });

  // Validation Confirm Change Password
  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
  } = useForm({
    resolver: yupResolver(confirmChangePasswordCustomerValidation),
  });

  return (
    <>
      <SiteTitle title="تغییر رمز عبور" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full">
        <div
          className="flex h-full justify-center items-center 
          rounded-xl bg-[#252839] relative"
        >
          <div
            className="rounded-xl flex flex-col h-full w-full
            animate__animated animate__fadeIn animate__fast"
          >
            <div
              className="w-full flex flex-col items-center 
              justify-center h-screen px-5"
            >
              <div
                className="flex flex-col -mt-5 w-full max-w-lg mx-auto 
                rounded-lg border-2 border-[#252839]"
              >
                {/* Inputs */}
                <div className="w-full px-6 py-5 bg-[#252839]">
                  {/* First Form */}
                  {!isCodeSent && (
                    <>
                      <form onSubmit={handleSubmitForm1(handleChangePassword)}>
                        <div className="grid grid-cols-1 gap-x-3 w-full">
                          <span className="animate__animated animate__fadeIn animate__fast">
                            {/* Phone Number */}
                            <span>
                              <label
                                htmlFor="phonenumber"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                شماره موبایل
                              </label>
                              <input
                                type="text"
                                name="phonenumber"
                                placeholder="شماره موبایل"
                                autoComplete="off"
                                {...registerForm1("phonenumber")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errorsForm1.phonenumber?.message}
                                />
                              </span>
                            </span>
                            {/* Current password */}
                            <span className="relative">
                              <label
                                htmlFor="currentPassword"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                رمز عبور فعلی
                              </label>
                              <input
                                // type={isShowCurrentPass ? "text" : "password"}
                                type="text"
                                name="currentPassword"
                                placeholder="رمز عبور فعلی"
                                autoComplete="off"
                                {...registerForm1("currentPassword")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              {/* Show/Hide Password Icon */}
                              {/* <span className="absolute left-2 top-[80%]">
                                {isShowCurrentPass ? (
                                  <HiEye
                                    onClick={() =>
                                      setIsShowCurrentPass(!isShowCurrentPass)
                                    }
                                    fill="#fff"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <HiEyeOff
                                    onClick={() =>
                                      setIsShowCurrentPass(!isShowCurrentPass)
                                    }
                                    fill="#fff"
                                    className="cursor-pointer"
                                  />
                                )}
                              </span> */}
                              <span>
                                <ValidationErrorMessage
                                  message={errorsForm1.currentPassword?.message}
                                />
                              </span>
                            </span>
                          </span>
                        </div>
                        {/* Button */}
                        <div className="flex items-center justify-end pt-5 w-full bg-[#252839]">
                          <button
                            type="submit"
                            className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                          >
                            دریافت کد
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                  {/* Second Form */}
                  {isCodeSent && (
                    <form
                      onSubmit={handleSubmitForm2(handleConfirmChangePassword)}
                    >
                      <div className="grid grid-cols-1 gap-x-3 w-full">
                        <>
                          <span className="animate__animated animate__fadeIn animate__fast">
                            {/* New Password */}
                            <span className="relative">
                              <label
                                htmlFor="newPassword"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                رمز عبور جدید
                              </label>
                              <input
                                // type={isShowNewPass ? "text" : "password"}
                                type="text"
                                name="newPassword"
                                placeholder="رمز عبور جدید"
                                autoComplete="off"
                                {...registerForm2("password")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              {/* Show/Hide Password Icon */}
                              {/* <span className="absolute left-2 top-[70%]">
                                {isShowNewPass ? (
                                  <HiEye
                                    onClick={() =>
                                      setIsShowNewPass(!isShowNewPass)
                                    }
                                    fill="#fff"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <HiEyeOff
                                    onClick={() =>
                                      setIsShowNewPass(!isShowNewPass)
                                    }
                                    fill="#fff"
                                    className="cursor-pointer"
                                  />
                                )}
                              </span> */}
                              <span>
                                <ValidationErrorMessage
                                  message={errorsForm2.password?.message}
                                />
                              </span>
                            </span>
                            {/* Confirm New password */}
                            <span className="relative">
                              <label
                                htmlFor="confirmPassword"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                تکرار رمز عبور جدید
                              </label>
                              <input
                                // type={isShowConfirmPass ? "text" : "password"}
                                type="text"
                                name="confirmPassword"
                                placeholder="تکرار رمز عبور جدید"
                                autoComplete="off"
                                {...registerForm2("confirmpassword")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              {/* Show/Hide Password Icon */}
                              {/* <span className="absolute left-2 top-[80%]">
                                {isShowConfirmPass ? (
                                  <HiEye
                                    onClick={() =>
                                      setIsShowConfirmPass(!isShowConfirmPass)
                                    }
                                    fill="#fff"
                                    className="cursor-pointer"
                                  />
                                ) : (
                                  <HiEyeOff
                                    onClick={() =>
                                      setIsShowConfirmPass(!isShowConfirmPass)
                                    }
                                    fill="#fff"
                                    className="cursor-pointer"
                                  />
                                )}
                              </span> */}
                              <span>
                                <ValidationErrorMessage
                                  message={errorsForm2.confirmpassword?.message}
                                />
                              </span>
                            </span>
                            {/* Verify Code */}
                            <span>
                              <label
                                htmlFor="verifyCode"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                کد تأیید
                              </label>
                              <input
                                type="text"
                                name="verifyCode"
                                placeholder="کد تأیید"
                                autoComplete="off"
                                {...registerForm2("token")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errorsForm2.token?.message}
                                />
                              </span>
                            </span>
                            {/* Timer */}
                            <p className="text-center mt-2 text-gray-200">
                              زمان باقی مانده
                              <span
                                id="verificationCodeCountDown"
                                className="mx-2"
                              >
                                120
                              </span>
                              ثانیه
                            </p>
                          </span>
                        </>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end pt-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          تغییر رمز عبور
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerChangePassword;
