import { useContext, useEffect } from "react";

import { HiOutlineNewspaper } from "react-icons/hi";

import { newsPublicContext } from "../../context/PublicContext/NewsPublicContext/NewsPublicContext";

const NewsModal = () => {
  const { getNews, handleGetNews } = useContext(newsPublicContext);

  useEffect(() => {
    handleGetNews();
  }, []);

  return (
    <>
      <span
        className="lg:absolute top-12 text-white font-normal bg-[#252839]
        w-[95%] lg:h-64 h-44 overflow-y-scroll p-2 text-justify rounded-xl
        text-sm border-2 border-[#A79B81] z-50
        animate__animated animate__fadeInDown animate__fast"
      >
        {getNews?.title === "" ? (
          <span className="w-full flex flex-col items-center justify-center h-full text-slate-200">
            <HiOutlineNewspaper size={40} />
            <span>خبری وجود ندارد</span>
          </span>
        ) : (
          <>
            <span className="animate__animated animate__fadeIn animate__slow">
              <span className="mt-3 block text-base font-bold m-1">
                {getNews?.title}
              </span>
              <span className="my-1 leading-loose">{getNews?.description}</span>
            </span>
          </>
        )}
      </span>
    </>
  );
};

export default NewsModal;
