import { useContext, useEffect } from "react";
import { isEmpty } from "lodash";

import { MdOutlineCurrencyExchange } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";

import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";
import PersianNumber from "../../../utils/PersianNumber/PersianNumber";
import { formatToPersianClock, formatToPersianDate } from "../../../utils";

import { transactionAdminContext } from "../../../context/AdminContext/TransactionAdminContext/TransactionAdminContext";

const AdminLiveTransactions = () => {
  const {
    setCounter,
    getLiveOrders,
    data,
    handleGetLiveOrders,
    handleChangeOrderType,
  } = useContext(transactionAdminContext);

  const handleInitial = () => {
    setCounter((prevCounter) => prevCounter + 1);
    handleGetLiveOrders();
  };

  useEffect(() => {
    handleInitial();
    const interval = setInterval(handleInitial, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <SiteTitle title="معاملات جاری" />
      <div
        className="2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full
          left-0 top-0 absolute mx-auto overflow-y-scroll pb-24
          grid gap-y-10 p-8 justify-items-center h-screen
          2xl:grid-cols-4 xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 
          animate__animated animate__fadeIn animate__slow"
      >
        {isEmpty(getLiveOrders) ? (
          <>
            <span
              className="w-full h-full absolute space-y-5
              left-0 right-0 text-slate-200 text-xl
              flex flex-col justify-center items-center"
            >
              <span>
                <GrTransaction size={60} />
              </span>
              <p>سفارشی وجود ندارد</p>
            </span>
          </>
        ) : (
          <>
            {getLiveOrders.map((_item) => (
              <>
                <div
                  key={_item.id}
                  className="w-[18rem] h-[26rem] rounded-xl
                bg-[#1a1d2783] border-b-2 border-[#A79B81]
                  max-w-lg relative mx-8 py-2"
                >
                  {/* Spin */}
                  <div className="absolute top-2 right-2">
                    <span className="relative flex h-3 w-3">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-100" />
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500" />
                      <span className="w-20 absolute top-4 opacity-100 text-sm text-slate-300 ">
                        در حال انتظار
                      </span>
                    </span>
                  </div>

                  {/* Order Type */}
                  {_item.is_orderi ? (
                    <div className="absolute top-2 left-2 text-slate-200">
                      <span className="absolute px-2 py-1 rounded-full top-0 left-0 z-10 flex items-center text-slate-300">
                        <div className="group flex relative">
                          <MdOutlineCurrencyExchange size={18} />
                          <span
                            className="group-hover:opacity-100 transition-opacity 
                            px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
                            -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                          >
                            اردری
                          </span>
                        </div>
                      </span>
                    </div>
                  ) : null}
                  
                  {/* Information */}
                  <span
                    className="flex flex-col justify-center
                    items-center space-y-6 relative 
                    animate__animated animate__flipInX animate__fast"
                  >
                    {/* Title */}
                    <span
                      className={`${
                        _item.bos === "B" ? "text-green-600" : "text-red-600"
                      } text-center font-medium text-lg tracking-wide`}
                    >
                      <h3
                        className={`${
                          _item.id === data.id ? "line-through" : "no-underline"
                        }`}
                      >
                        معامله {_item.bos === "B" ? "خرید" : "فروش"}{" "}
                        {_item.name}
                      </h3>
                      <p className="text-xs text-gray-100">
                        #{_item.transaction_id}
                      </p>
                    </span>
                    {/* Weight - Price - Total - UserName */}
                    <div className="space-y-3">
                      {/* User Name */}
                      <span className="flex justify-center items-center text-gray-100 w-full">
                        <p className="font-medium text-sm tracking-wide px-1">
                          کاربر :
                        </p>
                        <span className="flex items-center text-slate-300 px-1">
                          {_item.customeruser}
                        </span>
                      </span>
                      {/* Product Name */}
                      <span
                        className={`${
                          _item.id === data.id ? "line-through" : "no-underline"
                        } flex justify-center items-center text-gray-100 w-full`}
                      >
                        <span className="flex items-center text-slate-300 px-1">
                          نام محصول :
                        </span>
                        <p className="text-base px-1 text-gray-100 text-center">
                          {_item.product_name}
                        </p>
                      </span>
                      {/* Price */}
                      <span
                        className={`${
                          _item.id === data.id ? "line-through" : "no-underline"
                        } flex justify-center items-center text-gray-100 w-full`}
                      >
                        <p className="font-medium text-sm tracking-wide text-gray-100 px-1">
                          {_item.tag === "S" ? "قیمت" : "مظنه"} :
                        </p>
                        <span className="flex items-center text-slate-300 px-1">
                          <PersianNumber
                            value={_item.price}
                            thousandSeparator=","
                          />
                        </span>
                        <p className="text-xs px-1 text-gray-100 text-center">
                          تومان
                        </p>
                      </span>
                      {/* Weight */}
                      <span
                        className={`${
                          _item.id === data.id ? "line-through" : "no-underline"
                        } flex justify-center items-center text-gray-100 w-full`}
                      >
                        <p className="font-medium text-sm tracking-wide text-gray-100 px-1">
                          {_item.tag === "S" ? "تعداد" : "مقدار (گرم)"} :
                        </p>
                        <span className="flex items-center text-slate-300 px-1">
                          <PersianNumber
                            value={_item.volume}
                            thousandSeparator=","
                          />
                        </span>
                      </span>
                      {/* Total */}
                      <span
                        className={`${
                          _item.id === data.id ? "line-through" : "no-underline"
                        } flex justify-center items-center text-gray-100 w-full`}
                      >
                        <p className="font-medium text-sm tracking-wide text-gray-100 px-1">
                          مبلغ :
                        </p>
                        <span className="flex items-center text-slate-300 px-1">
                          <PersianNumber
                            value={_item.amount}
                            thousandSeparator=","
                          />
                          <p className="text-xs px-1 text-gray-100 text-center">
                            تومان
                          </p>
                        </span>
                      </span>
                      {/* Description */}
                      <span
                        className={`${
                          _item.tType === "L" ? "line-through" : "no-underline"
                        } flex flex-col justify-center items-center text-gray-100 w-full`}
                      >
                        <p className="font-medium text-sm tracking-wide text-gray-100 px-1">
                          توضیحات :
                        </p>
                        <span className="flex items-center text-center text-slate-300 px-1text-xs px-5">
                          {_item.description ? (
                            <>{_item.description}</>
                          ) : (
                            <p> - </p>
                          )}
                        </span>
                      </span>
                    </div>
                    {/* Date - Time */}
                    <div className="w-full">
                      <span className="w-full flex justify-around items-center font-medium text-sm tracking-wide text-gray-100">
                        <h3
                          className={`${
                            _item.id === data.id
                              ? "line-through"
                              : "no-underline"
                          }`}
                        >
                          {formatToPersianDate(_item.date_create)}
                        </h3>
                        <h3
                          className={`${
                            _item.id === data.id
                              ? "line-through"
                              : "no-underline"
                          }`}
                        >
                          {formatToPersianClock(_item.date_create)}
                        </h3>
                      </span>
                    </div>
                    {/* Buttons */}
                    <div className="w-full">
                      <span className="w-full flex justify-center items-center font-medium text-sm tracking-wide text-gray-100">
                        <button
                          onClick={() => handleChangeOrderType(_item.id, "L")}
                          className="mx-3 hover:bg-red-600 border-2 border-red-600
                         rounded-xl px-3 py-0.5 transition-all ease-linear
                         duration-100 hover:transition-all hover:ease-linear hover:duration-100"
                        >
                          لغو
                        </button>
                        <button
                          onClick={() => handleChangeOrderType(_item.id, "T")}
                          className="mx-3 hover:bg-green-600 border-2 border-green-600
                        rounded-xl px-3 py-0.5 transition-all ease-linear
                        duration-100 hover:transition-all hover:ease-linear hover:duration-100"
                        >
                          تأیید
                        </button>
                      </span>
                    </div>
                  </span>
                </div>
              </>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default AdminLiveTransactions;
