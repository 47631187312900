import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { isEmpty } from "lodash";

import { TbLogout } from "react-icons/tb";

import { logo } from "../../../assets";
import { decodeJWT } from "../../../utils";
import {
  MdOutlineHistory,
  MdOutlineHistoryEdu,
  MdSpaceDashboard,
} from "react-icons/md";

const PublicMobileMenu = () => {
  const token = localStorage.getItem("token");

  const isLogin = !isEmpty(token);

  const roleName = decodeJWT(token)?.usertype;

  if (!isLogin) return <Navigate to="/" replace />;

  return (
    <div
      className="lg:hidden inline-flex
      fixed bottom-2 left-1/2 transform
      -translate-x-1/2 z-50 mx-auto
      justify-between w-10/12
      rounded-3xl bg-[#1d1f25] 
      transition-all duration-100 ease-linear
      hover:transition-all hover:duration-100 hover:ease-linear
      "
    >
      {/* 1 */}
      <Link
        to="/Log-Out"
        className="inline-flex flex-col items-center 
         text-[#A79B81] hover:text-amber-300 py-3
         -px-4 flex-grow"
      >
        <TbLogout size={30} />
        <span>
          <span>
            خروج
          </span>
        </span>
      </Link>
      {/* 2 */}
      <Link
        to={
          roleName === "CUSTOMER"
            ? "/Customer/Profile"
            : "/Admin/Dashboard/Users"
        }
        className="inline-flex flex-col items-center 
         text-[#A79B81] hover:text-amber-300 py-3
         -px-4 flex-grow"
      >
        <MdSpaceDashboard size={30} />
        <span>
          <span>
            داشبورد
          </span>
        </span>
      </Link>
      {/* 3 */}
      <button
        className="relative inline-flex flex-col
        items-center py-3 px-6 flex-grow"
      >
        <Link
          to="/Public/Home"
          className="absolute bottom-6 rounded-full
          border-b-[4px] border-r-[5px] border-l-[5px]
         border-[#252839] bg-transparent"
        >
          <img
            src={logo}
            alt="Website Logo"
            width={60}
            className="rounded-full bg-cover"
          />
        </Link>
      </button>
      {/* 4 */}
      <Link
        to="/Public/Live-Transactions"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow text-center"
      >
        <MdOutlineHistoryEdu size={30} />
        <span>
          <span>
            معاملات
          </span>
        </span>
      </Link>
      {/* 5 */}
      <Link
        to="/Public/Last-Transactions"
        className="inline-flex flex-col items-center 
      text-[#A79B81] hover:text-amber-300 py-3
        -px-4 flex-grow text-center"
      >
        <MdOutlineHistory size={30} />
        تاریخچه
      </Link>
    </div>
  );
};

export default PublicMobileMenu;
