import { useContext, useEffect, useState } from "react";
import { isEmpty, filter } from "lodash";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import PersianNumber from "../../../../utils/PersianNumber/PersianNumber";
import { areYouSureMessage } from "../../../../utils/toastMessage/toastMessage";

import { HiOutlinePlusSm, HiOutlineX } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
import { MdClose, MdCheck, MdOutlineCurrencyExchange } from "react-icons/md";

import SiteTitle from "../../../../helpers/SiteTitle/SiteTitle";
import { usersAdminContext } from "../../../../context/AdminContext/UsersAdminContext/UsersAdminContext";
import { genderType } from "../../../../utils/index";
import ValidationErrorMessage from "../../../../helpers/ValidationErrorMessage/ValidationErrorMessage";
import {
  createUserValidation,
  editUserValidation,
} from "../../../../utils/validation/validation";

const AdminDashboardUsers = () => {
  const userInfo = useSelector((state) => state.userInfo);

  const {
    //* Edit Part
    getUsers,
    addUser,
    setAddUser,
    userEditId,
    editData,
    handleCreateUserAdmin,
    handleGetUsersAdmin,
    handleOpenEditModal,
    handleCloseEditModal,
    handleEditUserAdmin,
    handleChangeActiveUser,
    handleDeleteUser,
    // * Tolerance Part
    getProducts,
    userToleranceID,
    handleGetProducts,
    handleGetUserTolerance,
    handleOpenEditModalUserTolerance,
    handleCloseEditModalUserTolerance,
    handleEditUserToleranceAdmin,
    isOpenInfo,
    productName,
    setProductName,
    bTolerance,
    handleBuyToleranceChange,
    sTolerance,
    handleSellToleranceChange,
    toleranceData,
    //* Order By Admin
    addCommas,
    stateWeight,
    setStateWeight,
    statePrice,
    setStatePrice,
    stateTotal,
    setStateTotal,
    bos,
    setBos,
    isOrderi,
    setIsOrderi,
    description,
    setDescription,
    transactionId,
    setAdminUser,
    userSelected,
    selectedProductId,
    setSelectedProductId,
    productData,
    orderByAdminModal,
    setOrderByAdminModal,
    isOpenProduct,
    setIsOpenProduct,
    isOpenInputs,
    setIsOpenInputs,
    isShowButton,
    isShowTransactionId,
    setIsShowTransactionId,
    handleOpenOrderModal,
    handlePostTransaction,
    handlePriceChange,
    handleWeightChange,
    handleTotalChange,
    handleProductChange,
    handleShowButton,
    //* User Information
    userInfoId,
    userInfoData,
    handleOpenUserInfo,
    handleCloseUserInfo,
  } = useContext(usersAdminContext);

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    handleGetUsersAdmin();
  }, []);
 //filter
 const filteredUsers = filter(getUsers, (user) => {
  const fullName = `${user.firstname} ${user.lastname} ${user.phonenumber} ${user.nationalid}`.toLowerCase();
  return fullName.includes(searchQuery.toLowerCase());
 });
  useEffect(() => {
    handleGetProducts();
  }, []);

  // Validation Create Users
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createUserValidation),
  });

  // Validation Edit Users
  const {
    register: registerEditUser,
    handleSubmit: handleSubmitEditUser,
    setValue: setValueEditUser,
    reset: resetEditUser,
    watch: watchEditUser,
    formState: { errors: errorsEditUser },
  } = useForm({
    resolver: yupResolver(editUserValidation),
  });

  useEffect(() => {
    handleShowButton();
  }, [
    bos,
    selectedProductId,
    statePrice,
    stateWeight,
    stateTotal,
    description,
  ]);

  return (
    <>
      <SiteTitle title="لیست کاربران" />
      <div className="lg:mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full mb-16">
        <div className="flex w-full h-full justify-center items-center rounded-xl bg-[#252839]">
          <div className="rounded-xl h-full w-full relative animate__animated animate__fadeIn animate__fast">
            {/* Table */}
            <div className="w-full flex flex-col mt-10">
              {/* Add User Button */}
              
              <div className="flex items-center justify-between mb-4">
                {/* Search Input */}
                <input
                  type="text"
                  placeholder="جستجوی کاربر..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-[140.4px] h-[36px] flex items-center px-2 lg:py-2 py-1 text-white bg-[#252839] hover:bg-[#2a2d40] rounded-full font-bold lg:text-sm text-xs tracking-wide focus:outline-none border-[1px] border-[#A79B81]"
                />

                {/* Add User Button */}
                <button
                  onClick={() => {
                    reset();
                    setAddUser(true);
                  }}
                  className="w-[140.4px] h-[36px] flex items-center px-2 lg:py-2 py-1 text-white bg-green-500 hover:bg-green-600 rounded-full font-bold lg:text-sm text-xs tracking-wide"
                >
                  اضافه کردن کاربر
                  <HiOutlinePlusSm size={20} />
                </button>
              </div>      {/*     <div className="w-full flex justify-end items-center pl-1.5">
                
                <button
                  onClick={() => {
                    reset();
                    setAddUser(true);
                  }}
                  className="flex items-center px-2 lg:py-2 py-1
                  text-white bg-green-500 hover:bg-green-600 rounded-full
                   font-bold lg:text-sm text-xs tracking-wide"
                >
                  اضافه کردن کاربر
                  
                </button>
              </div> */}
              {/* Check Empty Array */}
              {isEmpty(filteredUsers) ? (
                <>
                  <span
                    className="lg:mr-auto w-full py-3 mt-10
                  text-slate-200 text-xl flex flex-col
                   justify-center items-center absolute top-[50%]"
                  >
                    <span>
                      <IoIosPeople size={60} />
                    </span>
                    <p>کاربری وجود ندارد</p>
                  </span>
                </>
              ) : (
                <>
                  <table className="w-full h-full">
                    <thead className="text-xs font-medium text-slate-200 border-b-[1px] border-[#A79B81] ">
                      <tr>
                        <th className="py-3">
                          <div>نام کاربر</div>
                        </th>
                        <th className="py-3">
                          <div>شماره موبایل</div>
                        </th>
                        <th className="py-3 hidden lg:table-cell">
                          <div>کد ملی</div>
                        </th>
                        <th className="py-3 hidden lg:table-cell">
                          <div>سمت</div>
                        </th>
                        <th className="py-3 hidden lg:table-cell">
                          <div>جنسیت</div>
                        </th>
                        <th className="py-3">
                          <div>وضعیت</div>
                        </th>
                        <th className="py-3">
                          <div>عملیات</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg divide-y divide-gray-700 w-full">
                       
                      {filteredUsers.map((_item, index) => (
                        <>
                          <tr
                            key={index}
                            className="hover:bg-gray-900 w-full h-16 relativ"
                          >
                              
                            {/* User Name */}
                            <td className="py-4">
                              <div className="flex justify-center text-center lg:text-sm text-xs font-medium text-slate-200">
                                <p
                                  className="w-6/12 cursor-pointer"
                                  onClick={() => handleOpenUserInfo(_item)}
                                >
                                  {_item.firstname} {_item.lastname}
                                </p>
                              </div>
                            </td>
                            {/* Phone Number */}
                            <td className="py-4">
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  {_item.phonenumber}{" "}
                                </p>
                              </div>
                            </td>
                            {/* National Number */}
                            <td className="py-4 hidden lg:table-cell">
                              <div className="flex justify-center text-gray-400 lg:text-sm text-xs">
                                <p className="tracking-wider">
                                  {_item.nationalid}
                                </p>
                              </div>
                            </td>
                            {/* Position */}
                            <td className="py-4 hidden lg:table-cell">
                              <div
                                className="flex justify-center text-center
                      lg:text-sm text-xs font-medium text-slate-200"
                              >
                                <p
                                  className={`${
                                    _item.user_type === "CUSTOMER"
                                      ? "bg-blue-400"
                                      : "bg-yellow-400"
                                  }
                         px-2.5 rounded-full text-black`}
                                >
                                  {_item.user_type === "CUSTOMER"
                                    ? "کاربر"
                                    : "ادمین"}
                                </p>
                              </div>
                            </td>
                            {/* Gender */}
                            <td className="py-4 hidden lg:table-cell">
                              <div
                                className="flex justify-center text-center
                      lg:text-sm text-xs font-medium text-slate-200"
                              >
                                <p className="px-2.5 rounded-full text-slate-200">
                                  {genderType(_item.gender)}
                                </p>
                              </div>
                            </td>
                            {/* Active */}
                            <td className="py-4">
                              <div
                                className="flex justify-center text-center
                                lg:text-sm text-xs font-medium text-slate-200"
                              >
                                <p>
                                  {_item.isAllowed ? (
                                    <MdCheck size={25} fill="#22c55e" />
                                  ) : (
                                    <MdClose size={25} fill="#dc2626" />
                                  )}
                                </p>
                              </div>
                            </td>
                            {/* Buttons */}
                            <td className="py-4">
                              <div
                                className="flex lg:flex-row-reverse flex-col 
                                justify-center items-center
                              text-gray-950 lg:text-sm text-xs font-bold
                                lg:space-x-2 lg:space-y-0 space-y-2 space-x-0"
                              >
                                {/* Delete User */}
                                <span className="">
                                  <button
                                    onClick={() =>
                                      areYouSureMessage(
                                        () => handleDeleteUser(_item.id),
                                        `آیا از حذف ${_item.firstname} ${_item.lastname} مطمئنید؟`
                                      )
                                    }
                                    className="bg-red-600 hover:bg-red-700 tracking-wide
                        text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    حذف
                                  </button>
                                </span>
                                {/* Active User */}
                                <span>
                                  {_item.isAllowed ? (
                                    <button
                                      onClick={() =>
                                        handleChangeActiveUser(_item.id, false)
                                      }
                                      className="bg-green-500 hover:bg-green-600 
                                      tracking-wide text-white lg:px-2.5 px-1.5 
                                      lg:py-1 py-0.5 rounded-full"
                                    >
                                      فعال
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        handleChangeActiveUser(_item.id, true)
                                      }
                                      className="bg-yellow-500 hover:bg-yellow-600
                                    tracking-wide text-white lg:px-2.5 px-1.5 
                                    lg:py-1 py-0.5 rounded-full"
                                    >
                                      غیرفعال
                                    </button>
                                  )}
                                </span>
                                {/* Edit */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModal(
                                        _item,
                                        setValueEditUser
                                      )
                                    }
                                    className="bg-yellow-600 hover:bg-yellow-700 tracking-wide
                                  text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    ویرایش
                                  </button>
                                </span>
                                {/* Tolerance User */}
                                <span>
                                  <button
                                    onClick={() =>
                                      handleOpenEditModalUserTolerance(_item)
                                    }
                                    className="bg-blue-600 hover:bg-blue-700 tracking-wide
                                  text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    سود
                                  </button>
                                </span>
                                {/* Order By Admin*/}
                                <span>
                                  <button
                                    onClick={() => handleOpenOrderModal(_item)}
                                    className="bg-slate-600 hover:bg-slate-700 tracking-wide
                                  text-white lg:px-2.5 px-1.5 lg:py-1 py-0.5 rounded-full"
                                  >
                                    سفارش
                                  </button>
                                </span>
                              </div>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </div>
          </div>
          {/* Create User Modal */}
          {addUser && (
            <>
              <div
                className="fixed bg-black bg-opacity-40 inset-0
                 w-full h-full z-50 flex items-center justify-center
                 animate__animated animate__fadeIn animate__fast"
              >
                <div
                  className="flex flex-col absolute lg:top-20 top-10
                  w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
                  border-2 border-[#252839]"
                >
                  <div className="flex flex-row justify-between p-5 bg-[#252839]">
                    {/* Title - Close Icon */}
                    <span className="flex justify-between w-full h-full">
                      {/* Title */}
                      <span>
                        <p className="font-medium lg:text-base text-sm text-gray-400">
                          اضافه کردن کاربر
                        </p>
                      </span>
                      {/* Close Icon */}
                      <span>
                        <HiOutlineX
                          size={25}
                          onClick={() => setAddUser(false)}
                          className="text-gray-400 cursor-pointer"
                        />
                      </span>
                    </span>
                  </div>
                  <span className="border-b-2 border-[#1A1D27]" />
                  {/* Inputs */}
                  <div className="w-full px-6 py-5 bg-[#252839]">
                    <form onSubmit={handleSubmit(handleCreateUserAdmin)}>
                      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3 w-full">
                        {/* Part 1 */}
                        <span>
                          {/* First name - Last Name */}
                          <span className="flex lg:flex-col flow-row w-full">
                            {/* First Name */}
                            <span className="lg:ml-0 ml-1 w-full">
                              <label
                                htmlFor="firstname"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نام کاربر
                              </label>
                              <input
                                type="text"
                                name="firstname"
                                placeholder="نام کاربر"
                                autoComplete="off"
                                {...register("firstname")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.firstname?.message}
                                />
                              </span>
                            </span>
                            {/* Last Name */}
                            <span className="lg:mr-0 mr-1 w-full">
                              <label
                                htmlFor="lastname"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                نام خانوادگی کاربر
                              </label>
                              <input
                                type="text"
                                name="lastname"
                                placeholder="نام خانوادگی کاربر"
                                autoComplete="off"
                                {...register("lastname")}
                                className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              />
                              <span>
                                <ValidationErrorMessage
                                  message={errors.lastname?.message}
                                />
                              </span>
                            </span>
                          </span>
                          {/* Gender - User Type */}
                          <span className="flex lg:flex-col flow-row w-full">
                            {/* Gender */}
                            <span className="lg:ml-0 ml-1 w-full">
                              <label
                                htmlFor="gender"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                جنسیت کاربر
                              </label>
                              <select
                                name="gender"
                                {...register("gender")}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue
                                  hidden
                                  value=""
                                >
                                  جنسیت
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue={watch("gender") == 0}
                                  value={0}
                                >
                                  نامشخص
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue={watch("gender") == 1}
                                  value={1}
                                >
                                  مرد
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue={watch("gender") == 2}
                                  value={2}
                                >
                                  زن
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.gender?.message}
                                />
                              </span>
                            </span>
                            {/* User Type */}
                            <span className="lg:mr-0 mr-1 w-full">
                              <label
                                htmlFor="userType"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                سمت
                              </label>
                              <select
                                name="userType"
                                {...register("user_type")}
                                className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue
                                  hidden
                                  value=""
                                >
                                  سمت کاربر
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue={watch("user_type") == "ADMIN"}
                                  value={"ADMIN"}
                                >
                                  ادمین
                                </option>
                                <option
                                  className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                  defaultValue={
                                    watch("user_type") == "CUSTOMER"
                                  }
                                  value={"CUSTOMER"}
                                >
                                  کاربر
                                </option>
                              </select>
                              <span>
                                <ValidationErrorMessage
                                  message={errors.user_type?.message}
                                />
                              </span>
                            </span>
                          </span>
                        </span>
                        {/* Part 2 */}
                        <span>
                          {/* National Number */}
                          <span>
                            <label
                              htmlFor="nationalid"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              کد ملی کاربر
                            </label>
                            <input
                              type="text"
                              name="nationalid"
                              placeholder="کد ملی کاربر"
                              autoComplete="off"
                              {...register("nationalid")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.nationalid?.message}
                              />
                            </span>
                          </span>
                          {/* Phone Number */}
                          <span>
                            <label
                              htmlFor="phonenumber"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              شماره موبایل کاربر
                            </label>
                            <input
                              type="text"
                              name="phonenumber"
                              placeholder="شماره موبایل کاربر"
                              autoComplete="off"
                              {...register("phonenumber")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.phonenumber?.message}
                              />
                            </span>
                          </span>
                          {/* Password */}
                          <span>
                            <label
                              htmlFor="password"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              رمز عبور کاربر
                            </label>
                            <input
                              type="text"
                              name="password"
                              placeholder="رمز عبور کاربر"
                              autoComplete="off"
                              {...register("password")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.password?.message}
                              />
                            </span>
                          </span>
                          {/* Confirm Password */}
                          <span>
                            <label
                              htmlFor="confirmpassword"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              تکرار رمز عبور کاربر
                            </label>
                            <input
                              type="text"
                              name="confirmpassword"
                              placeholder="تکرار رمز عبور کاربر"
                              autoComplete="off"
                              {...register("confirmpassword")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errors.confirmpassword?.message}
                              />
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* Button */}
                      <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                        <button
                          type="submit"
                          className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                        >
                          اضافه کردن
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Edit Modal */}
          {userEditId && (
            <div
              className="fixed bg-black bg-opacity-40 inset-0
            w-full h-full z-50 flex items-center justify-center
            animate__animated animate__fadeIn animate__fast"
            >
              <div
                className="flex flex-col absolute lg:top-20 top-10
              w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
              border-2 border-[#252839]"
              >
                <div className="flex flex-row justify-between p-5 bg-[#252839]">
                  {/* Title - Close Icon */}
                  <span className="flex justify-between w-full">
                    {/* Title */}
                    <span>
                      <p className="font-medium lg:text-base text-sm text-gray-400">
                        ویرایش کردن کاربر
                        <span className="mx-2 text-gray-300">
                          {editData.firstname} {editData.lastname}
                        </span>
                      </p>
                    </span>
                    {/* Close Icon */}
                    <span>
                      <HiOutlineX
                        size={25}
                        onClick={() => {
                          handleCloseEditModal();
                          resetEditUser();
                        }}
                        className="text-gray-400 cursor-pointer"
                      />
                    </span>
                  </span>
                </div>
                <span className="border-b-2 border-[#1A1D27]" />
                {/* Inputs */}
                <div className="w-full px-6 py-5 bg-[#252839]">
                  <form onSubmit={handleSubmitEditUser(handleEditUserAdmin)}>
                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-3 w-full">
                      {/* Part 1 */}
                      <span>
                        {/* First name - Last Name */}
                        <span className="flex lg:flex-col flow-row w-full">
                          {/* First Name */}
                          <span className="lg:ml-0 ml-1 w-full">
                            <label
                              htmlFor="firstname"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              نام کاربر
                            </label>
                            <input
                              type="text"
                              name="firstname"
                              placeholder="نام کاربر"
                              autoComplete="off"
                              {...registerEditUser("firstname")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.firstname?.message}
                              />
                            </span>
                          </span>
                          {/* Last Name */}
                          <span className="lg:mr-0 mr-1 w-full">
                            <label
                              htmlFor="lastname"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              نام خانوادگی کاربر
                            </label>
                            <input
                              type="text"
                              name="lastname"
                              placeholder="نام خانوادگی کاربر"
                              autoComplete="off"
                              {...registerEditUser("lastname")}
                              className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                            />
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.lastname?.message}
                              />
                            </span>
                          </span>
                        </span>
                        {/* Gender - User Type */}
                        <span className="flex lg:flex-col flow-row w-full">
                          {/* Gender */}
                          <span className="lg:ml-0 ml-1 w-full">
                            <label
                              htmlFor="gender"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              جنسیت کاربر
                            </label>
                            <select
                              name="gender"
                              {...registerEditUser("gender")}
                              className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                            >
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue
                                hidden
                                value=""
                              >
                                جنسیت
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue={watchEditUser("gender") == 0}
                                value={0}
                              >
                                نامشخص
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue={watchEditUser("gender") == 1}
                                value={1}
                              >
                                مرد
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue={watchEditUser("gender") == 2}
                                value={2}
                              >
                                زن
                              </option>
                            </select>
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.gender?.message}
                              />
                            </span>
                          </span>
                          {/* User Type */}
                          <span className="lg:mr-0 mr-1 w-full">
                            <label
                              htmlFor="userType"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              سمت
                            </label>
                            <select
                              name="userType"
                              {...registerEditUser("user_type")}
                              className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                            >
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue
                                hidden
                                value=""
                              >
                                سمت کاربر
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue={watch("user_type") == "ADMIN"}
                                value={"ADMIN"}
                              >
                                ادمین
                              </option>
                              <option
                                className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                                defaultValue={watch("user_type") == "CUSTOMER"}
                                value={"CUSTOMER"}
                              >
                                کاربر
                              </option>
                            </select>
                            <span>
                              <ValidationErrorMessage
                                message={errorsEditUser.user_type?.message}
                              />
                            </span>
                          </span>
                        </span>
                      </span>
                      {/* Part 2 */}
                      <span>
                        {/* National Number */}
                        <span>
                          <label
                            htmlFor="nationalid"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            کد ملی کاربر
                          </label>
                          <input
                            type="text"
                            name="nationalid"
                            placeholder="کد ملی کاربر"
                            autoComplete="off"
                            {...registerEditUser("nationalid")}
                            className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                              w-full lg:text-base text-sm tracking-wide text-slate-400
                              rounded-md focus:right-0 focus:outline-none
                            focus:border-yellow-400 focus:text-yellow-400"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsEditUser.nationalid?.message}
                            />
                          </span>
                        </span>
                        {/* Phone Number */}
                        <span>
                          <label
                            htmlFor="phonenumber"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            شماره موبایل کاربر
                          </label>
                          <input
                            type="text"
                            name="phonenumber"
                            placeholder="شماره موبایل کاربر"
                            autoComplete="off"
                            {...registerEditUser("phonenumber")}
                            className="p-2 mb-2 bg-transparent border-2 border-slate-600 
                                  w-full lg:text-base text-sm tracking-wide text-slate-400
                                  rounded-md focus:right-0 focus:outline-none
                                focus:border-yellow-400 focus:text-yellow-400"
                          />
                          <span>
                            <ValidationErrorMessage
                              message={errorsEditUser.phonenumber?.message}
                            />
                          </span>
                        </span>
                        {/* Active Type */}
                        <span>
                          <label
                            htmlFor="isAllowed"
                            className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                          >
                            وضعیت کاربر
                          </label>
                          <select
                            name="isAllowed"
                            {...registerEditUser("isAllowed")}
                            className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                              w-full lg:text-base text-sm tracking-wide text-slate-400
                              rounded-md focus:right-0 focus:outline-none
                            focus:border-yellow-400 focus:text-yellow-400"
                          >
                            <option
                              className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                              defaultValue
                              hidden
                              value=""
                            >
                              وضعیت کاربر
                            </option>
                            <option
                              className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                              defaultValue={watch("isAllowed") == false}
                              value={false}
                            >
                              غیر فعال
                            </option>
                            <option
                              className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                              defaultValue={watch("isAllowed") == true}
                              value={true}
                            >
                              فعال
                            </option>
                          </select>
                          <span>
                            <ValidationErrorMessage
                              message={errorsEditUser.isAllowed?.message}
                            />
                          </span>
                        </span>
                      </span>
                    </div>
                    {/* Button */}
                    <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                      <button
                        type="submit"
                        className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                      >
                        ویرایش کردن
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
          {/* Tolerance Modal */}
          {userToleranceID && (
            <div
              className="fixed bg-black bg-opacity-40 inset-0
            w-full h-full z-50 flex items-center justify-center
            animate__animated animate__fadeIn animate__fast"
            >
              <div
                className="flex flex-col absolute lg:top-20 top-10
              w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
              border-2 border-[#252839]"
              >
                {/* Title - Close Icon */}
                <div className="flex flex-row justify-between p-5 bg-[#252839]">
                  <span className="flex justify-between w-full">
                    {/* Title */}
                    <span>
                      <p className="font-medium lg:text-base text-sm text-gray-400">
                        سود کاربر
                        <span className="mx-2 text-gray-300">
                          {toleranceData.firstname} {toleranceData.lastname}
                        </span>
                      </p>
                    </span>
                    {/* Close Icon */}
                    <span>
                      <HiOutlineX
                        size={25}
                        onClick={handleCloseEditModalUserTolerance}
                        className="text-gray-400 cursor-pointer"
                      />
                    </span>
                  </span>
                </div>
                <span className="border-b-2 border-[#1A1D27]" />
                <div className="w-full px-6 py-5 bg-[#252839]">
                  {/* First Form */}
                  <form onSubmit={handleGetUserTolerance}>
                    {/* Product Name */}
                    <span className="flex flex-col w-full overflow-y-scroll">
                      <label
                        htmlFor="tag"
                        className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                      >
                        نوع محصول
                      </label>
                      <select
                        name="tag"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value, e)}
                        className="p-1.5 mb-2 bg-transparent border-2 border-slate-600 
                                w-full lg:text-base text-sm tracking-wide text-slate-400
                                rounded-md focus:right-0 focus:outline-none
                              focus:border-yellow-400 focus:text-yellow-400"
                      >
                        <option
                          className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                          defaultValue
                          hidden
                          value=""
                        >
                          نوع محصول
                        </option>
                        {getProducts?.map((_item, index) => (
                          <>
                            <option
                              key={index}
                              className="text-gray-200 p-1 bg-[#252839] lg:text-base text-sm"
                              defaultValue={productName == _item.id}
                              value={_item.id}
                            >
                              {_item.name}
                            </option>
                          </>
                        ))}
                      </select>
                    </span>
                    {/* Button */}
                    <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                      <button
                        type="submit"
                        className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded focus:outline-none"
                      >
                        مشاهده
                      </button>
                    </div>
                  </form>
                  {/* Second Form */}
                  {isOpenInfo && (
                    <>
                      <form
                        onSubmit={(event) =>
                          handleEditUserToleranceAdmin(event, userToleranceID)
                        }
                      >
                        <span className="font-medium flex lg:flex-row flex-col">
                          {/* Buy Tolerance */}
                          <span className="w-full mx-1">
                            <label
                              htmlFor="buyTolerance"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              سود فروش
                            </label>
                            <input
                              type="text"
                              name="buyTolerance"
                              placeholder="سود فروش"
                              autoComplete="off"
                              value={bTolerance === -1 ? 0 : bTolerance}
                              onChange={handleBuyToleranceChange}
                              className="p-2 mb-2 bg-transparent border-2
                              border-slate-600 w-full rounded-md
                              focus:right-0 focus:outline-none tracking-wide
                             text-slate-400 focus:border-yellow-400
                              focus:text-yellow-400"
                            />
                          </span>
                          {/* Sell Tolerance */}
                          <span className="w-full mx-1">
                            <label
                              htmlFor="sellTolerance"
                              className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                            >
                              سود خرید
                            </label>
                            <input
                              type="text"
                              name="sellTolerance"
                              placeholder="سود خرید"
                              autoComplete="off"
                              value={sTolerance === -1 ? 0 : sTolerance}
                              onChange={handleSellToleranceChange}
                              className="p-2 mb-2 bg-transparent border-2
                                border-slate-600 w-full rounded-md
                                focus:right-0 focus:outline-none tracking-wide
                               text-slate-400 focus:border-yellow-400
                                focus:text-yellow-400"
                            />
                          </span>
                        </span>
                        {/* Button */}
                        <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                          <button
                            type="submit"
                            className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded focus:outline-none"
                          >
                            ذخیره
                          </button>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* Order By Admin */}
          {orderByAdminModal && (
            <div
              className="fixed bg-black bg-opacity-40 inset-0
            w-full h-full z-50 flex items-center justify-center
            animate__animated animate__fadeIn animate__fast"
            >
              <div
                className="flex flex-col absolute lg:top-20 top-10
              w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
              border-2 border-[#252839]"
              >
                {/* Title - Close Icon */}
                <div className="flex flex-row justify-between p-5 bg-[#252839]">
                  <span className="flex justify-between w-full">
                    {/* Title */}
                    <span>
                      <p className="font-medium lg:text-base text-sm text-gray-400">
                        سفارش برای کاربر
                        <span className="mx-2 text-gray-300">
                          {userSelected?.firstname} {userSelected?.lastname}
                        </span>
                      </p>
                    </span>
                    {/* Close Icon */}
                    <span>
                      <HiOutlineX
                        size={25}
                        onClick={() => {
                          setIsShowTransactionId(false);
                          setOrderByAdminModal(false);
                          setIsOpenProduct(false);
                          setIsOpenInputs(false);
                          setBos("");
                          setProductName("");
                          setStatePrice("");
                          setStateWeight("");
                          setStateTotal("");
                        }}
                        className="text-gray-400 cursor-pointer"
                      />
                    </span>
                  </span>
                </div>
                <span className="border-b-2 border-[#1A1D27]" />
                <div className="w-full px-6 py-5 bg-[#252839] relative">
                  {/* Order Form */}
                  {!isShowTransactionId && (
                    <>
                      <form onSubmit={handlePostTransaction}>
                        {/* Order Icon */}
                        <span className="absolute top-1 left-1">
                          {isOpenInputs && isOrderi && (
                            <>
                              <span
                                className="absolute px-2 py-1 rounded-full top-1
        left-1 flex items-center text-slate-300"
                              >
                                <div className="group flex relative">
                                  <MdOutlineCurrencyExchange
                                    size={18}
                                    className="animate-spin-slow"
                                  />
                                  <span
                                    className="group-hover:opacity-100 transition-opacity 
         px-1 text-sm text-slate-300 rounded-md absolute left-1/2 
         -translate-x-1/2 translate-y-full opacity-0 m-1 mx-auto"
                                  >
                                    اردری
                                  </span>
                                </div>
                              </span>
                            </>
                          )}
                        </span>
                        {/* Inputs */}
                        <span className="flex flex-col space-y-5 mt-7">
                          {/* Buy / Sell */}
                          <div className="w-full flex text-slate-200">
                            {/* Buy */}
                            <div
                              onClick={() => {
                                setIsOpenProduct(true);
                                setBos("B");
                                setSelectedProductId("");
                                setIsOpenInputs(false);
                                setStatePrice("");
                                setStateWeight("");
                                setStateTotal("");
                                setDescription("");
                              }}
                              className="w-full px-4 py-2 text-center rounded-r-lg
                          transition duration-150 ease-linear cursor-pointer
                          bg-green-600 hover:bg-green-700"
                              style={{
                                clipPath:
                                  "polygon(3% 0%, 100% 0%, 100% 100%, 0% 100%)",
                              }}
                            >
                              <span>خرید</span>
                            </div>
                            {/* Sell */}
                            <div
                              onClick={() => {
                                setIsOpenProduct(true);
                                setBos("S");
                                setSelectedProductId("");
                                setIsOpenInputs(false);
                                setStatePrice("");
                                setStateWeight("");
                                setStateTotal("");
                                setDescription("");
                              }}
                              className="w-full px-4 py-2 text-center rounded-l-lg
                          transition duration-150 ease-linear cursor-pointer
                          bg-red-600 hover:bg-red-700"
                              style={{
                                clipPath:
                                  "polygon(0% 0%, 100% 0%, 97% 100%, 0% 100%)",
                              }}
                            >
                              <span>فروش</span>
                            </div>
                          </div>
                          {/* Products */}
                          {isOpenProduct && (
                            <span className="w-full">
                              <label  
                                htmlFor="products"
                                className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                              >
                                محصول
                              </label>
                              <select
                                name="products"
                                value={selectedProductId}
                                onChange={handleProductChange}
                                className="p-2 mb-2 bg-transparent border-2
                      border-slate-600 w-full rounded-md
                        focus:right-0 focus:outline-none tracking-wide
                      text-slate-400 focus:border-yellow-400
                      focus:text-yellow-400"
                              >
                                <option
                                  className="text-gray-200"
                                  defaultValue
                                  hidden
                                  value=""
                                >
                                  محصولات
                                </option>
                                {getProducts?.map((_item, index) => (
                                  <option
                                    key={index}
                                    className="text-gray-200 bg-[#252839]"
                                    value={_item?.id}
                                  >
                                    {_item?.name}
                                  </option>
                                ))}
                              </select>
                            </span>
                          )}
                          {/* Inputs */}
                          {isOpenInputs && (
                            <span className="flex flex-col space-y-1">
                              {/* Price */}
                              <span className="mx-1 w-full">
                                <label
                                  htmlFor="price"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  {productData?.tag === "S" ? "قیمت" : "مظنه"}
                                </label>
                                <input
                                  type="text"
                                  name="price"
                                  placeholder={
                                    productData?.tag === "S" ? "قیمت" : "مظنه"
                                  }
                                  autoComplete="off"
                                  value={
                                    statePrice === -1
                                      ? ""
                                      : statePrice === ""
                                      ? statePrice + statePrice
                                      : addCommas(statePrice)
                                  }
                                  onChange={(e) => {
                                    handlePriceChange(productData, e);
                                  }}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                              rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                              focus:border-yellow-400 focus:text-yellow-400"
                                />
                                {/* <span>
                               <ValidationErrorMessage
                                  message={errorsEditUser.firstname?.message}
                                /> 
                            </span>*/}
                              </span>
                              {/* Weight */}
                              <span className="mx-1 w-full">
                                <label
                                  htmlFor="weight"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  {productData?.tag === "S"
                                    ? "تعداد"
                                    : "مقدار (گرم)"}
                                </label>
                                <input
                                  type="text"
                                  name="weight"
                                  placeholder={
                                    productData?.tag === "S"
                                      ? "تعداد"
                                      : "مقدار (گرم)"
                                  }
                                  autoComplete="off"
                                  value={stateWeight}
                                  onChange={(e) => {
                                    handleWeightChange(productData, e);
                                  }}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                              rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                              focus:border-yellow-400 focus:text-yellow-400"
                                />
                                {/* <span>
                               <ValidationErrorMessage
                                  message={errorsEditUser.firstname?.message}
                                /> 
                            </span>*/}
                              </span>
                              {/* Total */}
                              <span className="mx-1 w-full">
                                <label
                                  htmlFor="total"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  جمع کل
                                </label>
                                <input
                                  type="text"
                                  name="total"
                                  placeholder="جمع کل"
                                  autoComplete="off"
                                  value={addCommas(stateTotal)}
                                  onChange={(e) => {
                                    handleTotalChange(productData, e);
                                  }}
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                              rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                              focus:border-yellow-400 focus:text-yellow-400"
                                />
                                {/* <span>
                               <ValidationErrorMessage
                                  message={errorsEditUser.firstname?.message}
                                /> 
                            </span>*/}
                              </span>
                              {/* Description */}
                              <span className="mx-1 w-full">
                                <label
                                  htmlFor="description"
                                  className="block mb-2 mr-2.5 text-sm font-medium text-gray-200"
                                >
                                  توضیحات
                                </label>
                                <input
                                  type="text"
                                  name="description"
                                  placeholder="توضیحات"
                                  autoComplete="off"
                                  value={description}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  className="p-2 mb-2 bg-transparent border-2 border-slate-600 w-full
                              rounded-md focus:right-0 focus:outline-none tracking-wide text-slate-400
                            focus:border-yellow-400 focus:text-yellow-400"
                                />
                                {/* <span>
                               <ValidationErrorMessage
                                  message={errorsEditUser.firstname?.message}
                                /> 
                            </span>*/}
                              </span>
                            </span>
                          )}
                          {/* Admin Id - Is Orderi*/}
                          <div>
                            {/* Admin Id */}
                            <span>{setAdminUser(userInfo[0]?.id)}</span>
                            {/* Is Orderi */}
                            <span>
                              {setIsOrderi(
                                productData?.buy_price !== statePrice
                                  ? true
                                  : false
                              )}
                              {setIsOrderi(
                                productData?.sell_price !== statePrice
                                  ? true
                                  : false
                              )}
                            </span>
                          </div>
                        </span>
                        {/* Button */}
                        <div className="flex items-center justify-end p-5 w-full bg-[#252839]">
                          {isOpenProduct && isOpenInputs && !isShowButton && (
                            <span className="text-slate-200 text-sm">
                              لطفا همه فیلد ها را کامل کنید!
                            </span>
                          )}

                          {isOpenProduct && isOpenInputs && isShowButton && (
                            <button
                              type="submit"
                              className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded focus:outline-none"
                            >
                              ثبت سفارش
                            </button>
                          )}
                        </div>
                      </form>
                    </>
                  )}
                  {/* Transaction Id Result */}
                  {isShowTransactionId && (
                    <div className="flex flex-col justify-center space-y-3 py-3">
                      {/* 1 */}
                      <span className="font-bold text-center tracking-wide text-slate-300">
                        سفارش{" "}
                        <span
                          className={
                            bos === "B" ? "text-green-500" : "text-red-500"
                          }
                        >
                          {bos === "B" ? "خرید " : "فروش "}
                        </span>{" "}
                        شما برای کاربر "
                        <span className="mx-2 font-bold text-slate-100">
                          {userSelected?.firstname} {userSelected?.lastname}
                        </span>
                        " ثبت گردید!
                      </span>
                      {/* 2 */}
                      <span className="flex justify-center font-medium tracking-wider text-zinc-300">
                        <span>شماره سفارش :</span>
                        <span>#{transactionId}</span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {/* User Info Modal */}
          {userInfoId && (
            <div
              className="fixed bg-black bg-opacity-40 inset-0
              w-full h-full z-50 flex items-center justify-center
              animate__animated animate__fadeIn animate__fast"
            >
              <div
                className="flex flex-col absolute lg:top-20 top-10
               w-11/12 sm:w-5/6 lg:w-12/12 max-w-2xl mx-auto rounded-lg 
               border-2 border-[#252839]"
              >
                <div className="flex flex-row justify-between p-5 bg-[#252839]">
                  {/* Title */}
                  <span className="flex justify-center items-center w-full">
                    <p className="flex font-medium lg:text-base text-sm text-gray-300">
                      مشخصات {userInfoData.firstname} {userInfoData.lastname}
                    </p>
                  </span>
                </div>
                <span className="border-b-2 border-[#1A1D27]" />
                {/* Inputs */}
                <div className="w-full px-6 py-5 bg-[#252839]">
                  <div className="grid sm:grid-cols-3 grid-cols-1 justify-around w-full text-sm font-medium">
                    {/* National Number */}
                    <td className="py-4">
                      <div className="flex flex-col justify-center items-center space-y-1">
                        <p className="text-gray-300">کد ملی</p>
                        <p className="tracking-wider text-gray-200">
                          <PersianNumber value={userInfoData.nationalid} />
                        </p>
                      </div>
                    </td>
                    {/* Position */}
                    <td className="py-4">
                      <div className="flex flex-col justify-center items-center space-y-1">
                        <p className="text-gray-300">سمت</p>
                        <p
                          className={`${
                            userInfoData.user_type === "CUSTOMER"
                              ? "bg-blue-400"
                              : "bg-yellow-400"
                          }
                         px-2.5 rounded-full text-black`}
                        >
                          {userInfoData.user_type === "CUSTOMER"
                            ? "کاربر"
                            : "ادمین"}
                        </p>
                      </div>
                    </td>
                    {/* Gender */}
                    <td className="py-4">
                      <div className="flex flex-col justify-center items-center space-y-1">
                        <p className="text-gray-300">جنسیت</p>
                        <p className="px-2.5 rounded-full text-slate-200">
                          {genderType(userInfoData.gender)}
                        </p>
                      </div>
                    </td>
                  </div>
                  {/* Button */}
                  <div className="flex items-center justify-center p-5 w-full bg-[#252839]">
                    <button
                      type="button"
                      onClick={handleCloseUserInfo}
                      className="px-4 py-1.5 text-white text-sm font-medium bg-green-500 hover:bg-green-600 rounded"
                    >
                      بستن
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdminDashboardUsers;
