import { createContext, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  toastErrorMessage,
  toastSuccessMessage,
} from "../../utils/toastMessage/toastMessage";
import { secondsCountDown } from "../../utils";

import {
  setLoadingFalseAction,
  setLoadingTrueAction,
} from "../../redux/actions/loadingActions/loadingActions";
import { setUserAction } from "../../redux/actions/userActions/userActions";

import {
  logInAuthService,
  singUpAuthService,
  optAuthService,
  otpRegisterAuthService,
  forgetPassAuthService,
  confirmForgetPassAuthService,
} from "../../services/userServices";

export const authContext = createContext({
  // Start Show Pass
  isShowPassLogin: null,
  setIsShowPassLogin: () => {},
  isShowPassSignUp: null,
  setIsShowPassSignUp: () => {},
  isShowPassSignUpConfirm: null,
  setIsShowPassSignUpConfirm: () => {},
  isShowPassForgetPass: null,
  setIsShowPassForgetPass: () => {},
  isShowPassForgetPassConfirm: null,
  setIsShowPassForgetPassConfirm: () => {},
  // End Show Pass
  // Start Log In
  sendCode: null,
  setSendCode: () => {},
  handleLogIn: () => {},
  // End Log In
  // Start Sign Up
  isShowForm: null,
  setIsShowForm: () => {},
  isChange: null,
  setIsChange: () => {},
  signUpCode: "",
  setSignUpCode: () => {},
  handleSignUp: () => {},
  handleOtpSignUp: () => {},
  // End Sign Up
  // Start Otp Code
  handleOtpCode: () => {},
  loginCode: "",
  setLoginCode: () => {},
  // End Otp Code
  // Start Forget Password
  isReceiveCode: null,
  setIsReceiveCode: () => {},
  handleForgetPassword: () => {},
  handleConfirmForgetPassword: () => {},
  // End Forget Password
  // Start Install PWA
  deferredPrompt: null,
  setDeferredPrompt: () => {},
  showInstallButton: null,
  setShowInstallButton: () => {},
  handleInstallClick: () => {},
  // End Install PWA
});

const AuthContext = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Show Password
  const [isShowPassLogin, setIsShowPassLogin] = useState(false);
  const [isShowPassSignUp, setIsShowPassSignUp] = useState(false);
  const [isShowPassSignUpConfirm, setIsShowPassSignUpConfirm] = useState(false);
  const [isShowPassForgetPass, setIsShowPassForgetPass] = useState(false);
  const [isShowPassForgetPassConfirm, setIsShowPassForgetPassConfirm] =
    useState(false);

  // Log In
  const [sendCode, setSendCode] = useState(false);
  // Sign Up
  const [isShowForm, setIsShowForm] = useState(true);
  const [isChange, setIsChange] = useState(false);
  const [signUpCode, setSignUpCode] = useState("");

  // Log In
  const [isReceiveCode, setIsReceiveCode] = useState(false);
  const [loginCode, setLoginCode] = useState("");

  // Forget Password
  const [phonenumber, setPhonenumber] = useState("");

  // PWA
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(false);

  //* Handle Sign Up
  const handleSignUp = async (userData, event) => {
    event?.preventDefault();

    const objData = {
      ...userData,
      token: signUpCode,
      gender: Number(userData.gender),
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await singUpAuthService(objData);
      if (status === 201) {
        setIsShowForm(true);
        setIsChange(false);
        toastSuccessMessage(
          "ثبت نام شما با موفقیت انجام شد لطفا منتظر تأیید حساب بمانید"
        );
        navigate("/");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Otp Code Sign Up
  const handleOtpSignUp = async (userData, event) => {
    event?.preventDefault();

    const objData = {
      ...userData,
      token: "",
      gender: Number(userData.gender),
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await otpRegisterAuthService(objData);
      if (status === 200) {
        setIsShowForm(false);
        setIsChange(true);
        secondsCountDown("verificationCodeCountDown", 120, () => {
          setIsChange(false);
          setIsShowForm(true);
        });
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Login
  const handleLogIn = async (userData, event) => {
    event?.preventDefault();
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await logInAuthService(userData);
      if (status === 200) {
        setSendCode(true);
        secondsCountDown("verificationCodeCountDown", 120, () => {
          setSendCode(false);
        });
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Otp Code Login
  const handleOtpCode = async (userData) => {
    const objData = {
      ...userData,
      token: loginCode,
      gender: Number(userData.gender),
    };
    try {
      dispatch(setLoadingTrueAction());
      const { data, status } = await optAuthService(objData);
      if (status === 200) {
        setLoginCode("");
        dispatch(setUserAction(data?.access));
        window.location.reload();
        navigate("/Public/Home");
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Forget Password
  const handleForgetPassword = async (userData, event) => {
    event?.preventDefault();

    try {
      dispatch(setLoadingTrueAction());
      const { status } = await forgetPassAuthService(userData);
      if (status === 200) {
        setPhonenumber(userData.phonenumber);
        setIsReceiveCode(true);
        secondsCountDown("verificationCodeCountDown", 120, () => {
          setIsReceiveCode(false);
        });
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Confirm Forget Password
  const handleConfirmForgetPassword = async (userData, event) => {
    event?.preventDefault();

    const objData = {
      ...userData,
      phonenumber,
    };
    try {
      dispatch(setLoadingTrueAction());
      const { status } = await confirmForgetPassAuthService(objData);
      if (status === 200) {
        setPhonenumber("");
        setIsReceiveCode(false);
        toastSuccessMessage("رمز عبور شما با موفقیت تغییر پیدا کرد");
        navigate("/");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch ({ response }) {
      if (response) {
        if (response.status) {
          toastErrorMessage(response.data.message);
        }
      }
    } finally {
      dispatch(setLoadingFalseAction());
    }
  };

  //* Handle Install Pwa
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("User accepted the install prompt");
          setShowInstallButton(false);
        } else {
          // console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <authContext.Provider
      value={{
        isShowPassLogin,
        setIsShowPassLogin,
        isShowPassSignUp,
        setIsShowPassSignUp,
        isShowPassSignUpConfirm,
        setIsShowPassSignUpConfirm,
        isShowPassForgetPass,
        setIsShowPassForgetPass,
        isShowPassForgetPassConfirm,
        setIsShowPassForgetPassConfirm,
        signUpCode,
        setSignUpCode,
        loginCode,
        setLoginCode,
        sendCode,
        setSendCode,
        handleLogIn,
        handleOtpCode,
        isChange,
        setIsChange,
        isShowForm,
        setIsShowForm,
        handleSignUp,
        handleOtpSignUp,
        deferredPrompt,
        setDeferredPrompt,
        showInstallButton,
        setShowInstallButton,
        handleInstallClick,
        phonenumber,
        isReceiveCode,
        setIsReceiveCode,
        handleForgetPassword,
        handleConfirmForgetPassword,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthContext;
