import { useSelector } from "react-redux";

import SiteTitle from "../../../helpers/SiteTitle/SiteTitle";
import { defaultPicture } from "../../../utils";

const CustomerProfilePage = () => {
  const userInfo = useSelector((state) => state.userInfo);

  return (
    <>
      <SiteTitle title="پروفایل" />
      <div className="mr-auto 2xl:w-[85%] xl:w-[75%] lg:w-[70%] w-full h-full">
        <div className="flex h-full justify-center items-center rounded-xl bg-[#252839] relative">
          <div
            className="rounded-xl flex flex-col h-full w-full
        animate__animated animate__fadeIn animate__fast"
          >
            <span className="w-full flex flex-col items-center justify-center h-screen">
              <div
                className="flex flex-col items-center w-full p-5 space-y-5
                bg-[#252839] text-[#A79B81] lg:text-lg text-sm font-medium text-center"
              >
                {/* Profile */}
                <span className="xl:w-[30%] md:w-[60%] w-full">
                  <img
                    src={defaultPicture}
                    alt="User_Profile"
                    width={135}
                    className="m-auto text-white rounded-full object-cover"
                  />
                </span>
                {/* User Name */}
                <span
                  className="xl:w-[30%] md:w-[60%] w-full p-2 space-y-2
                  flex flex-col justify-between 
                  border-2 border-[#111827] bg-[#111827] rounded-xl"
                >
                  <span>نام کاربر :</span>
                  <span>
                    {userInfo[0]?.firstname} {userInfo[0]?.lastname}
                  </span>
                </span>
                {/* Phone Number */}
                <span
                  className="xl:w-[30%] md:w-[60%] w-full p-2 space-y-2
                      flex flex-col justify-between
                      border-2 border-[#111827] bg-[#111827] rounded-xl"
                >
                  <span>موبایل :</span>
                  <span>{userInfo[0]?.phonenumber}</span>
                </span>
                {/* National Number */}
                <span
                  className="xl:w-[30%] md:w-[60%] w-full p-2 space-y-2
                      flex flex-col justify-between
                      border-2 border-[#111827] bg-[#111827] rounded-xl"
                >
                  <span>کد ملی :</span>
                  <span>{userInfo[0]?.nationalid}</span>
                </span>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfilePage;
